/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import Loading from './Loading';
import ToolTipModal from './TooltipModal';

import iconClose from '../../assets/images/mocana/close.svg';
import iconNext from '../../assets/images/mocana/icon-next.svg';

import RealmTooltip from './RealmTooltip';

const itemsPerPage = 15;

const Table = ({
  data,
  count,
  setPage,
  loading,
  setOffset,
  setValueSort,
  currentPage,
}) => {
  const formatWallet = (wallet) => {
    const prefix = wallet.slice(0, 6);
    const suffix = wallet.slice(-4);
    return `${prefix}...${suffix}`;
  };

  const calculatePage = (number) => Math.ceil(number / itemsPerPage);

  const options = Array.from({ length: calculatePage(count) }, (_, i) => i + 1);

  const [isOpen, setIsOpen] = useState(false);
  const [sort, setSort] = useState('rank');

  const [selectedOption, setSelectedOption] = useState(1);
  const toggling = () => setIsOpen(!isOpen);

  const [showTooltip, setShowTooltip] = useState(false);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setPage(Number(value));
    setOffset((Number(value) - 1) * itemsPerPage);
    setIsOpen(false);
  };
  const lastItem = options[options.length - 1];
  options.splice(options.length - 1, 1);

  const handleSort = (type) => {
    if (type === 'rank') {
      if (sort === 'rank') {
        setSort('-rank');
        setValueSort('-rank');
        return;
      }
      setSort('rank');
      setValueSort('rank');
    } else if (type === 'wallet_address') {
      if (sort === '-wallet_address') {
        setSort('wallet_address');
        setValueSort('wallet_address');
        return;
      }
      setSort('-wallet_address');
      setValueSort('-wallet_address');
    }
  };
  const onPrevClicked = () => {
    if (selectedOption === 1) return;
    setSelectedOption(selectedOption - 1);
    setPage(selectedOption - 1);
    setOffset((selectedOption - 2) * itemsPerPage);
  };
  const onNextClicked = () => {
    if (selectedOption >= calculatePage(count)) return;
    setSelectedOption(selectedOption + 1);
    setPage(selectedOption + 1);
    setOffset(selectedOption * itemsPerPage);
  };
  useEffect(() => {
    if (selectedOption !== currentPage) {
      setSelectedOption(currentPage);
    }
  }, [currentPage]);

  return (
    <div className="table-container table-realm">
      <div className={`table-container__header active-${sort}`}>
        <div className="table-container__header--col-1">
          <span onClick={() => handleSort('rank')}>RANK</span>
        </div>
        <div className="table-container__header--col-2">
          <span onClick={() => handleSort('wallet_address')}>ADDRESS</span>
        </div>
        <div className="table-container__header--col-6 not-sort">
          <RealmTooltip label="white" />
          <span>REALM TICKET MILES</span>
        </div>
      </div>
      {loading && <Loading />}
      {data && data.length && !loading && (
        <>
          {data.map((item) => (
            <div key={item.moca_id}>
              <div className="table-container__item mobile">
                <div className="table-container__item-top realm">
                  <span className="table-container__item--col-1">
                    {item.rank}
                  </span>
                </div>
                <div className="table-container__item-bottom">
                  <div className="table-container__item--col-2">
                    <span className="title">
                      <RealmTooltip />
                      REALM TICKET MILES
                    </span>
                    <span className="value">{item.rtm}</span>
                  </div>
                  <div className="table-container__item--col-2 address">
                    <span className="title">ADDRESS</span>
                    <span className="value-address">
                      <a
                        href={`https://etherscan.io/address/${item.wallet_address}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {formatWallet(item.wallet_address)}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="table-container__item">
                <span className="table-container__item--col-1">
                  {item.rank}
                </span>
                <div className="table-container__item--col-2">
                  <span>
                    <a
                      href={`https://etherscan.io/address/${item.wallet_address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatWallet(item.wallet_address)}
                    </a>
                  </span>
                </div>
                <span className="table-container__item--col-6">{item.rtm}</span>
              </div>
            </div>
          ))}
          {showTooltip && (
            <ToolTipModal>
              <div
                onClick={() => setShowTooltip(!showTooltip)}
                className="toggle-button"
              >
                <img src={iconClose} alt="" />
              </div>
              <p className="tooltip-title">What is Moca XP?</p>
              <p className="tooltip-description">
                <span>
                  {`Moca XP is our power system where we keep track of every
                  Moca's participation in`}{' '}
                  <a href="https://twitter.com/hashtag/Mocaverse?src=hashtag_click">
                    #Mocaverse
                  </a>
                </span>
                <br />
                <br />
                <span>
                  {`More Moca XP means higher chances of getting access to..."The
                  Box"`}
                </span>
                <br />
                <br />
                <span>*Moca XP will be expired after each season.</span>
              </p>
            </ToolTipModal>
          )}
          <div className="table-container__pagination">
            <span
              className="table-container__pagination--prev"
              onClick={() => onPrevClicked()}
            >
              <img src={iconNext} alt="" />
            </span>

            <span className="table-container__pagination--label">Page</span>
            <div className="table-container__pagination--select">
              <div onClick={toggling}>{selectedOption || 1}</div>
              {isOpen && (
                <div className="table-container__pagination--select--option">
                  <ul>
                    {options.map((option) => (
                      <li onClick={onOptionClicked(option)} key={option}>
                        {option}
                      </li>
                    ))}
                  </ul>
                  <div
                    className="last-item"
                    onClick={onOptionClicked(lastItem)}
                  >
                    {lastItem}
                  </div>
                </div>
              )}
            </div>
            <span className="table-container__pagination--label">
              of {calculatePage(count)}
            </span>
            <span
              className="table-container__pagination--next"
              onClick={() => onNextClicked()}
            >
              <img src={iconNext} alt="" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;
