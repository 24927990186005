import React from 'react';
import { HeroBannerAnimation } from './realms/index';

const PlayerRealms = () => (
  <>
    {/* <div className="learn-realm">
      <LearnTextAnimation />
      <LearnAnimation />
    </div>
    <div className="dogood-realm">
      <DoGoodTextAnimation />
      <DoGoodAnimation />
    </div>
    <div className="invest-realm">
      <InvestTextAnimation />
      <InvestAnimation />
    </div>
    <div className="play-realm">
      <PlayTextAnimation />
      <PlayAnimation />
    </div> */}
    <div className="hero-banner">
      <HeroBannerAnimation />
    </div>
  </>
);

export default React.memo(PlayerRealms);
