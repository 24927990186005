import arc8Title from '../../assets/images/mocana/arc8-title.svg';

const EventTitlePage = () => (
  <div className="title-event">
    <div className="title-content">
      <img src={arc8Title} alt=" arc8 title" />
    </div>
  </div>
);
export default EventTitlePage;
