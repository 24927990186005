/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import Loading from '../Loading';
import iconNext from '../../../assets/images/mocana/icon-next.svg';

import { SORT_OPTIONS, ITEMSPERPAGE } from '../../../utils/constants';

const Table = ({
  data,
  count,
  setPage,
  loading,
  setOffset,
  setValueSort,
  currentPage,
  isTotalScore,
}) => {
  const calculatePage = (number) => Math.ceil(number / ITEMSPERPAGE);

  const options = Array.from({ length: calculatePage(count) }, (_, i) => i + 1);
  const lastItem = options[options.length - 1];
  options.splice(options.length - 1, 1);

  const [isOpenP, setIsOpenP] = useState(false);
  const [sort, setSort] = useState(SORT_OPTIONS.RANK);
  const [selectedOption, setSelectedOption] = useState(1);

  const handleSort = (type) => {
    let newSort = type;
    if (type === sort) {
      newSort = `-${type}`;
    }
    setSort(newSort);
    setValueSort(newSort);
  };
  const togglingP = () => setIsOpenP(!isOpenP);

  const onOptionClicked = (value) => {
    setSelectedOption(value);
    setPage(Number(value));
    setOffset((Number(value) - 1) * ITEMSPERPAGE);
    setIsOpenP(false);
  };

  const onPrevClicked = () => {
    if (selectedOption === 1) return;
    setSelectedOption(selectedOption - 1);
    setPage(selectedOption - 1);
    setOffset((selectedOption - 2) * ITEMSPERPAGE);
  };

  const onNextClicked = () => {
    if (selectedOption >= calculatePage(count)) return;
    setSelectedOption(selectedOption + 1);
    setPage(selectedOption + 1);
    setOffset(selectedOption * ITEMSPERPAGE);
  };

  useEffect(() => {
    if (selectedOption !== currentPage) {
      setSelectedOption(currentPage);
    }
  }, [currentPage]);

  return (
    <div className="table-container">
      <div className={`table-container__header active-${sort}`}>
        <div className="table-container__header--col-1">
          <span onClick={() => handleSort(SORT_OPTIONS.RANK)}>RANK</span>
        </div>
        <div className="table-container__header--col-2">
          <span onClick={() => handleSort(SORT_OPTIONS.MOCA_NAME)}>
            MOCA Name
          </span>
        </div>
        <div className="table-container__header--col-4">
          <span onClick={() => handleSort(SORT_OPTIONS.TRIBE)}>TRIBE</span>
        </div>
        <div className="table-container__header--col-5">
          <span onClick={() => handleSort(SORT_OPTIONS.NICKNAME)}>
            GAMEE ID
          </span>
        </div>
        <div className="table-container__header--col-6">
          <span onClick={() => handleSort(SORT_OPTIONS.MAX_SCORE)}>
            {isTotalScore ? 'TOTAL SCORE' : 'HIGH SCORE'}
          </span>
        </div>
      </div>
      {loading && <Loading />}
      {data && data.length && !loading && (
        <>
          {data.map((item, index) => (
            <div key={index}>
              <div
                className="table-container__item mobile"
                data-eligible={
                  isTotalScore
                    ? item.is_eligible_total_score
                    : item.is_eligible_max_score
                }
              >
                <div className="table-container__item-top">
                  <span className="table-container__item--col-1">
                    {isTotalScore ? item.rank_total_score : item.rank_max_score}
                  </span>
                  <span className="table-container__item--col-4">
                    {item.tribe}
                  </span>
                </div>
                <div className="table-container__item-bottom">
                  <div className="table-container__item--col-2">
                    <span>{item.moca_name}</span>
                  </div>
                  <div className="table-container__item-bottom-right">
                    <div className="table-container__item-bottom-right-flex">
                      <div className="table-container__item-own">
                        <span className="label">
                          {isTotalScore ? 'Total Score ' : 'High Score'}
                        </span>
                        <span className="table-container__item--col-5">
                          {isTotalScore ? item.total_score : item.max_score}
                        </span>
                      </div>
                    </div>
                    <div className="table-container__item-bottom-right-flex">
                      <div className="table-container__item-own">
                        <span className="label">GAMEE ID</span>
                        <span
                          className={`table-container__item--col-5
                         ${
                           isTotalScore &&
                           item.is_eligible_total_score &&
                           item.is_top25
                             ? 'warning'
                             : ''
                         }
                        `}
                        >
                          {item.nickname}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="table-container__item"
                data-eligible={
                  isTotalScore
                    ? item.is_eligible_total_score
                    : item.is_eligible_max_score
                }
              >
                <span className="table-container__item--col-1">
                  {isTotalScore ? item.rank_total_score : item.rank_max_score}
                </span>
                <div className="table-container__item--col-2">
                  <span>{item.moca_name}</span>
                </div>

                <span className="table-container__item--col-4">
                  {item.tribe}
                </span>
                <span
                  className={`table-container__item--col-5
                      ${
                        isTotalScore &&
                        item.is_eligible_total_score &&
                        item.is_top25
                          ? 'warning'
                          : ''
                      }
                `}
                >
                  {item.nickname}
                </span>
                <span className="table-container__item--col-6">
                  {isTotalScore
                    ? item.total_score.toLocaleString()
                    : item.max_score.toLocaleString()}
                </span>
              </div>
            </div>
          ))}

          <div className="table-container__pagination">
            <span
              className="table-container__pagination--prev"
              onClick={() => onPrevClicked()}
            >
              <img src={iconNext} alt="" />
            </span>

            <span className="table-container__pagination--label">Page</span>
            <div
              className="table-container__pagination--select"
              onClick={() => togglingP()}
            >
              <div>{selectedOption || 1}</div>
              {isOpenP && (
                <div className="table-container__pagination--select--option">
                  <ul>
                    {options.map((option) => (
                      <li onClick={() => onOptionClicked(option)} key={option}>
                        {option}
                      </li>
                    ))}
                  </ul>
                  <div
                    className="last-item"
                    onClick={() => onOptionClicked(lastItem)}
                  >
                    {lastItem}
                  </div>
                </div>
              )}
            </div>
            <span className="table-container__pagination--label">
              of {calculatePage(count)}
            </span>
            <span
              className="table-container__pagination--next"
              onClick={() => onNextClicked()}
            >
              <img src={iconNext} alt="" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;
