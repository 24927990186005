import React from 'react';
import { EVENT_TIME_STATUS } from '../../utils/eventHelpers';

const CampaignJoinDiscord = ({ statusEvent, handleRegister }) => {
  const rgBtnEnded = () => (
    <>
      <h4 className="event-sb-title">Registration has closed</h4>

      <p className="event-sb-action-note">
        Join Discord to stay up to date for
        <br /> upcoming experiences
      </p>
      <button
        className="btn btn-md-second btn-connect event-closed"
        aria-label="Connect Wallet"
      >
        <img
          className="discord"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABJCAYAAADCOyPGAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR9SURBVHgB7Zzblds2EIY/J3nP5i1vgSvIdiB0EHew7MDpgHIF2g5oV+AS5K1g3QG1FcgdyMQhaUs0LyAxw4uN75z/SMdeccAZDEDcCJHIr8wrlue+0K76tIXuCr0u9AVdTKFjZedU6FOhp0Kf+clxjn5LefPnQpcWpejz2GH7XJXtbVXWnwJb6EC3w9uccIcexrMcTnmhrLqHTWEZ5/Q5s2DPtDLVwRDLDOk+wNXah0JvCK8xrm1+XV3zvvr8p/qss8P0/PZaL5TtvPvu2vi857e+nCgD+VR9XxRDWG3fstw9Z4QHdDKG7TlNS4t02jnbdZi0nhnJ74Sxp2zvIyV/V59PzIBhW7VzLp2ZqT/IgEtUq44o8wa4RPXKokgOXKJ6leMxmv+N8SQs+My7IQzlfFIvU0bCOTEAvtSj+c6Z3bEZkBCdPwbXBPVmwdgMiLV/PL1ZMCYDEqLzp9CbBWMyINb+6XRmgW8GJETnh9CZBb4ZEGt/OCfKLLjBJwMs0fkSGFpGxz4BeCAiRdr8h6EmyFA2PxE5/uKqMx7KAEtEmpvOeCgAsfmRx3sBy7Ct2cctydZO7suA/4hosau/9AUgrvXq8c23XU9Bhvj0o40p9NKVARZ93KPYY2XLPZq9qj5d7XiPPp/4PsVS27aV7RP69LYwH9HthNz1h5brDDpLn+6aOw/bmYLtpg86yRUNp/hjhMuSM25aJRW03dS5y+i9otEj4zHI7Tk1jOcoZLtNrVsZE0WDhmmkArZTpmHQ23TcOkWdKRnLmM5doBNCd6sdgIuCWn3yrGRsRxghTsgIw4KID5rK24xdlBR65CgJsD24P8cDlWaoOQ7Q2t9+IvzUY8jpRYmTjyqnNpsBMKwX7WOrQ5xQoBmAf1kvhmVRObU5VwYYwm8g5PeGcAwKzNkEhfYvIdPjljDqU5riNAOgeTg6dH3BMh038RVybxJPUV5oHjMNGQwlAvZTpmHQnRu74aKsI8s5YGoFyARsewXAKBuqtccf12xIjsxzxgVhL2h7NQFwOuC3HqAxLZIzHARXtkcF270B0JyG7nLEQ4szLPO89iDjx47dUHa4ubLt1gDYGY02dWbZg385C73n4g/WwfUbUJbAsBBTTklGBIkBWJgYgIW5DsDS072/JDEACxOboIVp7g29EJmVuTLArcm+Z5vUe1hPzIAbEUqP9q7nfQzltLCGHWk9V2W9LrvG3NQN9wrOcdez/MiOcj5mTa+6dGU50L6HySIfgLzFjtrOYHfNrmXJXfX/uYJdHyd0Od1h0dkfOjgjnKDjkCP9y4uGcvnygPyNuxr+XF37gfJNvF1YdBzvyjB4QubaGSllMKQ5US54fPD4W5c5f/J9cdzQP4FXv7L4xO0ri18YZk/7NLkErjN/x4Qxl0bfUKf/mgjdBNyX9TsESJANhEihhPkf2eZGfEeFqyUp4YHIWC9Hwh3vfKS6xmEIC4RhvVhW7PgmhvFNU8r6GXMeYRHHt5EwnL4528CnQ3b3umOFGMoa1JYVhu3Q9opmd09itf0rzr5EBLQCNkYAAAAASUVORK5CYII="
          alt="Discord"
        />
        <a href="https://discord.com/invite/MocaverseNFT">Join Discord</a>
      </button>
    </>
  );
  const rgNotStarted = () => (
    <>
      <button
        className="btn btn-md-second btn-connect event-closed"
        aria-label="Connect Wallet"
        disabled
      >
        Register Now
      </button>
      <p className="event-sb-action-note">
        Registration period has not started yet.
      </p>
    </>
  );
  const rgBtnLive = () => (
    <>
      <p className="event-sb-action-note live">
        <b>
          Connect with hot wallet. Use{' '}
          <a href="https://delegate.cash" target="_blank" rel="noreferrer">
            delegate.cash
          </a>{' '}
          to delegate your cold wallet to hot wallet in order to register with
          your cold wallet Mocas upcoming experiences
        </b>
      </p>
      <button
        className="btn btn-md-second btn-connect event-live"
        aria-label="Connect Wallet"
        onClick={handleRegister}
      >
        Register Now
      </button>
    </>
  );

  return (
    <div className="event-sb-actions event-status">
      {statusEvent === EVENT_TIME_STATUS.LIVE && rgBtnLive()}
      {statusEvent === EVENT_TIME_STATUS.NOT_STARTED && rgNotStarted()}
      {statusEvent === EVENT_TIME_STATUS.ENDED && rgBtnEnded()}
    </div>
  );
};

export default CampaignJoinDiscord;
