import React from 'react';
import Error from '../../assets/images/no_moca_found.svg';

const NoData = ({ text }) => (
  <div className="moca-404">
    <img src={Error} alt="" />
    <h3>{text}</h3>
    <p>Please try again.</p>
  </div>
);
NoData.defaultProps = {
  text: 'Invalid Token ID / Wallet Address',
};
export default NoData;
