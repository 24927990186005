import React from 'react';
import { ArrowDownIcon } from '../common/Icons';
import NavGroupLinks from './NavGroupLinks';
// links = [{key: string, icon: , text: string, url: string, target: '_blank'}]
// type = 'desktop' | 'mobile'
// handleShowDesktopNav = () => showDesktopNav({ display: true })
// handleMobileItemClick = handleClick = () => { setIsActive((current) => !current);};
const NavLinks = ({
  links,
  type,
  handleShowDesktopNav,
  handleMobileItemClick,
}) => {
  const content = [];
  links.forEach((link, index) => {
    let cls = '';
    if (index === links.length - 1) {
      cls = 'last';
    }
    if (link?.groups) {
      content.push(
        <li key={link.key} className={`nav-group ${cls}`}>
          <a
            href={link.url}
            className="nav-group-link"
            title={link.text}
            target={link?.target}
          >
            {link?.icon && <img src={link.icon} alt={link.text} />}
            {link.text}
            <span className="nav-group-link-icon">
              <ArrowDownIcon aria-label="Arrow Down" />
            </span>
          </a>
          <NavGroupLinks groups={link.groups} />
        </li>
      );
    } else if (link.key === 'home') {
      content.push(
        <li key={link.key} className={`${cls} home`}>
          <a
            href={link.url}
            className="home"
            onClick={
              type === 'desktop' && link.key === 'home'
                ? handleShowDesktopNav
                : handleMobileItemClick
            }
            title={link.text}
            target={link?.target}
          >
            {link?.icon && <img src={link.icon} alt={link.text} />}
            {link.text}
          </a>
        </li>
      );
    } else {
      content.push(
        <li key={link.key} className={cls}>
          <a
            href={link.url}
            onClick={type === 'mobile' ? handleMobileItemClick : () => {}}
            title={link.text}
            target={link?.target}
          >
            {link.text}
          </a>
        </li>
      );
    }
  });
  return content;
};

export default NavLinks;
