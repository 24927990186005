export const tribesFilter = [
  {
    id: 'angel',
    name: 'Angel',
  },
  {
    id: 'builder',
    name: 'Builder',
  },
  {
    id: 'connector',
    name: 'Connector',
  },
  {
    id: 'dreamer',
    name: 'Dreamer',
  },
  {
    id: 'neo-capitalist',
    name: 'Neo-Capitalist',
  },
];
export const xpHistoryFilter = [
  {
    id: 1,
    name: 'Staking',
  },
  {
    id: 5,
    name: 'Experience',
  },
  {
    id: 9,
    name: 'Social Media',
  },
];
export const leaderBoardSort = [
  {
    id: 'rank',
    name: 'RANK: High to Low',
  },
  {
    id: '-rank',
    name: 'RANK: Low to High',
  },
  {
    id: 'tribe',
    name: 'TRIBE: A to Z',
  },
  {
    id: '-tribe',
    name: 'TRIBE: Z to A',
  },
];
export const realmSort = [
  {
    id: 'rank',
    name: 'RANK: High to Low',
  },
  {
    id: '-rank',
    name: 'RANK: Low to High',
  },
  {
    id: '-wallet_address',
    name: 'Address: A to Z',
  },
  {
    id: 'wallet_address',
    name: 'Address: Z to A',
  },
];
export const myMocaSort = [
  {
    id: 'desc',
    name: 'High to Low',
  },
  {
    id: 'asc',
    name: 'Low to High',
  },
];
export const myMocaHistorySort = [
  {
    id: 'date',
    name: 'Date: New to Old',
  },
  {
    id: '-date',
    name: 'Date: Old to New',
  },
  {
    id: '-xp_from',
    name: 'XP From: A to Z',
  },
  {
    id: 'xp_from',
    name: 'XP From: Z to A',
  },
  {
    id: 'xp_gained',
    name: 'XP Gained: High to Low',
  },
  {
    id: '-xp_gained',
    name: 'XP Gained: Low to High',
  },
];

export const openSeaLink =
  'https://opensea.io/assets/ethereum/0x59325733eb952a92e069c87f0a6168b29e80627f';

export const marketplaceLink =
  'https://marketplace.mocaverse.xyz/token/ETHEREUM:0x59325733eb952a92e069c87f0a6168b29e80627f';

export const marketplaceHeroBannerLink =
  'https://marketplace.mocaverse.xyz/?utm_source=website&utm_medium=herobanner&utm_campaign=launch';
export const marketplaceSideButtonLink =
  'https://marketplace.mocaverse.xyz/?utm_source=website&utm_medium=ctaicon&utm_campaign=launch';
export const marketplaceHeaderLink =
  'https://marketplace.mocaverse.xyz/?utm_source=website&utm_medium=header&utm_campaign=launch';

export const tabsModal = [
  {
    key: 'MocaDetail',
    text: "Moca's Details",
  },
  {
    key: 'MocaXPHistory',
    text: 'Moca XP History',
  },
];
export const tabsSeason = [
  {
    key: '1',
    text: 'Season 1',
  },
  {
    key: '2',
    text: 'Season 2',
  },
];
export const leaderBoardDustlandSort = [
  {
    id: '-rank_max_score',
    name: 'RANK: High to Low',
  },
  {
    id: 'rank_max_score',
    name: 'RANK: Low to High',
  },
  {
    id: 'tribe',
    name: 'TRIBE: A to Z',
  },
  {
    id: '-tribe',
    name: 'TRIBE: Z to A',
  },
  {
    id: 'nickname',
    name: 'ENERGY SPENT: Low to High',
  },
  {
    id: '-nickname',
    name: 'ENERGY SPENT: High to Low',
  },
];
export const leaderBoardEventSort = [
  {
    id: '-rank_max_score',
    name: 'RANK: High to Low',
  },
  {
    id: 'rank_max_score',
    name: 'RANK: Low to High',
  },
  {
    id: 'tribe',
    name: 'TRIBE: A to Z',
  },
  {
    id: '-tribe',
    name: 'TRIBE: Z to A',
  },
  {
    id: 'nickname',
    name: 'Gamee ID: A to Z',
  },
  {
    id: '-nickname',
    name: 'Gamee ID: Z to A',
  },
];
export const leaderBoardRealmEventSort = [
  {
    id: '-rank_max_score',
    name: 'RANK: High to Low',
  },
  {
    id: 'rank_max_score',
    name: 'RANK: Low to High',
  },
  {
    id: 'nickname',
    name: 'Gamee ID: A to Z',
  },
  {
    id: '-nickname',
    name: 'Gamee ID: Z to A',
  },
];
export const leaderBoardRealmDustlandSort = [
  {
    id: '-rank_max_score',
    name: 'RANK: High to Low',
  },
  {
    id: 'rank_max_score',
    name: 'RANK: Low to High',
  },
  {
    id: 'nickname',
    name: 'ENERGY SPENT: Low to High',
  },
  {
    id: '-nickname',
    name: 'ENERGY SPENT: High to Low',
  },
];
export const SORT_OPTIONS = {
  RANK: 'rank_max_score',
  MOCA_NAME: 'moca_name',
  WALLET: 'wallet_address',
  NICKNAME: 'nickname',
  TRIBE: 'tribe',
  MAX_SCORE: 'max_score',
  MAXSTREAK: 'max_streaks',
};
export const ITEMSPERPAGE = 15;
export const API_EVENT_JSON = {
  MOCA: 'https://mocaverse-resources.s3.us-west-2.amazonaws.com/gamee/sample_gamee_leaderboard_mocas.json',
  REALM:
    'https://mocaverse-resources.s3.us-west-2.amazonaws.com/gamee/sample_gamee_leaderboard_realm.json',
  TRIBE:
    'https://mocaverse-resources.s3.us-west-2.amazonaws.com/gamee/gamee_leaderboard_tribe.json',
};
