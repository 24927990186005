import React, { useMemo } from 'react';
import {
  EVENT_REGISTRATION_STATUS,
  EVENT_RESULT_TYPE,
} from '../../utils/eventHelpers';
import EventMocaGrid from './EventMocaGrid';
import EventMsg from './EventMsg';

// const mocaList = [
//   {
//     moca_id: '1351',
//     img: '',
//     moca_name: '1223',
//     tribe: 'neo-capitalist',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1352',
//     img: '',
//     moca_name: '1224',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1353',
//     img: '',
//     moca_name: '1225',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1354',
//     img: '',
//     moca_name: '1226',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1355',
//     img: '',
//     moca_name: '1227',
//     tribe: 'builder',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1356',
//     img: '',
//     moca_name: '1228',
//     tribe: 'angel',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1357',
//     img: '',
//     moca_name: '1229',
//     tribe: 'dreamer',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1358',
//     img: '',
//     moca_name: '1230',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1359',
//     img: '',
//     moca_name: '1231',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1360',
//     img: '',
//     moca_name: '1232',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1361',
//     img: '',
//     moca_name: '1233',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
//   {
//     moca_id: '1362',
//     img: '',
//     moca_name: '1234',
//     tribe: 'connector',
//     total_xp: 999999999,
//   },
// ];

const EventSelectMoca = ({
  status,
  handleRegister,
  handleClickMoca,
  selectedMocaItem,
  error,
  mocaList,
}) => {
  const rgBtn = useMemo(() => {
    if (status === EVENT_REGISTRATION_STATUS.IN_PROCESS) {
      return (
        <button
          className="btn btn-md-second btn-register-now"
          onClick={handleRegister}
          aria-label="Register Now"
          disabled={!selectedMocaItem}
        >
          Register Now
        </button>
      );
    }
    return (
      <>
        <button
          className="btn btn-md-second btn-register-now"
          disabled
          aria-label="Register Now"
        >
          Register Now
        </button>
        <p className="event-sb-action-note">
          Registration period has not started yet.
        </p>
      </>
    );
  }, [status, handleRegister]);

  return (
    <>
      {error && (
        <EventMsg
          type={EVENT_RESULT_TYPE.ERROR}
          message={error?.msg}
          // txnHash={error?.txnHash}
        />
      )}
      <div className="event-sb-header">
        <h2 className="event-sb-title">Select a Moca to be your Agent</h2>
        <div className="event-sb-note">
          MocaXP will be gained to the selected Moca. <br />
          You cannot change your selection after registered.
        </div>
      </div>
      <div className="event-sb-main event-select-moca">
        <EventMocaGrid
          classNamePrefix="event-moca"
          items={mocaList}
          selectedItem={selectedMocaItem}
          handleClick={handleClickMoca}
        />
      </div>
      <div className="event-sb-actions">{rgBtn}</div>
    </>
  );
};

export default EventSelectMoca;
