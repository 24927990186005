import currencyjs from 'currency.js';

// wrap with currenctjs and extract for further testing, align frontend and prepare for decimals handling
export const calculateTotalPrice = (quantity, remainingFree, currency) => {
  const price = currencyjs(currency.price, {
    precision: 8,
    errorOnInvalid: true,
  });

  return price.multiply(Math.max(quantity - remainingFree, 0)).value;
};

export const formatPrice = (price, unit = '', precision = 0) => {
  const desiredPrecision = price % 1 !== 0 ? 1 : 0;

  if (unit !== '') {
    return `${currencyjs(price, {
      symbol: '',
      precision: desiredPrecision,
    }).format()} ${unit}`;
  }
  return currencyjs(price, { symbol: '', precision }).format();
};
