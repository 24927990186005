import { submitRealms } from '../api/fetchData';
// tx id shd be token id such that .data() for metadata is not necessary
export const parseTokenIds = (transactions = []) =>
  // eslint-disable-next-line no-underscore-dangle
  transactions.map((tx) => parseInt(tx.id._hex, 16));

// TODO consider bulk
export const sendTokenToRealm = async (realms, realmNameToSend, tokenId) => {
  const realmId = realms.find((x) => x.name === realmNameToSend)?.id;
  if (!realmId || tokenId === undefined) {
    return {
      success: false,
    };
  }
  const data = await submitRealms(realmId, tokenId);
  if (data.errors) {
    return {
      success: false,
      errors: data.errors,
    };
  }
  return {
    success: true,
  };
};
export const formatWallet = (wallet) => {
  if (!wallet) return '';
  if (typeof wallet === 'string') {
    const prefix = wallet.slice(0, 6);
    const suffix = wallet.slice(-4);
    return `${prefix}...${suffix}`;
  }
  return wallet;
};
