import React, { useEffect } from 'react';
import DustlandWrapper from '../components/dustland/DustlandWrapper';

const Dustland = () => {
  useEffect(() => {
    document.body.classList = 'dustland-page event-page';
  });

  return <DustlandWrapper />;
};

export default Dustland;
