import React from 'react';
import Slider from 'react-slick';
import DoGoodRealmItem from './DoGoodRealmItem';
import LearnRealmItem from './LearnRealmItem';
import InvestRealmItem from './InvestRealmItem';
import PlayRealmItem from './PlayRealmItem';

const RealmsSlider = () => (
  <div className="realms-slider">
    <Slider arrows infinite speed={500} slidesToShow={1} slidesToScroll={1}>
      <div>
        <PlayRealmItem isSlider />
      </div>
      <div>
        <InvestRealmItem isSlider />
      </div>
      <div>
        <LearnRealmItem isSlider />
      </div>
      <div>
        <DoGoodRealmItem isSlider />
      </div>
    </Slider>
  </div>
);

export default RealmsSlider;
