import React from 'react';
import { useCountdown } from '../../hooks/useCountDown';
import { EVENT_TIME_STATUS } from '../../utils/eventHelpers';
import EventBar from './EventBar';
import EventCountDown from './EventCountDown';

const EventBarWrapper = ({ data, statusEvent, countDownType = '' }) => {
  const { hours, minutes, days } = useCountdown(data?.end_date);
  const converTime = (isoDate) => {
    const dateObj = new Date(isoDate);
    const month = dateObj.toLocaleString('default', {
      timeZone: 'UTC',
      month: 'short',
    });
    const rDay = dateObj.getUTCDate();
    const rHours = dateObj.getUTCHours();
    const rMinutes = dateObj.getUTCMinutes();

    return `${month} ${rDay}, ${rHours}:${rMinutes < 10 ? '0' : ''}${rMinutes}`;
  };

  if (statusEvent !== EVENT_TIME_STATUS.LIVE) {
    return (
      <EventBar
        className="event-bar-not-started"
        title="Registration:"
        subtitle={
          data && (
            <>
              <span className="date">{converTime(data?.start_date)}</span> -{' '}
              <span className="date">{converTime(data?.end_date)} (UTC)</span>
            </>
          )
        }
      />
    );
  }

  return (
    <EventBar
      title="Registration closes in"
      subtitle={
        <EventCountDown
          hours={hours}
          minutes={minutes}
          days={days}
          type={countDownType}
        />
      }
    />
  );
};

export default EventBarWrapper;
