import React from 'react';
import { HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';
import mocaIcon from '../../assets/images/user-board/moca-pink.svg';

const SoftStakeTooltip = () => (
  <Tooltip
    className="moca-event-tooltip bottom-tooltip soft-tooltip"
    title={<HelpIcon />}
    content={
      <div className="moca-SoftStake-tooltip">
        <div className="SoftStake-tooltip-header">
          <h2 className="header-name">Soft Stake</h2>
          <span className="header-value">
            <h2>8XP</h2>
            <span>
              {' '}
              to all
              <img src={mocaIcon} alt="" />
            </span>
          </span>
        </div>
        <p className="description">Hold Moca for 1 full staking period.</p>
      </div>
    }
  />
);

export default SoftStakeTooltip;
