import React, { useEffect, useState } from 'react';
import { env } from '../../../utils/envHelpers';

import NoData from '../NoData';
import Sort from '../Sort';
import TableTicket from './TableTicket';

import iconSort from '../../../assets/images/mocana/icon-down.svg';
import iconSearch from '../../../assets/images/mocana/icon-search.svg';
import { useDebounce } from '../../../hooks/useDebounce';
import { sanitizeInput } from '../../../utils/mocanaHelper';

import iconClose from '../../../assets/images/mocana/close.svg';
import iconRemoveS from '../../../assets/images/mocana/icon-remove-s.svg';
import ticket from '../../../assets/images/ticket-tiny.svg';
import iconAlert from '../../../assets/images/alert-icon.svg';

// import iData from '../../../assets/dustlandJson/realm_ranking.json';

const TicketLeaderboard = () => {
  const [showSort, setShowSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTotalScore, setIsTotalScore] = useState(true);

  const [count, setCount] = useState(0);

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchTerm = useDebounce(searchInput, 400);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [ordering, setOrdering] = useState('-rank_max_score');

  const [initData, setInitData] = useState();
  const [data, setData] = useState();

  const handleChangeParam = () => {
    if (page !== 1 || offset !== 0) {
      setPage(1);
      setOffset(0);
    }
  };
  const handleInputChange = (event) => {
    handleChangeParam();
    setSearchInput(event.target.value);
  };

  const handleRemoveSearch = () => {
    setSearchInput('');
  };
  // Sort filtered data based on ordering
  const handleSortedData = (filteredData) => {
    switch (ordering) {
      case '-rank_max_score':
        return isTotalScore
          ? filteredData.sort(
              (a, b) => a.rank_total_distance - b.rank_total_distance
            )
          : filteredData.sort((a, b) => a.rank_avg_pace - b.rank_avg_pace);
      case 'rank_max_score':
        return isTotalScore
          ? filteredData.sort(
              (a, b) => b.rank_total_distance - a.rank_total_distance
            )
          : filteredData.sort((a, b) => b.rank_avg_pace - a.rank_avg_pace);
      case 'nickname':
        return filteredData.sort((a, b) => a.nickname - b.nickname);
      case '-nickname':
        return filteredData.sort((a, b) => b.nickname - a.nickname);
      case 'wallet_address':
        return filteredData.sort((a, b) =>
          a.wallet_address.toString().localeCompare(b.wallet_address.toString())
        );
      case '-wallet_address':
        return filteredData.sort((a, b) =>
          b.wallet_address.toString().localeCompare(a.wallet_address.toString())
        );
      case 'max_score':
        return isTotalScore
          ? filteredData.sort((a, b) => a.total_score - b.total_score)
          : filteredData.sort((a, b) => a.max_score - b.max_score);
      case '-max_score':
        return isTotalScore
          ? filteredData.sort((a, b) => b.total_score - a.total_score)
          : filteredData.sort((a, b) => b.max_score - a.max_score);
      default:
        return filteredData;
    }
  };
  const handleShowSort = () => {
    setShowSort(!showSort);
  };
  useEffect(() => {
    // Filter data based on search input
    if (initData) {
      const filteredData = initData.filter((item) =>
        // item.nickname
        //   .toLowerCase()
        //   .includes(sanitizeInput(debouncedSearchTerm).toLowerCase()) ||
        item.wallet_address
          .toString()
          .toLowerCase()
          .includes(sanitizeInput(debouncedSearchTerm).toLowerCase())
      );

      const sortedData = handleSortedData(filteredData);
      // Calculate total count
      const totalCount = sortedData.length;
      // console.log(sortedData, offset, offset + 15, ordering, 9);

      // Set the data for the current page and offset
      const dataForPage = sortedData?.slice(offset, offset + 15);

      setCount(totalCount);
      setData(dataForPage);
    }
  }, [debouncedSearchTerm, page, offset, ordering, initData, isTotalScore]);

  useEffect(() => {
    if (showSort) {
      document.body.classList.add('active-sort');
    } else {
      document.body.classList.remove('active-sort');
    }
  }, [showSort]);

  useEffect(() => {
    // setInitData(iData);

    setLoading(true);
    fetch(env.dustlandRealmJson)
      .then((response) => response.json())
      .then((values) => {
        setInitData(values);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching realm leaderboard data:', error);
        setLoading(false);
      });
  }, []);
  console.log(initData);
  return (
    <section className="moca-table realm-table" id="moca">
      <div className="moca-title">
        <img src={ticket} alt="" />
        2. Realm Ticket Leaderboard
      </div>
      <div className="sort-filter">
        <div className="sort-tabs">
          <button
            className={`btn-sort ${isTotalScore ? 'active' : ''}`}
            onClick={() => setIsTotalScore(true)}
          >
            Total Distance
          </button>
          <button
            className={`btn-sort  ${!isTotalScore ? 'active' : ''}`}
            onClick={() => setIsTotalScore(false)}
          >
            Avg. Pace
          </button>
        </div>
        <div className="moca-search-filter">
          <div className="moca-search">
            <img src={iconSearch} alt="icon-search" />
            <input
              type="text"
              name="search"
              value={searchInput}
              placeholder="Search by Moca name"
              onChange={handleInputChange}
              required
            />
            {searchInput.length !== 0 && (
              <button onClick={handleRemoveSearch}>
                <img src={iconRemoveS} alt="icon-r-search" />
              </button>
            )}
          </div>
          <div className="mocana-filter">
            <div className="mocana-button-sort">
              <button onClick={() => setShowSort(!showSort)}>
                <span>Sort</span>
                <span>
                  <img src={iconSort} alt="" />
                  <img src={iconSort} alt="" />
                </span>
              </button>
            </div>

            <div className={`mocana-list-sort ${showSort && 'active'}`}>
              <Sort
                setShowSort={handleShowSort}
                setValueSort={setOrdering}
                isRealm
              />
              <div onClick={() => handleShowSort()} className="toggle-button">
                <img src={iconClose} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isTotalScore && (
        <div className="moca-notice">
          <img src={iconAlert} alt="alert" />
          <p>
            Top player rewards on both Total Distance and Avg. Pace Leader board
            are mutually exclusive
          </p>
        </div>
      )}
      <div className="moca-results">
        {data && data.length === 0 ? (
          <NoData text="Player / Wallet address is not registered in this experience." />
        ) : (
          <TableTicket
            data={data}
            count={count}
            setPage={setPage}
            loading={loading}
            setOffset={setOffset}
            setValueSort={setOrdering}
            currentPage={page}
            isTotalScore={isTotalScore}
          />
        )}
      </div>
    </section>
  );
};
export default TicketLeaderboard;
