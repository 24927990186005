/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import {
  useLogin,
  useUser,
  useAddress,
  useNetworkMismatch,
  useLogout,
  useNetwork,
} from '@thirdweb-dev/react';
import { useSelector, useDispatch } from 'react-redux';
import { setAddress, setUser } from '../redux/user/userSlice';
import {
  checkIsErrorWithAuth,
  formatErrorMessage,
  ERROR_MESSAGES,
} from '../utils/errorsHelper';
import { checkIsAlreadyMinted } from '../utils/mintHelper';
import { env } from '../utils/envHelpers';
import {
  setIsLoginInProgress,
  setEligibility,
  setError,
  setQuotas,
  setSupplies,
  setSurveyItems,
} from '../redux/data/dataSlice';
import {
  checkQuotas,
  getEligibility,
  getSupplies,
  fetchSurveyItems,
} from '../api/fetchData';

const PageWrapper = ({ className, children }) => {
  const networknNotMatched = useNetworkMismatch();
  const dispatch = useDispatch();
  const { error, isLoginInProgress, currentUser, eligibility, quotas } =
    useSelector((state) => ({
      error: state.data.error,
      isLoginInProgress: state.data.isLoginInProgress,
      currentUser: state.user?.user,
      eligibility: state.data.eligibility,
      quotas: state.data.quotas,
    }));
  const { login } = useLogin();
  const { logout } = useLogout();
  const [, switchNetwork] = useNetwork();
  const user = useUser();
  const address = useAddress();

  const getEl = async () => {
    const dataQuotas = await checkQuotas();
    dispatch(setQuotas(dataQuotas));

    const el = await getEligibility();
    dispatch(setEligibility(el));
    if (el.errors) {
      const key = el.errors[0]?.key;
      if (key !== 'SURVEY_NOT_SUBMITTED' && key !== 'ELIGIBLILITY_MISSING') {
        dispatch(setError(formatErrorMessage(el.errors)));
      }
    }
  };

  const getData = async () => {
    const res = await getSupplies();
    dispatch(setSupplies(res));
    const data = await fetchSurveyItems();
    if (data.objects) {
      dispatch(setSurveyItems(data.objects));
    } else {
      dispatch(setError('Something went wrong, try again later'));
    }
  };

  const handleProcess = () => {
    const isAlreadyMinted = checkIsAlreadyMinted(quotas);
    // need to log out after switching another method
    if (
      address &&
      user.user &&
      user.user.address &&
      address !== user.user.address
    ) {
      logout()
        .then(() => {
          dispatch(setUser(null));
          dispatch(setIsLoginInProgress(false));
        })
        .catch((err) => {
          console.log('logout error', err);
          // further state handling re UI
        });
      return;
    }
    // disconnect
    if (!address && user?.user && currentUser && isLoginInProgress) {
      if (!isAlreadyMinted) {
        dispatch(setQuotas(null));
        dispatch(setEligibility(null));
        dispatch(setSurveyItems(null));
      }
    }
    // logged
    if (!address && !user?.user && currentUser && isLoginInProgress) {
      dispatch(setUser(null));
      dispatch(setIsLoginInProgress(false));
      return;
    }
    if (address && !currentUser && user.user && !user.isLoading) {
      dispatch(setUser(user));
      dispatch(setAddress(user.address));
      if (!isAlreadyMinted) {
        getEl();
      }
    } else if (address && !user.isLoading && !isLoginInProgress) {
      if (checkIsErrorWithAuth(error)) {
        return;
      }
      dispatch(setIsLoginInProgress(true));
      dispatch(setError(''));

      // login().catch((err) => {
      //   console.log('login error', err);
      //   dispatch(setError(formatErrorMessage(ERROR_MESSAGES.UNAUTHORIZED)));
      //   if (!isAlreadyMinted) {
      //     dispatch(setQuotas(null));
      //     dispatch(setEligibility(null));
      //     dispatch(setSurveyItems(null));
      //   }
      //   dispatch(setUser(null));
      //   dispatch(setIsLoginInProgress(false));
      // });
    }
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (networknNotMatched && address) {
      if (!switchNetwork) {
        // gnosis safe might not support this
        // https://github.com/thirdweb-animoca-gmgn/mocaverse-site/issues/126
        return;
      }
      switchNetwork(env.desiredChainId)
        .then(() => {
          handleProcess(user, address);
        })
        .catch((e) => console.log('switching network error', e));
      return;
    }
    handleProcess(user, address);
  }, [user?.user, user?.isLoggedIn, address, error]);

  const tagManagerArgs = useMemo(
    () => ({
      dataLayer: {
        userId: address || '',
      },
      dataLayerName: 'PageDataLayer',
    }),
    [address]
  );

  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, [tagManagerArgs]);

  return <main className={className}>{children}</main>;
};

export default React.memo(PageWrapper);
