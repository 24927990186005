import React from 'react';
import { HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';

const EventEligibleTooltip = () => (
  <Tooltip
    className="moca-event-tooltip"
    title={<HelpIcon />}
    content={
      <>
        <h2>Be Eligible to Earn Moca XP</h2>
        <p>
          Your Moca must fulfill one of the requirement below to earn Moca XP:
        </p>
        <ul>
          <li>Hold in the same wallet ( no listing and transfer )</li>
        </ul>
      </>
    }
  />
);

export default EventEligibleTooltip;
