import React from 'react';
import { HelpIconWhite, HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';

const RealmTooltip = ({ label }) => (
  <Tooltip
    className="moca-event-tooltip realm-tooltip"
    title={label === 'white' ? <HelpIconWhite /> : <HelpIcon />}
    content={
      <>
        <h2>What is Realm Ticket Miles?</h2>
        <p>
          Realm Ticket Miles is our power system where we keep track of every
          Realm Ticket Holder’s participation and engagement in Mocaverse.
          <br /> <br />
          More Realm Ticket Miles means higher chance of accessing benefits and
          becoming a Moca Holder.
        </p>
      </>
    }
  />
);

export default RealmTooltip;
