import React, { useState } from 'react';
import { env } from '../../utils/envHelpers';
import rareBadge from '../../assets/images/user-board/icon_moca_rare.svg';
import TableMyMocaXp from './TableMocaXp';

const TableMyMoca = ({ data, xpSource }) => {
  const [selectedMoca, setSelectedMoca] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = (item) => {
    setSelectedMoca(item);
    setShowTooltip(!showTooltip);
  };
  return (
    <>
      {data.map((item) => (
        <div className="list-moca-item" key={item.id}>
          <img
            className="thumbnail"
            src={`${`${env.userBoardThumbnailUrl}%23${item.moca_name}`}.jpg`}
            alt={item.moca_name}
          />
          <div className="moca-item-info">
            <p className="info-id">#{item.moca_name}</p>
            <p className="info-xp">{item.moca_xp} XP</p>
          </div>
          {item.is_rare && (
            <div className="rare-badge">
              <img src={rareBadge} alt="" />
            </div>
          )}
          {item.is_staked && <p className="staked">Staked</p>}

          <div className="view-detail">
            <button
              className="btn-view-detail"
              onClick={() => toggleTooltip(item)}
            >
              View Detail
            </button>
          </div>
        </div>
      ))}
      {showTooltip && (
        <TableMyMocaXp
          setShowTooltip={() => setShowTooltip(!showTooltip)}
          selectedMoca={selectedMoca}
          xpSource={xpSource}
        />
      )}
    </>
  );
};

export default TableMyMoca;
