import { useState, useEffect } from 'react';
import { formatNumber } from '../utils/textHelper';
import { env } from '../utils/envHelpers';
import {
  EVENT_REGISTRATION_STATUS,
  EVENT_TIME_STATUS,
} from '../utils/eventHelpers';

export const useCountdown = (startDate) => {
  const countDownDate = new Date(startDate || env.targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  if (countDown < 0) {
    return { hours: 'hh', minutes: 'mm', seconds: 'ss' };
  }

  return {
    days: days && days !== 0 ? formatNumber(days) : '00',
    hours: hours && hours !== 0 ? formatNumber(hours) : '00',
    minutes: minutes && minutes !== 0 ? formatNumber(minutes) : '00',
    seconds: seconds && seconds !== 0 ? formatNumber(seconds) : '00',
  };
};

const convertDatetoTimeZone = (date = '') => {
  if (date !== '') {
    return new Date(date).toLocaleString('en-US', {
      timeZone: 'Asia/Hong_Kong',
    });
  }
  return new Date().toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' });
};

const formatDateString = (date = '') =>
  date.replace(/,[ ]\d{4}/i, '').replace(/:00 UTC/i, '');

export const useEventRegistrationCountDown = (startD, endD) => {
  const eventStartedDateOrg =
    startD || env.eventRegistrationStartedDate || '20 May, 2023 12:00:00 EST';
  const eventEndedDateOrg =
    endD || env.eventRegistrationEndedDate || '30 May, 2023 02:00:00 EST';
  const eventStatedDate = new Date(convertDatetoTimeZone(eventStartedDateOrg));
  const eventEndedDate = new Date(convertDatetoTimeZone(eventEndedDateOrg));
  const [status, setStatus] = useState(EVENT_REGISTRATION_STATUS.NOT_STARTED);
  const [countDown, setCountDown] = useState(
    eventEndedDate.getTime() - eventStatedDate.getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const crtDate = new Date(convertDatetoTimeZone());
      const crtTime = crtDate.getTime();
      const startedTime = eventStatedDate.getTime();
      const endedTime = eventEndedDate.getTime();
      if (startedTime <= crtTime && crtTime <= endedTime) {
        setStatus(EVENT_REGISTRATION_STATUS.IN_PROCESS);
      } else if (crtTime > endedTime) {
        setStatus(EVENT_REGISTRATION_STATUS.ENDED);
      }
      setCountDown(endedTime - crtTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [eventStatedDate, eventEndedDate]);

  const days = Math.floor(countDown / (24 * 60 * 60 * 1000));
  const hours = Math.floor((countDown / (60 * 60 * 1000)) % 24);
  const minutes = Math.floor((countDown / (60 * 1000)) % 60);
  const seconds = Math.floor((countDown / 1000) % 60);
  // const startedDate = formatDateString(eventStartedDateOrg);
  // const endedDate = formatDateString(eventEndedDateOrg);

  // console.log('event registration',{eventEndedDate, eventStatedDate,eventStartedDateOrg, eventEndedDateOrg, status, days, hours, minutes, seconds})

  return {
    statusRegistration: status,
    days: days && days !== 0 ? formatNumber(days) : '00',
    hours: hours && hours !== 0 ? formatNumber(hours) : '00',
    minutes: minutes && minutes !== 0 ? formatNumber(minutes) : '00',
    seconds: seconds && seconds !== 0 ? formatNumber(seconds) : '00',
    // startedDate,
    // endedDate,
  };
};

export const useEventTimeCountDown = (startDate, endDate) => {
  const eventStartedDateUTC =
    startDate || env.eventTimeStartedDate || '20 Apr, 2023 00:00:00 UTC';
  const eventEndedDateUTC =
    endDate || env.eventTimeEndedDate || '27 Apr, 2023 00:00:00 UTC';
  const eventStatedDate = new Date(convertDatetoTimeZone(eventStartedDateUTC));
  const eventEndedDate = new Date(convertDatetoTimeZone(eventEndedDateUTC));
  const [status, setStatus] = useState('');
  const [countDown, setCountDown] = useState(
    eventEndedDate.getTime() - eventStatedDate.getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const crtDate = new Date(convertDatetoTimeZone());
      const crtTime = crtDate.getTime();
      const startedTime = eventStatedDate.getTime();
      const endedTime = eventEndedDate.getTime();
      if (startedTime <= crtTime && crtTime <= endedTime) {
        setStatus(EVENT_TIME_STATUS.LIVE);
      } else if (crtTime > endedTime) {
        setStatus(EVENT_TIME_STATUS.ENDED);
      } else {
        setStatus(EVENT_TIME_STATUS.NOT_STARTED);
      }
      setCountDown(endedTime - crtTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [eventStatedDate, eventEndedDate]);

  const days = Math.floor(countDown / (24 * 60 * 60 * 1000));
  const hours = Math.floor((countDown / (60 * 60 * 1000)) % 24);
  const minutes = Math.floor((countDown / (60 * 1000)) % 60);
  const seconds = Math.floor((countDown / 1000) % 60);
  const startedDate = formatDateString(eventStartedDateUTC);
  const endedDate = formatDateString(eventEndedDateUTC);

  // console.log('event Time',{eventEndedDate, eventStatedDate,eventStartedDateUTC, eventEndedDateUTC, startedDate, endedDate, status, days, hours, minutes, seconds})

  return {
    statusEvent: status,
    days: days && days !== 0 ? formatNumber(days) : '00',
    hours: hours && hours !== 0 ? formatNumber(hours) : '00',
    minutes: minutes && minutes !== 0 ? formatNumber(minutes) : '00',
    seconds: seconds && seconds !== 0 ? formatNumber(seconds) : '00',
    startedDate,
    endedDate,
  };
};
