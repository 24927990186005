import React, { useState } from 'react';
import { myMocaSort } from '../../utils/constants';

const Sort = ({ setShowSort, setValueSort }) => {
  const [sort, setSort] = useState([]);

  const handleTribeChange = (id) => {
    const index = sort.indexOf(id);
    if (index === -1) {
      setSort([id]);
    } else {
      const newSelectedSort = [...sort];
      newSelectedSort.splice(index, 1);
      setSort(newSelectedSort);
    }
  };

  const handleConfirm = () => {
    setValueSort(sort.toString());
    setShowSort(false);
  };
  const handleReset = () => {
    setValueSort('asc');
    setSort([]);
    setShowSort(false);
  };
  const handleClose = () => {
    setShowSort(false);
  };
  return (
    <div className="sort-wrapper">
      <h2>
        <span className="text-filter">Sort</span>
        <button className="text-reset" onClick={handleReset}>
          Reset All
        </button>
      </h2>
      <div className="sub-filter-section">
        <h3>XP</h3>
        <ul>
          {myMocaSort.map((item) => (
            <li key={item.id}>
              <label className="custom-input" htmlFor="sort">
                {item.name}
                <input
                  type="checkbox"
                  name="sort"
                  value={item.id}
                  checked={sort.includes(item.id)}
                  onChange={() => handleTribeChange(item.id)}
                />
                <span className="checkmark" />
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="group-btn">
        <button className="btn-apply-filter btn-cancel" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-apply-filter" onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Sort;
