import React from 'react';
import Discord from '../../assets/images/discord-black.png';
import Twitter from '../../assets/images/twitter-black.png';
import Marketplace from '../../assets/images/mocana/moca_marketplace.svg';
import Etherscan from '../../assets/images/etherscan-black.png';
import { env } from '../../utils/envHelpers';
import { marketplaceHeaderLink } from '../../utils/constants';

const HeaderSocials = () => (
  <div className="social-btn">
    <ul>
      <li>
        <a
          href={marketplaceHeaderLink}
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Marketplace} alt="Marketplace" />
        </a>
      </li>
      <li>
        <a
          href="http://discord.gg/MocaverseNFT"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Discord} alt="Discord" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/MocaverseNFT"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href={env.contractUrl}
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Etherscan} alt="Etherscan" />
        </a>
      </li>
    </ul>
  </div>
);

export default React.memo(HeaderSocials);
