import React from 'react';
import FaqWrapper from './faq/FaqWrapper';
import FaqContent from './faq/FaqContent';

const Faq = () => (
  <FaqWrapper>
    <FaqContent />
  </FaqWrapper>
);

export default Faq;
