import React, { useState, useEffect } from 'react';
import HomeIcon from '../../assets/images/moca-home.png';
import Modal from '../Modal';
import Socials from './HeaderSocials';
import LinkOutIcon from '../../assets/images/icon-link-out.svg';
import ArrowDownIcon from '../../assets/images/arrow-down.svg';
import { marketplaceHeaderLink } from '../../utils/constants';

// import ConnectWallet from './ConnectWallet';

const MobileNav = ({
  address,
  //  handleGetUpdate,
  //  handleDisconnect
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isShowSubItems, setIsShowSubItems] = useState(false);

  useEffect(() => {
    if (address) {
      setShowModal(false);
    }
  }, [address]);

  const handleClickMenu = () => {
    setIsActive((prev) => !prev);
  };

  const handleShowSubItems = (e) => {
    e?.preventDefault();
    setIsShowSubItems((prev) => !prev);
  };

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <nav>
        <div className={!isActive ? 'mobile close' : ' mobile open'}>
          <div
            className={!isActive ? 'mobile-btn close' : ' mobile-btn open'}
            onClick={handleClickMenu}
          >
            <img src={HomeIcon} className="mobile-moca-icon" alt="" />
            <span>Mocaverse</span>
          </div>
          <div className="connect-wrapper">
            <div className="connect btn">
              <a href="/mocana">Visit Mocana</a>
            </div>
          </div>

          <div className="mobile-nav">
            <div className="content">
              <li>
                <a href="/#" onClick={handleShowSubItems}>
                  Mocaverse
                  <img src={ArrowDownIcon} alt="Moca Dao link" />
                </a>
              </li>
              <div
                className={`mobile-sub-items ${isShowSubItems ? 'show' : ''}`}
              >
                <a href="/#story-of-mocas">Story of The Mocas</a>
                <a href="/#realms">Realms</a>
                <a href="/#what-is-mocaverse">What is Mocaverse?</a>
                {/* <a href="/#utilities">Utilities</a> */}
                <a href="/#faq">FAQ</a>
              </div>

              <li>
                <a
                  href="https://snapshot.org/#/mocana.eth"
                  target="_blank"
                  rel="noreferrer"
                >
                  Moca DAO
                  <img src={LinkOutIcon} alt="Moca Dao link" />
                </a>
              </li>
              <li className="last">
                <a
                  href={marketplaceHeaderLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Marketplace
                  <img src={LinkOutIcon} alt="Marketplace link" />
                </a>
              </li>

              {/* <li className="last">
                <a href="/#story-of-mocas">
                  Marketplace
                  <img src={LinkOutIcon} alt="Marketplace link" />
                </a>
              </li> */}
            </div>
            <Socials />
          </div>
          <div className="navBg" />
          <div className="navoverlay" onClick={handleClickMenu} />
        </div>
      </nav>
    </>
  );
};

export default React.memo(MobileNav);
