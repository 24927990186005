import React from 'react';
import hourglass from '../../assets/images/user-board/hourglass.svg';
import { countDownMocana } from '../../hooks/useCountDownMocana';

const TimeMyMoca = () => {
  const { days, hours, minutes } = countDownMocana();
  return (
    <div className="staking-period-countdown">
      <img src={hourglass} alt="time" />
      <span className="time">
        {days}D {hours}H {minutes}M
      </span>
    </div>
  );
};

export default TimeMyMoca;
