import React from 'react';

const EventBar = ({ className, title, subtitle }) => (
  <div className={`event-bar-wrapper ${className ?? ''}`}>
    <h3 className="event-bar-title">{title}</h3>
    <div className="event-bar-subtitle">{subtitle}</div>
  </div>
);

export default EventBar;
