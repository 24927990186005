import React from 'react';
import realm from '../../assets/images/realmicon.svg';

const RealmMilesHeader = () => (
  <section className="mocana-countdown-wrapper realm-header">
    <div className="content-header-left">
      <p className="content-header-left-title">
        <img src={realm} alt="" /> <span>Realm Ticket Miles ranking</span>
      </p>
      <p className="content-header-left-description">
        Hold Realm Tickets to participate in experiences and earn RTM.
      </p>
    </div>
  </section>
);

export default RealmMilesHeader;
