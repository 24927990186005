import React, { useEffect } from 'react';
import { useAddress } from '@thirdweb-dev/react';

import { Footer, Socials } from '../components';
import UserBoardHeader from '../components/userBoard/UserBoardHeader';
import UserBoardContent from '../components/userBoard/UserBoardContent';
import EventHeader from '../components/event/EventHeader';

const UserBoard = () => {
  useEffect(() => {
    document.body.classList.add('user-board');
  });
  const address = useAddress();

  return (
    <main className="user-board">
      <EventHeader />
      <Footer />
      <Socials />
      <UserBoardHeader />
      <UserBoardContent address={address} />
    </main>
  );
};

export default UserBoard;
