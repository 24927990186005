import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEventRegistrationCountDown } from '../../hooks/useCountDown';

import {
  EVENT_REGISTRATION_STATUS,
  EVENT_RESULT_TYPE,
  handleListId,
} from '../../utils/eventHelpers';
import { env } from '../../utils/envHelpers';

import EventConnectWallet from './EventConnectWallet';
import EventJoinDiscord from './EventJoinDiscord';
import EventNoEligible from './EventNoEligible';
import EventNoMoca from './EventNoMoca';
import EventSelectMoca from './EventSelectMoca';
import EventSidebarWrapper from './EventSidebarWrapper';

import EventJoinDiscordMobile from './EventJoinDiscordMobile';
import EventRegisterMobile from './EventRegisterMobile';
import EventResult from './EventResult';

import {
  fetchEventRegistration,
  fetchMyMoca,
  fetchQualifiedMoca,
  fetchRealmBalance,
  submitEventRegistration,
  fetchDelegated,
} from '../../api/fetchData';
import Modal from '../Modal';
import EventBarWrapper from './EventBarWrapper';

const EVENT_ID = 2;
// const STATUSAFTER17 = 'not-started';

const EventSideBar = ({ address }) => {
  // const address = '0x9fed225115a1374c7f0faef307d471c80fff1893';
  const signedLicense = useSelector((state) => state.data.signedLicense);

  const { statusRegistration: _statusRegistration } =
    useEventRegistrationCountDown(
      env.dustlandRegistrationStartedDate,
      env.dustlandRegistrationEndedDate
    );

  const statusRegistration = _statusRegistration;
  const [searchParams] = useSearchParams();
  const resultStatus = searchParams.get('result') || EVENT_RESULT_TYPE.SUCCESS;
  const [showModal, setShowModal] = useState(false);
  const [selectedMocaItem, setSelectedMocaItem] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [
    result,
    // setResult
  ] = useState('');

  const [resultWithRealm, setResultWithRealm] = useState('');
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const [mocaList, setMocaList] = useState([]);
  const [delegatedList, setDelegatedList] = useState([]);
  const [delegateItem, setDelegateItem] = useState(null);

  const [isFetching, setIsFetching] = useState(true);
  const [infoRegistration, setInfoRegistration] = useState();
  const [realmsBalance, setRealmsBalance] = useState(0);

  const convertWalletList = (data) => {
    const transformedData = data.reduce((acc, item) => {
      if (!acc.some((obj) => obj.address === item.wallet_address)) {
        acc.push({
          address: item.wallet_address,
          type: 'Delegate',
        });
      }

      if (!acc.some((obj) => obj.address === item.vault_address)) {
        if (item.vault_address !== 'N/A') {
          acc.push({
            address: item.vault_address,
            type: 'Vault',
          });
        }
      }

      return acc;
    }, []);
    return transformedData;
  };

  const handleShowConnectWalletModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleRegisterMobile = useCallback(() => {
    setShowPopup(true);
  }, []);

  const handleClickMoca = useCallback((selectedItem) => {
    setSelectedMocaItem(selectedItem);
  }, []);

  /* register moca_holder */
  const handleRegister = useCallback(async () => {
    const results = await submitEventRegistration(
      delegateItem?.type === 'Vault'
        ? {
            wallet: delegateItem?.address,
            event_id: EVENT_ID,
            moca_id: selectedMocaItem.moca_id,
            registered_by: 'moca_holder',
            delegate_wallet: address,
          }
        : {
            wallet: address,
            event_id: EVENT_ID,
            moca_id: selectedMocaItem.moca_id,
            registered_by: 'moca_holder',
          }
    );

    if (results && !results.error) {
      results.is_owner = true;
      results.is_registered = true;
      setInfoRegistration(results);
    } else {
      setError({
        msg:
          'Transaction failed. Please try again.' || results.error[0].message,
      });
    }
    return false;
  }, [address, selectedMocaItem, delegateItem]);
  /* end register moca_holder */

  /* onFetchEventRegistration realm */
  const handleRegisterWithRealms = useCallback(async () => {
    const results = await submitEventRegistration(
      delegateItem?.type === 'Vault'
        ? {
            wallet: delegateItem?.address,
            event_id: EVENT_ID,
            registered_by: 'realm',
            delegate_wallet: address,
          }
        : {
            wallet: address,
            event_id: EVENT_ID,
            registered_by: 'realm',
          }
    );

    if (results && !results.error) {
      results.is_registered = true;
      setResultWithRealm('success');
      setInfoRegistration(results);
    } else {
      setError({
        msg:
          'Transaction failed. Please try again.' || results.error[0].message,
      });
    }

    return false;
  }, [address, delegateItem]);
  /* end onFetchEventRegistration realm */

  /* get moca list */
  const onFetchQualified = async (data) => {
    const stakedResults = await fetchQualifiedMoca(
      handleListId(data),
      EVENT_ID
    );
    if (stakedResults) {
      const stakedList = data.map((t1) => ({
        ...t1,
        ...stakedResults.results.find((t2) => t2.moca_id === t1.moca_id),
      }));
      setMocaList(stakedList);
    }
  };

  const onFetchMoca = async (wallet) => {
    const results = await fetchMyMoca({
      ordering: 'asc',
      wallet_address: wallet,
      tribe: '',
    });
    if (results) {
      if (results.results.length !== 0) {
        onFetchQualified(results?.results);
      } else {
        setMocaList([]);
      }
    }
  };
  /* end get moca list */

  /* onFetchEventRegistration */
  const onFetchEventRegistration = async () => {
    const results = await fetchEventRegistration(
      delegateItem?.type === 'Vault'
        ? {
            event_id: EVENT_ID,
            wallet_address: delegateItem.address,
            delegate_wallet: address,
          }
        : {
            event_id: EVENT_ID,
            wallet_address: delegateItem?.address || address,
          }
    );
    if (results) {
      setInfoRegistration(results);
    }
  };
  /* end onFetchEventRegistration */

  /* fetch delegated wallet */
  const onFetchDelegated = async () => {
    const results = await fetchDelegated({
      wallet_address: address,
    });
    if (results && results.total !== 0) {
      setDelegatedList(convertWalletList(results.results));
      setDelegateItem(convertWalletList(results.results)[0]);
    }
  };
  /* end fetch delegated wallet */

  /* fetch RealmsBalanc */
  const onFetchRealmsBalance = async (wallet) => {
    const results = await fetchRealmBalance({
      event_id: EVENT_ID,
      wallet_address: wallet,
    });
    const balance = Number(results?.balance);
    setRealmsBalance(balance);
    setIsFetching(false);
  };
  /* end fetch RealmsBalanc */

  const onFilterMocaEligible = (data) => {
    if (data.length === 0) return data;
    const listMocaEligible = data.filter((item) => item.is_qualified);
    return listMocaEligible;
  };

  const onFilterMocaById = (data) => {
    if (mocaList && mocaList.length === 0) return data;
    const mocaId = mocaList.filter((item) => item.moca_id === data.moca_id);
    return mocaId[0];
  };

  const fetchDustlandApi = (wallet) => {
    onFetchMoca(wallet);
    onFetchEventRegistration();
    onFetchRealmsBalance(wallet);
  };

  useEffect(() => {
    if (delegateItem && delegateItem?.address) {
      fetchDustlandApi(delegateItem?.address);
    }
  }, [delegateItem]);
  useEffect(() => {
    if (address) {
      setShowModal(false);
      setIsFetching(true);
      onFetchDelegated();
      fetchDustlandApi(address);
    } else {
      setTimeout(() => {
        setIsFetching(false);
      }, 2000);
    }
  }, [address]);

  const renderContent = useMemo(() => {
    const cls = showPopup ? 'show-popup' : '';
    if (
      statusRegistration === EVENT_REGISTRATION_STATUS.ENDED &&
      (!address || !infoRegistration?.is_registered)
    ) {
      return (
        <>
          <EventSidebarWrapper className="event-sb-modal event-sb-connect">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventJoinDiscord />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventJoinDiscordMobile />
          </EventSidebarWrapper>
        </>
      );
    }

    if (
      address &&
      infoRegistration?.is_registered &&
      infoRegistration?.registered_by !== 'moca_holder'
    ) {
      return (
        <EventSidebarWrapper
          className={`event-sb-mobile event-sb-mobile-collapse event-sb-result ${cls} ${
            isCollapsed ? 'hide' : 'show'
          }`}
        >
          <EventBarWrapper
            result=""
            resultWithRealm={
              infoRegistration?.registered_by !== 'moca_holder'
                ? 'resultWithRealm'
                : ''
            }
            resultStatus={resultStatus}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
          <EventResult
            resultStatus={resultStatus}
            selectedMocaItem={realmsBalance ? null : selectedMocaItem}
            address={address}
          />
        </EventSidebarWrapper>
      );
    }

    if (
      address &&
      infoRegistration?.registered_by === 'moca_holder' &&
      infoRegistration?.moca_id
    ) {
      return (
        <EventSidebarWrapper
          className={`event-sb-mobile event-sb-mobile-collapse event-sb-result ${cls} ${
            isCollapsed ? 'hide' : 'show'
          }`}
        >
          <EventBarWrapper
            result="success"
            resultWithRealm=""
            resultStatus="success"
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
          <EventResult
            resultStatus="success"
            selectedMocaItem={onFilterMocaById(infoRegistration)}
            address={address}
          />
        </EventSidebarWrapper>
      );
    }
    if (address && mocaList.length === 0) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventNoMoca
              status={statusRegistration}
              realms={realmsBalance}
              handleRegister={handleRegisterWithRealms}
              error={error}
              delegateItem={delegateItem}
              delegatedList={delegatedList}
              handleSelectDelegate={setDelegateItem}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    if (
      address &&
      infoRegistration?.is_registered &&
      onFilterMocaEligible(mocaList).length === 0
    ) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventNoEligible
              status={statusRegistration}
              realms={realmsBalance}
              handleRegister={handleRegisterWithRealms}
              error={error}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    if (
      address &&
      !infoRegistration?.is_registered &&
      onFilterMocaEligible(mocaList).length === 0 &&
      mocaList.length !== 0
    ) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventNoEligible
              status={statusRegistration}
              realms={realmsBalance}
              handleRegister={handleRegisterWithRealms}
              error={error}
              delegateItem={delegateItem}
              delegatedList={delegatedList}
              handleSelectDelegate={setDelegateItem}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }

    if (
      address &&
      !infoRegistration?.moca_id &&
      onFilterMocaEligible(mocaList).length !== 0
    ) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventSelectMoca
              status={statusRegistration}
              handleRegister={handleRegister}
              handleClickMoca={handleClickMoca}
              selectedMocaItem={selectedMocaItem}
              error={error}
              mocaList={onFilterMocaEligible(mocaList)}
              delegateItem={delegateItem}
              delegatedList={delegatedList}
              handleSelectDelegate={setDelegateItem}
              address={address}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    if (address && infoRegistration?.moca_id && mocaList.length !== 0) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventSelectMoca
              status={statusRegistration}
              handleRegister={handleRegister}
              handleClickMoca={handleClickMoca}
              selectedMocaItem={selectedMocaItem}
              error={error}
              mocaList={mocaList}
              address={address}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    if (!address) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventConnectWallet
              status={statusRegistration}
              handleClick={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    return (
      <>
        <EventSidebarWrapper className="event-sb-modal event-sb-connect">
          <EventBarWrapper
            result={result}
            resultWithRealm={resultWithRealm}
            resultStatus={resultStatus}
          />
          <EventJoinDiscord />
        </EventSidebarWrapper>
        <EventSidebarWrapper className="event-sb-mobile">
          <EventBarWrapper
            result={result}
            resultWithRealm={resultWithRealm}
            resultStatus={resultStatus}
            countDownType="fixed"
          />
          <EventJoinDiscordMobile />
        </EventSidebarWrapper>
      </>
    );
  }, [
    address,
    result,
    resultWithRealm,
    selectedMocaItem,
    isFetching,
    statusRegistration,
    realmsBalance,
    showPopup,
    infoRegistration,
    mocaList,
    error,
    isCollapsed,
    delegatedList,
    delegateItem,
  ]);

  return (
    <div>
      {isFetching || (!signedLicense && address) ? (
        <EventSidebarWrapper className="event-sb-modal event-sb-connect">
          <EventBarWrapper
            result={result}
            resultWithRealm={resultWithRealm}
            resultStatus={resultStatus}
          />
          <div className="loading-state" />
          <div className="loading-state h-100" />
        </EventSidebarWrapper>
      ) : (
        <>
          {showModal && <Modal toggleModal={() => setShowModal(false)} />}
          {renderContent}
        </>
      )}
    </div>
  );
};

export default EventSideBar;
