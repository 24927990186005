import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { datadogRum } from '@datadog/browser-rum';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import { env } from './utils/envHelpers';

datadogRum.init({
  applicationId: 'c8bd3361-e95c-4849-808e-3d4f719ae466',
  clientToken: 'pubc112f2c85ad615da69dc3b7023c5fe3a',
  site: 'datadoghq.com',
  service: 'mocaverse',
  env: env.envTag,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
const tagManagerArgs = {
  gtmId: env.gtmId,
  auth: env.gtmAuth,
  preview: env.gtmPreview,
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
