import React from 'react';
import { useEventRegistrationCountDown } from '../../hooks/useCountDown';
import {
  EVENT_MESSAGE,
  EVENT_REGISTRATION_STATUS,
  EVENT_RESULT_TYPE,
} from '../../utils/eventHelpers';
import EventBar from './EventBar';
import EventCountDown from './EventCountDown';
import EventMsgBar from './EventMsgBar';
import { env } from '../../utils/envHelpers';

const EventBarWrapper = ({
  result,
  resultWithRealm,
  resultStatus,
  onClick,
  countDownType = '',
}) => {
  const { statusRegistration, hours, minutes, days } =
    useEventRegistrationCountDown(
      env.dustlandRegistrationStartedDate,
      env.dustlandRegistrationEndedDate
    );

  if (result !== '' && resultStatus === EVENT_RESULT_TYPE.SUCCESS) {
    return (
      <EventMsgBar
        type="success"
        message={EVENT_MESSAGE.REGISTERED}
        onClick={onClick}
      />
    );
  }
  if (result !== '' && resultStatus === EVENT_RESULT_TYPE.ERROR) {
    return (
      <EventMsgBar
        type="error"
        message={EVENT_MESSAGE.NOT_FOUND}
        onClick={onClick}
      />
    );
  }
  if (resultWithRealm !== '' && resultStatus === EVENT_RESULT_TYPE.SUCCESS) {
    return (
      <EventMsgBar
        type="success"
        message={EVENT_MESSAGE.REGISTERED_WITH_REAML}
        reamls={1}
        onClick={onClick}
        // txnHash="0x24b79999990x24b79e58"
      />
    );
  }

  if (statusRegistration === EVENT_REGISTRATION_STATUS.IN_PROCESS) {
    return (
      <EventBar
        title="Registration closes in"
        subtitle={
          <EventCountDown
            hours={hours}
            minutes={minutes}
            days={days}
            type={countDownType}
          />
        }
      />
    );
  }

  return (
    <EventBar
      className="event-bar-not-started"
      title="Registration:"
      subtitle={
        <>
          <span className="date">20 Jun</span> 10:00 -{' '}
          <span className="date">22 Jun</span> 10:00 UTC
        </>
      }
    />
  );
};

export default EventBarWrapper;
