import { env } from '../utils/envHelpers';

const callAPI = async (method, endpoint, body = null) => {
  const options = {
    method,
    headers: { 'Content-type': 'application/json' },
    credentials: 'include',
    ...(method === 'POST'
      ? {
          body: JSON.stringify(body),
        }
      : {}),
  };

  try {
    const res = await fetch(`${env.apiUrl}${endpoint}`, options);
    const el = await res.json();
    return el;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getEligibility = () => callAPI('GET', '/eligibility');

export const submitLicense = (sig) =>
  callAPI('POST', '/license', { signature: sig, project: 'mocaverse' });

export const postMint = (currency_address, quantity, signature) =>
  callAPI('POST', '/mint', {
    currency_address,
    quantity,
    signature,
  });

export const fetchSurveyItems = () => callAPI('GET', '/survey');

export const fetchLeaderBoard = (param) =>
  callAPI(
    'GET',
    `/leaderboard?search=${param.search}&page=${param.page}&offset=${param.offset}&tribe=${param.tribe}&ordering=${param.ordering}`
  );

// get Wallet Is Signed
export const fetchWalletIsSigned = (data) =>
  callAPI('POST', `/check-sign`, data);

// get Wallet Is Signed
export const postWalletSign = (data) => callAPI('POST', `/verify-sign`, data);

// get RTM Leaderboard
export const fetchRtmLeaderboard = (param) =>
  callAPI(
    'GET',
    `/rtm-leaderboard?search=${param.search}&page=${param.page}&offset=${param.offset}&ordering=${param.ordering}`
  );
// get moca
export const fetchMocaEvent = (param) =>
  callAPI(
    'GET',
    `/moca?event_id=${param.event_id}&wallet_address=${param.wallet_address}`
  );

// GET /xp-history/1002?season=1&week=1
export const fetchMocaXp = (param) =>
  callAPI(
    'GET',
    `/xp-history/${param.id}?season=${param.season}&ordering=date`
  );

// To get the xp breakdown
export const fetchMocaXpDetail = (param) =>
  callAPI(
    'GET',
    `/xp-details/${param.id}?season=${param.season}&week=${param.week}&xp_sources=${param.xp_sources}`
  );

// get mymoca
export const fetchMyMoca = (param) =>
  callAPI(
    'GET',
    `/my-moca?wallet_address=${param.wallet_address}&page=${param.page}&offset=${param.offset}&tribe=${param.tribe}&xp_sort=${param.ordering}`
  );
// get xp-source name
export const fetchXpSource = () => callAPI('GET', `/xp-source`);

// POST /staked-moca to check if the moca is staked
export const checkStakedMoca = async (data) =>
  callAPI('POST', '/staked-moca', data);

/* fetch delegated wallet */
export const fetchDelegated = (param) =>
  callAPI('GET', `/delegate-wallets?wallet_address=${param.wallet_address}`);
/* end delegated wallet */

export const fetchEventRegistration = (param) =>
  callAPI(
    'GET',
    `/event-registration?event_id=${param.event_id}&wallet=${param.wallet_address}&delegate_wallet=${param.delegate_wallet}`
  );
export const submitEventRegistration = async (data) =>
  callAPI('POST', '/event-registration', data);

export const fetchRealmBalance = (param) =>
  callAPI('GET', `/realm-balance?wallet_address=${param.wallet_address}`);

export const submitSurvey = (data) =>
  callAPI('POST', '/survey', { option_ids: data });

export const checkQuotas = () => callAPI('GET', '/quotas');

export const getSupplies = async () => callAPI('GET', '/supplies');

export const getRealms = async () => callAPI('GET', '/realms');

export const submitRealms = async (realm_id, token_id) =>
  callAPI('POST', '/realms', {
    realm_id,
    token_id,
  });
//  check the moca’s eligiblity
export const fetchQualifiedMoca = async (moca_ids, event_id) =>
  callAPI('POST', '/is-qualified ', {
    moca_ids,
    event_id,
  });
export const submitGettingUpdates = async (address, email) => {
  const data =
    address && email
      ? {
          address,
          email,
        }
      : {
          email,
        };
  return callAPI('POST', '/email', data);
};
