import { useMemo } from 'react';
// import { observer } from 'mobx-react';
// import { Items, Item, Btn, Powered, TabWrapper } from './Styled';

const Tabs = (props) => {
  const { selectedTab, handleSelectedTab, items } = props;
  const itemsEl = useMemo(() => {
    if (!items) return <></>;
    const data = [];
    items.map((item) => {
      const cls = selectedTab === item.key ? 'active' : '';
      data.push(
        <li
          key={`my-account-tab-item-${item.key}`}
          onClick={() => handleSelectedTab(item.key)}
          className={cls}
          disabled={item.disabled ?? false}
        >
          {item.text}
        </li>
      );
    });
    return data;
  }, [selectedTab]);

  return (
    <div className="nav-tabs">
      <ul>{itemsEl}</ul>
    </div>
  );
};

export default Tabs;
