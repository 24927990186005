/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

const MintingProgress = createContext(null);

export function MintingProgressProvider({ children }) {
  const [progress, setProgress] = useState({
    // Step for this state = pages completed
    step: 0,
  });

  return (
    <MintingProgress.Provider value={{ progress, setProgress }}>
      {children}
    </MintingProgress.Provider>
  );
}

export function useMintingProgress() {
  const context = useContext(MintingProgress);
  if (context === undefined) {
    throw new Error(
      'useMintingProgress must be used within a MintingProgressProvider'
    );
  }
  return context;
}
