import React from 'react';
import { HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';

const EligibleTooltip = () => (
  <Tooltip
    className="moca-event-tooltip bottom-tooltip staking-tooltip"
    title={<HelpIcon />}
    content={
      <>
        <h2>How to Stake</h2>
        <p>Hold this Moca for a full staking period</p>
      </>
    }
  />
);

export default EligibleTooltip;
