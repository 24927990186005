import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';

const FaqTitleAnimation = () => {
  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    import('../../assets/lottie/FAQ.json').then(setAnimationData);
  }, []);

  if (!animationData) return <div className="faq-title" />;
  return (
    <Lottie
      animationData={animationData}
      background="transparent"
      play
      speed={1}
      loop={false}
      className="faq-title"
    />
  );
};

export default React.memo(FaqTitleAnimation);
