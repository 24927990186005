import React from 'react';

const EventRequirement = () => (
  <div className="event-section event-requirement">
    <h2 className="event-section-title">Requirement</h2>
    <div className="event-section-content dustland-section">
      <p>
        You must meet <strong>at least 1</strong> of the follow requirement:
      </p>
      <ul>
        <li>Own a Moca that is staked during 12 Jun - 19 Jun.</li>
        <li>Own a Realm Ticket</li>
      </ul>
    </div>
  </div>
);

export default EventRequirement;
