import React, { useState } from 'react';
import { xpHistoryFilter } from '../../utils/constants';

const FilterHistory = ({ setTribe, setShowFilter }) => {
  const [selectedTribes, setSelectedTribes] = useState([]);

  const handleTribeChange = (id) => {
    const index = selectedTribes.indexOf(id);
    if (index === -1) {
      setSelectedTribes([...selectedTribes, id]);
    } else {
      const newSelectedTribes = [...selectedTribes];
      newSelectedTribes.splice(index, 1);
      setSelectedTribes(newSelectedTribes);
    }
  };

  const handleConfirm = () => {
    setTribe(selectedTribes);
    setShowFilter(false);
  };
  const handleReset = () => {
    setTribe([]);
    setSelectedTribes([]);
    setShowFilter(false);
  };
  const handleClose = () => {
    setShowFilter(false);
  };
  return (
    <div className="filter-wrapper">
      <h2>
        <span className="text-filter">Filter</span>
        <button className="text-reset" onClick={handleReset}>
          Reset All
        </button>
      </h2>
      <div className="sub-filter-section">
        <div>
          <h3>XP From</h3>
          <ul>
            {xpHistoryFilter.map((tribe) => (
              <li key={tribe.id}>
                <label className="custom-input" htmlFor="xp-from">
                  {tribe.name}
                  <input
                    type="checkbox"
                    name="xp-from"
                    value={tribe.id}
                    checked={selectedTribes.includes(tribe.id)}
                    onChange={() => handleTribeChange(tribe.id)}
                  />
                  <span className="checkmark" />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="group-btn">
        <button className="btn-apply-filter btn-cancel" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-apply-filter" onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default FilterHistory;
