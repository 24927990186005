import React from 'react';
import { HelpIconWhite } from '../common/Icons';
import Tooltip from '../common/Tooltip';

const XPTooltip = () => (
  <Tooltip
    className="moca-event-tooltip bottom-tooltip xp-tooltip"
    title={<HelpIconWhite />}
    content={
      <div className="moca-XP-tooltip">
        <h2>3 Ways to Gain XP</h2>
        <ul>
          <li>
            <p className="title">1. Staking</p>
            <p className="des">
              <span className="des-title"> Total XP:</span>
              <span>
                {' '}
                Base XP + ( Rare Boost or Whale Boost ) + Tribe Boost
              </span>
            </p>
          </li>
          <li>
            <p className="title">2. Participate in Experience</p>
          </li>
          <li>
            <p className="title">3. Participate in Social Media Campaign</p>
          </li>
        </ul>
      </div>
    }
  />
);

export default XPTooltip;
