import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';

const InvestRealmTextAnimation = () => {
  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    import('../../assets/lottie/BuildRealmText.json').then(setAnimationData);
  }, []);

  if (!animationData) return <div className="realm" />;
  return (
    <Lottie
      animationData={animationData}
      background="transparent"
      play
      speed={1}
      loop={false}
      className="text invest build"
    />
  );
};

export default React.memo(InvestRealmTextAnimation);
