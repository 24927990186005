import React from 'react';
import { useEventTimeCountDown } from '../../hooks/useCountDown';
import { EVENT_TIME_STATUS } from '../../utils/eventHelpers';
import { TimeRemindIcon } from '../common/Icons';
import { env } from '../../utils/envHelpers';

const EventTitle = ({ className }) => {
  const { statusEvent } = useEventTimeCountDown(
    env.eventTimeStartedDate || '20 Apr, 2023 00:00:00 UTC',
    env.eventTimeEndedDate || '27 Apr, 2023 00:00:00 UTC'
  );
  // console.log(1, statusEvent);
  return (
    <div className={`event-title-wrapper ${className ?? ''}`}>
      <h1 className="event-title">Into the Mocaverse with Gamee</h1>
      <div className="event-title-notify">
        <p className="event-title-date">
          <TimeRemindIcon className="event-title-icon" />
          June 23 13:00 - June 30 13:00 (UTC)
          {/* {startedDate}- {endedDate} (UTC) */}
        </p>
        <p className="event-title-status">
          {statusEvent === EVENT_TIME_STATUS.NOT_STARTED && (
            <span>Not Started</span>
          )}
          {statusEvent === EVENT_TIME_STATUS.LIVE && (
            <span className="live">Live</span>
          )}
          {statusEvent === EVENT_TIME_STATUS.ENDED && (
            <span className="ended">Ended</span>
          )}
        </p>
        <p className="event-title-type">
          <span className="partnership">Partnership</span>
          {/* <span className="odyssey">Odyssey</span> */}
        </p>
      </div>
    </div>
  );
};

export default EventTitle;
