import React, { useState, useEffect } from 'react';
import CampaignJoinDiscord from './CampaignJoinDiscord';
import EventSidebarWrapper from './EventSidebarWrapper';
import EventBarWrapper from './EventBarWrapper';
import Modal from '../Modal';
import { env } from '../../utils/envHelpers';
import { EVENT_TIME_STATUS } from '../../utils/eventHelpers';

const EventSidebarCampaign = ({ address, data, statusEvent, heightIframe }) => {
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isConnect, setIsConnect] = useState(false);

  const eventId = new URLSearchParams(window.location.search).get('id');

  const handleRegisterMobile = () => {
    setIsConnect(true);
    if (!address) {
      document.querySelector('.connect.btn').click();
      return;
    }
    document.querySelector('.main-content').classList.add('disabled');
    setShowPopup(true);
  };

  const handleCloseModal = () => {
    setIsConnect(false);
    document.querySelector('.main-content').classList.remove('disabled');
    setShowPopup(false);
  };

  useEffect(() => {
    if (isConnect && address) {
      document.querySelector('.main-content').classList.add('disabled');
      setShowPopup(true);
    }
    if (statusEvent === EVENT_TIME_STATUS.ENDED) {
      document.querySelector('.main-content  .right')?.classList.add('active');
    }
  }, [address, isConnect, statusEvent, heightIframe]);

  const renderContent = () => {
    const cls = showPopup ? 'show-popup' : '';

    return (
      <EventSidebarWrapper className={`event-sb-mobile ${cls}`}>
        <EventSidebarWrapper
          className={`event-sb-modal ${cls}`}
          handleCloseBtn={handleCloseModal}
        >
          <EventBarWrapper
            data={data}
            statusEvent={statusEvent}
            countDownType="fixed"
          />
          <div className="modal-iframe">
            {window.screen.width <= 768 && (
              <iframe
                id="iframe"
                src={`${env.w3wIframeLink}${eventId}`}
                frameBorder="0"
                style={{
                  width: '100%',
                  height: `${heightIframe || 500}px`,
                  border: 'none',
                }}
                title="Challenge"
                className="active"
              />
            )}
          </div>
        </EventSidebarWrapper>
        <EventBarWrapper data={data} statusEvent={statusEvent} />

        <CampaignJoinDiscord
          statusEvent={statusEvent}
          handleRegister={handleRegisterMobile}
        />
      </EventSidebarWrapper>
    );
  };

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      {statusEvent !== EVENT_TIME_STATUS.ENDED && renderContent()}
    </>
  );
};

export default EventSidebarCampaign;
