import React, { useState } from 'react';
import { tribesFilter } from '../../utils/constants';

const Filter = ({ setTribe, setShowFilter }) => {
  const [selectedTribes, setSelectedTribes] = useState([]);

  const handleTribeChange = (id) => {
    const index = selectedTribes.indexOf(id);
    if (index === -1) {
      setSelectedTribes([...selectedTribes, id]);
    } else {
      const newSelectedTribes = [...selectedTribes];
      newSelectedTribes.splice(index, 1);
      setSelectedTribes(newSelectedTribes);
    }
  };

  const handleConfirm = () => {
    setTribe(selectedTribes.toString());
    setShowFilter(false);
  };
  const handleReset = () => {
    setTribe('');
    setSelectedTribes([]);
    setShowFilter(false);
  };
  const handleClose = () => {
    setShowFilter(false);
  };
  return (
    <div className="filter-wrapper">
      <h2>
        <span className="text-filter">Filter</span>
        <button className="text-reset" onClick={handleReset}>
          Reset All
        </button>
      </h2>
      <div className="sub-filter-section">
        <div>
          <h3>Tribe</h3>
          <ul>
            {tribesFilter.map((tribe) => (
              <li key={tribe.id}>
                <label className="custom-input" htmlFor="tribes">
                  {tribe.name}
                  <input
                    type="checkbox"
                    name="tribes"
                    value={tribe.id}
                    checked={selectedTribes.includes(tribe.id)}
                    onChange={() => handleTribeChange(tribe.id)}
                  />
                  <span className="checkmark" />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="group-btn">
        <button className="btn-apply-filter btn-cancel" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn-apply-filter" onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};
export default Filter;
