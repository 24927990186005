import React from 'react';
// import { formatDotDot } from '../../utils/textHelper';
import { EVENT_RESULT_TYPE } from '../../utils/eventHelpers';
import { ErrorMsgIcon, SuccessMsgIcon } from '../common/Icons';
import TicketMessageImg from '../../assets/images/mocana/ticket-msg.png';

// const EventMsgBar = ({ type, message, reamls = 0, txnHash = '' }) => (
const EventMsgBar = ({ type, message, reamls = 0, onClick }) => (
  <div
    onClick={onClick}
    className={`event-bar-wrapper event-msg-wrapper ${
      reamls !== 0 ? 'event-msg-with-realm' : ''
    } ${type}`}
  >
    {type === EVENT_RESULT_TYPE.ERROR && (
      <ErrorMsgIcon className="event-msg-icon" aria-label="warning-icon" />
    )}
    {type === EVENT_RESULT_TYPE.SUCCESS && (
      <SuccessMsgIcon className="event-msg-icon" aria-label="success-icon" />
    )}
    <div className="event-msg">
      {message}
      {reamls !== 0 && (
        <span className="event-msg-realm">
          <img src={TicketMessageImg} alt="Realm" /> x{reamls}
        </span>
      )}{' '}
      {/* <br />
      {txnHash && (
        <a href="/event" title={txnHash} target="_blank">
          Txn Hash: {formatDotDot(txnHash, '...', 6, 4)}
        </a>
      )} */}
    </div>
  </div>
);

export default EventMsgBar;
