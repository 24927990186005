import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import invalidImage from '../../assets/images/invalid-image.svg';
import iconSearch from '../../assets/images/mocana/icon-search.svg';
import noMoca from '../../assets/images/no_moca_found.svg';
import filter from '../../assets/images/symbol-filter.svg';
import sort from '../../assets/images/symbol-sort.svg';
import marketplace from '../../assets/images/mocana/moca_marketplace.svg';
import Loading from '../mocana/Loading';
import Filter from './Filter';
import Sort from './Sort';
import StakingTooltip from './StakingTooltip';
import TableMyMoca from './TableMyMoca';
import Tabs from './Tabs';
import TimeMyMoca from './TimeMyMoca';
import { marketplaceHeroBannerLink } from '../../utils/constants';

import {
  checkStakedMoca,
  fetchMyMoca,
  fetchXpSource,
} from '../../api/fetchData';
import { handleListId } from '../../utils/eventHelpers';
import iconRemoveS from '../../assets/images/mocana/icon-remove-s.svg';
import { useDebounce } from '../../hooks/useDebounce';
import { setTotal } from '../../redux/my-moca/myMocaSlice';

const myMocaTabs = [
  {
    key: 'MyMoca',
    text: <>All</>,
  },
  {
    key: 'MyRealmTickets',
    text: <>Staked</>,
  },
  {
    key: 'RegisteredExperiences',
    text: (
      <>
        Not Staked <StakingTooltip />
      </>
    ),
  },
];

const MyMoca = ({ address }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(myMocaTabs[0].key);

  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tribe, setTribe] = useState('');
  const [xpSource, setXpSource] = useState({});

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchTerm = useDebounce(searchInput, 400);
  const [ordering, setOrdering] = useState('asc');
  const [initData, setInitData] = useState([]);
  const [data, setData] = useState();
  //   const [isHovering, setIsHovering] = useState(false);

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const toggleFilter = () => setShowFilter(!showFilter);
  const toggleSort = () => setShowSort(!showSort);
  const handleRemoveSearch = () => {
    setSearchInput('');
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const results = await fetchMyMoca({
        wallet_address: address,
        tribe,
        ordering,
      });
      if (results) {
        dispatch(setTotal(results.total));
        setData(results.results);
        if (results.results.length !== 0) {
          const stakedResults = await checkStakedMoca({
            moca_ids: handleListId(results.results),
          });
          if (stakedResults) {
            const stakedList = results.results.map((t1) => ({
              ...t1,
              ...stakedResults.results.find((t2) => t2.moca_id === t1.moca_id),
            }));
            setData(stakedList);
            setInitData(stakedList);
          }
        }
      }
      const resultsSource = await fetchXpSource();
      if (resultsSource) {
        const xpList = resultsSource?.results.reduce((obj, item) => {
          obj[item.id] = item.source;
          return obj;
        }, {});
        setXpSource(xpList);
      }
      setLoading(false);
    };
    fetchData();
  }, [tribe, ordering, address]);

  useEffect(() => {
    const results = initData.filter((item) =>
      item.moca_name.includes(debouncedSearchTerm)
    );
    setData(results);
  }, [debouncedSearchTerm, initData]);

  const handleSelectedTab = (value) => {
    setSelectedTab(value);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ListMoca = ({ dataList }) => {
    if (loading) return <Loading />;
    if (!dataList || (dataList?.length === 0 && searchInput.length !== 0))
      return (
        <div className="invalid-moca">
          <img src={invalidImage} alt="" />
          <p className="invalid-title">Invalid Moca Name</p>
          <p className="invalid-sub-title">Please try again.</p>
        </div>
      );
    if (dataList && dataList.length === 0)
      return (
        <div className="invalid-moca">
          <img src={noMoca} alt="" />
          <p className="invalid-title">No Moca Found in Wallet</p>
          <p className="invalid-sub-title">
            Get a Moca to register in upcoming experiences
          </p>
          <a
            href={marketplaceHeroBannerLink}
            className="socials"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn-get-moca">
              <img src={marketplace} alt="" />
              <span>Get Moca</span>
            </button>
          </a>
        </div>
      );
    return (
      <div className="list-moca">
        <TableMyMoca data={dataList} xpSource={xpSource} />
      </div>
    );
  };
  return (
    <div className="moca-wrapper">
      <div className="moca-content">
        <div className="moca-content-header-wrapper">
          <div className="moca-search-filter">
            <div className="moca-search">
              <img src={iconSearch} alt="icon-search" />
              <input
                type="text"
                name="search"
                placeholder="Search by Moca name"
                onChange={handleInputChange}
                value={searchInput}
              />
              {searchInput.length !== 0 && (
                <button onClick={handleRemoveSearch}>
                  <img src={iconRemoveS} alt="icon-r-search" />
                </button>
              )}
            </div>
            <div className="moca-filter">
              <button onClick={toggleFilter}>
                <img src={filter} alt="filter" />
              </button>
              <div className={`mocana-list-filter ${showFilter && 'active'}`}>
                <Filter setTribe={setTribe} setShowFilter={setShowFilter} />
              </div>
            </div>
            <div className="moca-sort">
              <button onClick={toggleSort}>
                <img src={sort} alt="" />
              </button>
              <div className={`mocana-list-sort ${showSort && 'active'}`}>
                <Sort setShowSort={setShowSort} setValueSort={setOrdering} />
              </div>
            </div>
          </div>
          <div className="moca-content-header">
            <Tabs
              items={myMocaTabs}
              selectedTab={selectedTab}
              handleSelectedTab={handleSelectedTab}
            />
          </div>
        </div>
        <div className="staking-period">
          <p className="staking-period-title">Staking Period Ends In:</p>
          <TimeMyMoca />
        </div>
        <p className="staking-disclaimer">
          *Staking status is updated at the end of each staking period.
        </p>
        <div
          className={`${
            selectedTab === myMocaTabs[0].key ? 'selected' : ''
          } tab-item`}
        >
          <ListMoca dataList={data} />
        </div>
        <div
          className={`${
            selectedTab === myMocaTabs[1].key ? 'selected' : ''
          } tab-item`}
        >
          <ListMoca
            dataList={
              data && data.length !== 0 ? data.filter((i) => i?.is_staked) : []
            }
          />
        </div>
        <div
          className={`${
            selectedTab === myMocaTabs[2].key ? 'selected' : ''
          } tab-item`}
        >
          <ListMoca
            dataList={
              data && data.length !== 0
                ? data.filter((i) => i?.is_staked === false)
                : []
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MyMoca;
