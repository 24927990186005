import React, { useState, useEffect } from 'react';
import CampaignHeader from './CampaignHeader';
import CampaignContent from './CampaignContent';
import CampaignAPI from '../../api/campaign';

const categoryTabs = [
  {
    key: 'All',
    text: <>All</>,
  },
  {
    key: 'Odyssey',
    text: <>Odyssey</>,
    disabled: true,
  },
  {
    key: 'Giveaway',
    text: <>Giveaway</>,
  },
];

const tabs = [
  {
    key: 'Live',
    text: <>Live</>,
  },
  {
    key: 'Past',
    text: <>Past</>,
  },
];

const CampaignSection = () => {
  const [selectedCategoryTab, setSelectedCategoryTab] = useState(
    categoryTabs[0].key
  );
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const [isFetching, setIsFetching] = useState(false);
  const [campaignData, setCampaignData] = useState([]);

  const handleSelectedCategoryTab = (value) => {
    setSelectedCategoryTab(value);
  };
  const handleSelectedTab = (value) => {
    setSelectedTab(value);
  };

  const fetchCampaignList = async () => {
    setIsFetching(true);
    try {
      const { data } = await CampaignAPI.getCampaignList();
      setCampaignData(data.campaigns.data);
    } catch (err) {
      console.log('@err', err);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchCampaignList();
  }, []);

  return (
    <section className="campaign-wrapper">
      <div className="campaign-header-bg">
        <CampaignHeader
          categoryTabs={categoryTabs}
          selectedCategoryTab={selectedCategoryTab}
          handleSelectedCategoryTab={handleSelectedCategoryTab}
          tabs={tabs}
          selectedTab={selectedTab}
          handleSelectedTab={handleSelectedTab}
        />
      </div>
      <CampaignContent
        isFetching={isFetching}
        campaignData={campaignData}
        tabs={tabs}
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
      />
    </section>
  );
};

export default CampaignSection;
