import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Tabs from './Tabs';
import CampaignList from './CampaignList';
import Loading from './Loading';

const CampaignContent = ({
  isFetching,
  campaignData,
  tabs,
  selectedTab,
  handleSelectedTab,
}) => {
  const [liveCampaignData, setLiveCampaignData] = useState([]);
  const [pastCampaignData, setPastCampaignData] = useState([]);

  const checkIsLive = (arr, displayLive) =>
    arr.filter((item) => {
      const now = moment(new Date());
      const { start_date, end_date } = item;

      const isLive = moment({
        hour: now.hour(),
        minute: now.minute(),
        second: now.second(),
      }).isBetween(start_date, end_date);

      return isLive === displayLive;
    });

  useEffect(() => {
    const liveData = checkIsLive(campaignData, true);
    const pastData = checkIsLive(campaignData, false);

    setLiveCampaignData(liveData);
    setPastCampaignData(pastData);
  }, [campaignData]);

  return (
    <section className="campaign-content-wrapper">
      <Tabs
        className="main-tabs-campaign main-tabs-campaign-mobile"
        items={tabs}
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
      />
      <div
        className={`tab-content ${
          selectedTab === tabs[0].key ? 'selected' : ''
        }`}
      >
        {isFetching ? (
          <Loading />
        ) : (
          <CampaignList campaignData={liveCampaignData} isLive />
        )}
      </div>
      <div
        className={`tab-content ${
          selectedTab === tabs[1].key ? 'selected' : ''
        }`}
      >
        {isFetching ? (
          <Loading />
        ) : (
          <CampaignList campaignData={pastCampaignData} isLive={false} />
        )}
      </div>
    </section>
  );
};

export default CampaignContent;
