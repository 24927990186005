import React, { useEffect } from 'react';
import { EventWrapper } from '../components/event';

const Event = () => {
  useEffect(() => {
    document.body.classList = 'mocana-page event-page';
  });

  return <EventWrapper />;
};

export default Event;
