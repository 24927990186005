import React from 'react';
import { EVENT_REGISTRATION_STATUS } from '../../utils/eventHelpers';
import { WalletIcon } from '../common/Icons';
import HotWalletInfo from '../HotWalletInfo';

const EventConnectWallet = ({ status, handleClick }) => (
  <>
    <div className="event-sb-header">
      <h2 className="event-sb-title">Select a Moca to be your Agent</h2>
      {status === EVENT_REGISTRATION_STATUS.IN_PROCESS && (
        <p className="event-sb-subtitle">or use Realm Ticket to Register</p>
      )}
    </div>
    <div className="event-sb-main event-connect-wallet">
      <WalletIcon className="wallet-icon" aria-label="wallet-icon" />
      <p>Connect Wallet to Continue</p>
      <button
        className="btn btn-md-second btn-connect"
        onClick={handleClick}
        aria-label="Connect Wallet"
      >
        Connect Wallet
      </button>
      <HotWalletInfo />
    </div>
  </>
);

export default EventConnectWallet;
