import React, { useState } from 'react';
import { useAddress, useDisconnect, useLogout } from '@thirdweb-dev/react';
import { useScroll } from 'framer-motion';
import DesktopNav from './header/DesktopNav';
import MobileNav from './header/MobileNav';
import ModalGetUpdates from './ModalGetUpdates';

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const address = useAddress();

  const disconnect = useDisconnect();
  const { logout } = useLogout();

  const [showDesktopNav, setShowDesktopNav] = useState({
    display: true,
    y: 88,
  });
  const { scrollY } = useScroll({});

  scrollY.onChange((latest) => {
    if (latest <= showDesktopNav.y) {
      setShowDesktopNav({ display: true, y: 88 });
    } else {
      setShowDesktopNav({ display: false, y: 88 });
    }
  });

  const handleShowDesktopNav = () => {
    setShowDesktopNav({ display: true, y: scrollY.latest });
  };

  const handleGetUpdate = () => {
    setShowModal(true);
  };

  const handleDisconnect = async () => {
    await disconnect().catch(() => {
      // further state handling re UI
      console.log('handle disconnect errors');
    });
    await logout()
      .catch((err) => {
        console.log('logout error', err);
        // further state handling re UI
      })
      .finally(() => {
        window.location.reload();
      });
  };

  return (
    <header>
      {showModal && <ModalGetUpdates toggleModal={() => setShowModal(false)} />}
      <DesktopNav
        showDesktopNav={showDesktopNav}
        address={address}
        handleGetUpdate={handleGetUpdate}
        handleDisconnect={handleDisconnect}
      />
      <div className="menu-shrink-desktop" onClick={handleShowDesktopNav} />
      <MobileNav
        address={address}
        handleGetUpdate={handleGetUpdate}
        handleDisconnect={handleDisconnect}
      />
    </header>
  );
};

export default React.memo(Header);
