import React from 'react';
import { DoGoodAnimation, DoGoodTextAnimation } from './index';

// const DoGoodAnimation = React.lazy(() => import('./DoGoodRealm'));
// const DoGoodTextAnimation = React.lazy(() => import('./DoGoodRealmText'));

const text =
  'Contribute to society and protect our surrounding environments for a better future that benefits everyone';

const DoGoodRealmItem = ({ isSlider = false }) => {
  if (isSlider) {
    return (
      <div className="realms-slider-item">
        <DoGoodAnimation />
        <div className="realm-info dogood">
          <DoGoodTextAnimation />
          {text}
        </div>
      </div>
    );
  }

  return (
    <div className="realm__dogood">
      <div className="realm__content">
        <DoGoodTextAnimation />
        <DoGoodAnimation />
        <div className="realm__info">{text}</div>
      </div>
    </div>
  );
};

export default DoGoodRealmItem;
