import React from 'react';
import EventRewardItem from './EventRewardItem';

/**
 *
 * @param string classNamePrefix
 * @param object[] items: [{key: string, img: string (url of image), name: string, subtitle: string}]
 * @param boolean center = false
 * @returns
 */
const EventRewardList = ({ classNamePrefix, items, center }) => (
  <ul className={`${classNamePrefix}-list ${center ? 'center' : ''}`}>
    {items.map((item) => (
      <EventRewardItem
        classNamePrefix={classNamePrefix}
        key={item?.key}
        name={item?.name || ''}
        img={item?.img || ''}
        subtitle={item?.subtitle || ''}
      />
    ))}
  </ul>
);

export default EventRewardList;
