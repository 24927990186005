import React, { useEffect, useState } from 'react';
import { env } from '../../../utils/envHelpers';

import tribeIcon from '../../../assets/images/tribe-large.svg';
import rank1_angel from '../../../assets/images/rank1_angel.svg';
import rank1_connector from '../../../assets/images/rank1_connector.svg';
import rank1_builder from '../../../assets/images/rank1_builder.svg';
import rank1_dreamer from '../../../assets/images/rank1_dreamer.svg';

import rank1_neo from '../../../assets/images/rank1_neo.svg';
import rankn_connector from '../../../assets/images/rankn_connector.svg';
import rankn_neo from '../../../assets/images/rankn_neo.svg';

import rankn_builder from '../../../assets/images/rankn_builder.svg';

import rankn_dreamer from '../../../assets/images/rankn_dreamer.svg';

import rankn_angel from '../../../assets/images/rankn_angel.svg';
// import iData from '../../../assets/dustlandJson/tribe_ranking.json';

const tribeIcons1 = {
  angel: rank1_angel,
  connector: rank1_connector,
  builder: rank1_builder,
  dreamer: rank1_dreamer,
  'neo-capitalist': rank1_neo,
};

const tribeIconsN = {
  angel: rankn_angel,
  connector: rankn_connector,
  builder: rankn_builder,
  dreamer: rankn_dreamer,
  'neo-capitalist': rankn_neo,
};

const TribeLeaderboard = () => {
  const [initData, setInitData] = useState();
  const [hasSameRank, setHasSameRank] = useState(false);

  const checkSameRank = (arr) => {
    const ranks = arr.map((item) => item.rank);
    const uniqueRanks = [...new Set(ranks)];
    return ranks.length !== uniqueRanks.length;
  };

  useEffect(() => {
    fetch(env.dustlandTribeJson)
      .then((response) => response.json())
      .then((values) => {
        setInitData(values);
        setHasSameRank(checkSameRank(values));
      })
      .catch((error) => {
        console.error('Error fetching leaderboard data:', error);
      });
    // setInitData(iData);
    // setHasSameRank(checkSameRank(iData));
  }, []);

  if (!initData) {
    return <></>;
  }

  return (
    <section className="moca-table moca-leaderboard">
      <div className="moca-title">
        <img src={tribeIcon} alt="" /> 3. Tribe Leaderboard
      </div>
      <div className="tribe-content">
        <p className="tribe-title">
          Tribe score is contributed by total distance of{' '}
          <span className="text-large">Top 5 Players</span> from each tribe.
        </p>
        {!hasSameRank ? (
          <div className="tribe-rank-list">
            <div className="tribe-rank-1">
              <p className="rank-number">{initData[0].rank}</p>
              <p className="rank-point">
                {initData[0].total_distance.toLocaleString()}
              </p>
              <div className="rank-image">
                <img
                  src={tribeIcons1[initData[0].tribe]}
                  alt="icon"
                  className={initData[0].tribe}
                />
              </div>
              <p className={`rank-tags ${initData[0].tribe}`}>
                {initData[0].tribe}
              </p>
            </div>
            <div className="tribe-ranks">
              {initData
                .filter((i) => i.rank > 1)
                .map((data) => (
                  <div
                    className={`tribe-rank tribe-rank-${data.rank}`}
                    key={data.rank}
                  >
                    <p className="rank-number">{data.rank}</p>
                    <p className="rank-point">
                      {data.total_distance.toLocaleString()}
                    </p>
                    <div className="rank-image">
                      <img src={tribeIconsN[data.tribe]} alt="" />
                    </div>
                    <p className={`rank-tags ${data.tribe}`}>{data.tribe}</p>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="tribe-rank-list">
            <div className="tribe-ranks same-rank">
              {initData.map((data) => (
                <div
                  className={`tribe-rank tribe-rank-${data.rank}`}
                  key={data.rank}
                >
                  <p className="rank-number">{data.rank}</p>
                  <p className="rank-point">
                    {data.total_distance.toLocaleString()}
                  </p>
                  <div className="rank-image">
                    <img
                      className={data.tribe}
                      src={
                        data.rank === 1
                          ? tribeIcons1[data.tribe]
                          : tribeIconsN[data.tribe]
                      }
                      alt="icon"
                    />
                  </div>
                  <p className={`rank-tags ${data.tribe}`}>{data.tribe}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
export default TribeLeaderboard;
