import React, { useState } from 'react';
import AbLogo from '../../assets/images/ab_logo.png';
import ModalTerms from '../ModalTerms';
import ModalLicence from '../ModalLicence';

const FaqWrapper = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalLicence, setShowModalLicence] = useState(false);

  return (
    <>
      {showModal && <ModalTerms toggleModalTerms={() => setShowModal(false)} />}
      {showModalLicence && (
        <ModalLicence toggleModalLicence={() => setShowModalLicence(false)} />
      )}
      <section id="faq" className="faq-section">
        {children}
        <div className="legal">
          <div className="legal-icon">
            <img src={AbLogo} alt="Animoca Brands" />
          </div>
          <div className="legal-text">
            <button onClick={() => setShowModal(true)} className="btn-link">
              Terms of Use
            </button>{' '}
            and{' '}
            <button
              onClick={() => setShowModalLicence(true)}
              className="btn-link"
            >
              License Agreement
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(FaqWrapper);
