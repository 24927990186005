/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useAddress, useDisconnect, useLogout } from '@thirdweb-dev/react';
import { useScroll } from 'framer-motion';
import DesktopNav from './DesktopNavWrapper';
import MobileNav from './MobileNavWrapper';
// import LinksNav from './LinksNav';
import ModalGetUpdates from '../ModalGetUpdates';

const HeaderWrapper = ({ links, clsNameNav }) => {
  const [showModal, setShowModal] = useState(false);
  const address = useAddress();

  const disconnect = useDisconnect();
  const { logout } = useLogout();

  const [showDesktopNav, setShowDesktopNav] = useState({
    display: true,
    y: 88,
  });

  const { scrollY } = useScroll({});

  scrollY.onChange((latest) => {
    if (latest <= showDesktopNav.y) {
      setShowDesktopNav({ display: true, y: 88 });
    } else {
      setShowDesktopNav({ display: false, y: 88 });
    }
  });

  const handleShowDesktopNav = () => {
    setShowDesktopNav({ display: true, y: scrollY.latest });
  };

  const handleGetUpdate = () => {
    setShowModal(true);
  };

  const handleDisconnect = async () => {
    await disconnect().catch(() => {
      // further state handling re UI
      console.log('handle disconnect errors');
    });
    await logout()
      .catch((err) => {
        console.log('logout error', err);
        // further state handling re UI
      })
      .finally(() => {
        window.location.reload();
      });
  };

  return (
    <header>
      {showModal && <ModalGetUpdates toggleModal={() => setShowModal(false)} />}
      {window.screen.width >= 992 && (
        <DesktopNav
          links={links}
          className={clsNameNav}
          showDesktopNav={showDesktopNav}
          address={address}
          handleGetUpdate={handleGetUpdate}
          handleDisconnect={handleDisconnect}
        />
      )}
      <div className="menu-shrink-desktop" onClick={handleShowDesktopNav} />
      {window.screen.width < 992 && (
        <MobileNav
          links={links}
          className={clsNameNav}
          address={address}
          handleGetUpdate={handleGetUpdate}
          handleDisconnect={handleDisconnect}
        />
      )}
    </header>
  );
};

export default HeaderWrapper;
