import React from 'react';
import Loader from '../../assets/images/mocana/loading.gif';

const Loading = () => (
  <div className="moca-404 loading">
    <img className="loading-icon" src={Loader} alt="" />
  </div>
);

export default Loading;
