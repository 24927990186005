import React from 'react';
import EventRewardPool from './EventRewardPool';
import EventHowToRegister from './EventHowToRegister';
import EventRequirement from './EventRequirement';
import EventRewardDetail from './EventRewardDetail';

const Reward = () => (
  <div>
    <EventRewardPool />
    <EventRewardDetail />
    <EventRequirement />
    <EventHowToRegister />
  </div>
);
export default Reward;
