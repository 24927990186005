import React from 'react';
import EventRewardPool from './EventRewardPool';
import EventRewardDetail from './EventRewardDetail';

const Reward = () => (
  <div>
    <EventRewardPool />
    <EventRewardDetail />
  </div>
);
export default Reward;
