import React, { useMemo } from 'react';
import { EVENT_REGISTRATION_STATUS } from '../../utils/eventHelpers';

const EventRegisterMobile = ({
  status,
  handleRegister,
  address,
  handleConnect,
}) => {
  const rgBtn = useMemo(() => {
    if (!address) {
      return (
        <button
          className="btn btn-md-second btn-connect-wallet"
          onClick={handleConnect}
          aria-label="Connect Wallet"
        >
          Connect Wallet
        </button>
      );
    }
    if (status === EVENT_REGISTRATION_STATUS.IN_PROCESS) {
      return (
        <button
          className="btn btn-md-second btn-register-now"
          onClick={handleRegister}
          aria-label="Register Now"
        >
          Register Now
        </button>
      );
    }

    return (
      <>
        <button
          className="btn btn-md-second btn-register-now"
          disabled
          aria-label="Register Now"
        >
          Register Now
        </button>
        <p className="event-sb-action-note">
          Registration period has not started yet.
        </p>
      </>
    );
  }, [address, status, handleRegister]);

  return <div className="event-sb-actions">{rgBtn}</div>;
};

export default EventRegisterMobile;
