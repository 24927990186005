import React from 'react';
import ModalWrapper from './common/ModalWrapper';

const Terms = ({ toggleModalTerms }) => (
  <ModalWrapper className="terms-wappar" onClose={() => toggleModalTerms()}>
    <h2 className="title">Terms of Use</h2>
    <div className="modal-scroll">
      <h3>Last updated: December 8, 2022</h3>
      <p>
        These terms of use (these “<strong>Terms</strong>” or this “
        <strong>Agreement</strong>”) govern your access to and use of
        www.mocaverse.xyz (“<strong>Site</strong>”) and constitute a legally
        binding agreement between us and you, any entity you represent, or any
        individual accessing or using the Site (each a “<strong>User</strong>”,
        “<strong>you</strong>” or “<strong>your</strong>”). Each of Crown
        Education Limited (“
        <strong>Animoca Brands</strong>”, “<strong>we</strong>” or “
        <strong>us</strong>”) and you is a “<strong>Party</strong>” and
        collectively the “<strong>Parties</strong>”.{' '}
      </p>

      <h2>1. Agreement to these Terms</h2>
      <p>
        1.1 Please read these Terms carefully before using the Site. By
        accessing or using the Site in any manner, you:
      </p>
      <ul className="letters">
        <li>
          accept and agree to these Terms and any amendment to these Terms that
          may be made by us from time to time;{' '}
        </li>
        <li>
          consent to the collection, use, disclosure and other handling of
          information as set forth in our Privacy Policy, which can be accessed
          at https://policy.animocabrands.com/privacy-policy/ (the “
          <strong>Privacy Policy</strong>”), provided that for the purpose of
          these Terms, references to “Animoca Brands Limited” in the Privacy
          Policy shall refer to us; and{' '}
        </li>
        <li>
          accept and agree to any Anti-Money Laundering and Counter-Terrorist
          Financing and Know-Your-Client Policy (“
          <strong>AML/KYC Policy</strong>”) and any other mandatory policies
          notified to you from time to time (the Privacy Policy, AML/KYC Policy
          and any other mandatory policies are each a “<strong>Policy</strong>”)
          and any amendment thereto that may be made by us from time to time.
        </li>
      </ul>
      <p>
        1.2 IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS OR USE THE SITE.{' '}
      </p>
      <p>
        1.3 When you use the Site, you may also be using the services of one or
        more third parties, your use of any third-party services will be subject
        to the privacy policies, terms of use and similar policies and terms,
        and fees (if any) of the relevant third party service providers.
      </p>

      <h2>2. Eligibility to access or use the Site </h2>
      <p>
        By accessing or using the Site, you further represent and warrant to us
        that:
      </p>
      <ul className="letters">
        <li>
          You are at least 18 years old or have attained such age of age of
          majority and contractual capacity in your jurisdiction of residence;
        </li>
        <li>
          You have read carefully in full these Terms and our Policies (to the
          extent applicable) to which you consent; additionally, you also
          confirm that you have performed reasonable due diligence with regard
          to us, the Site, and that you have, if and to extent you deem
          necessary, sought independent financial and/or legal advice;
        </li>
        <li>
          If you are entering into this Agreement on behalf of any entity, you
          have the legal authority to do so and bind such entity to these Terms;
          and
        </li>
        <li>
          You, your entity or financial institution, or any party that owns or
          controls you, your entity or financial institutions, are (i) not
          subject to any embargo, sanctions or otherwise included on any list of
          designated prohibited or restricted parties, including but not limited
          to the lists maintained by the United Nations Security Council, the
          United States Government (i.e., the Specially Designated Nationals
          List, Foreign Sanctions Evaders List of the United States Department
          of Treasury, Entity List, Denied Persons List and Unverified List of
          the United States Department of Commerce), the United Kingdom
          Government (i.e., HM Treasury’s financial sanctions regime), the
          European Union or any of its Member States, or other applicable
          government authority and (ii) not located in any country subject to
          any comprehensive sanctions program implemented by the United States
          Government.
        </li>
      </ul>
      <p>
        You undertake to us that you will do all things reasonably necessary to
        ensure continuing compliance with this Section 2, such that each of the
        representations and warranties made shall continue to be true.
      </p>
      <p>
        If at any time Animoca Brands becomes aware that you misrepresented, or
        breached any warranty or undertaking, given in this Agreement, Animoca
        Brands may in its sole discretion deny you access to all or part of the
        Site.
      </p>

      <h2>3. Amendment to these Terms</h2>
      <p>
        We reserve the right to amend or modify these Terms or any Policy at our
        sole discretion at any time by publishing such amended or modified Terms
        on the Site. Any and all modifications or changes will become effective
        upon publication and you agree and acknowledge that we will not
        explicitly notify you about any possible amendments and modifications
        and it is your responsibility to check these Terms and any Policy
        periodically for changes. Your continued use of the Site following the
        publication of any changes to these Terms and any Policy will mean that
        you accept and agree to such changes.
      </p>

      <h2>4. Interpretation</h2>
      <p>
        4.1 The following terms shall have, for the purposes of this Agreement,
        the following meaning:
      </p>
      <ul className="no-style">
        <li>
          “<strong>Account</strong>” shall have the meaning as set out in
          Section 5.1 of these Terms;
        </li>
        <li>
          “<strong>Account Credentials</strong>” shall have the meaning as set
          out in Section 5.2 of these Terms;
        </li>
        <li>
          “<strong>AML</strong>” means Anti Money Laundering clause, which
          refers to the laws, regulations and procedures intended to prevent
          individuals from disguising illegally obtained funds as legitimate
          income;
        </li>
        <li>
          “<strong>AML/KYC Policy</strong>” shall have the meaning as set out in
          Section 1.1(c) of these Terms;
        </li>
        <li>
          “<strong>Claims</strong>” shall have the meaning as set out in Section
          12.1 of these Terms;
        </li>
        <li>
          “<strong>Confidential Information</strong>” means information,
          technical data or know-how, including, but not limited to, information
          relating to business and product or service plans, financial
          projections, customer lists, business forecasts, sales and
          merchandising, human resources, patents, patent applications, computer
          object or source code, research, inventions, processes, designs,
          drawings, engineering, marketing or finance to be confidential or
          proprietary or information which would, under the circumstances,
          appear to a reasonable person to be confidential or proprietary;
        </li>
        <li>
          “<strong>Content</strong>” shall have the meaning as set out in
          Section 8.1 of these Terms;{' '}
        </li>
        <li>
          “<strong>HKIAC</strong>” shall have the meaning as set out in Section
          13.4 of these Terms;
        </li>
        <li>
          “<strong>Indemnified Parties</strong>” shall have the meaning as set
          out in Section 12.1 of these Terms;
        </li>
        <li>
          “<strong>Intellectual Property Rights</strong>” mean any copyrights,
          designs, patents, rights to inventions, rights in confidential
          information, know-how, trade secrets, trademarks, trade names,
          database rights, chip topography rights, mask works, utility models,
          domain names, source codes, rights in designs, rights in computer
          software, rights in the websites or mobile applications and all
          similar rights of whatever nature and in whatever form and, in such
          case (a) whether registered or not, (b) including any applications to
          protect or register such rights, (c) including all renewals and
          extensions of such rights or applications, (d) whether vested,
          contingent or future and wherever existing;
        </li>
        <li>
          “<strong>KYC</strong>” means Know Your Customer standards, which are
          designed to protect including, but not limited to, financial
          institutions against fraud, corruption, money laundering and terrorist
          financing, as institutions have the obligations to verify customer’s
          identity;
        </li>
        <li>
          “<strong>KYC/AML Regulations</strong>’’ shall have the meaning as set
          out in Section 6.1 of these Terms;
        </li>
        <li>
          “<strong>NFT</strong>” means a unit data stored on blockchain and
          which is unique, non-interchangeable with any other non-fungible
          tokens and with blockchain-managed ownership;
        </li>
        <li>
          “<strong>Policy</strong>” shall have the meaning as set out in Section
          1.1(c) of these Terms;
        </li>
        <li>
          “<strong>Privacy Policy</strong>” shall have the meaning as set out in
          Section 1.1(b) of these Terms;
        </li>
        <li>
          “<strong>Site</strong>” shall have the meaning as set out in the first
          paragraph of these Terms;
        </li>
        <li>
          “<strong>Terms</strong>” or this “<strong>Agreement</strong>” shall
          have the meaning as set out in the second paragraph of these Terms;
          and
        </li>
        <li>
          “<strong>User</strong>”, “<strong>you</strong>” or “
          <strong>your</strong>” shall have the meaning as set out in the second
          paragraph of these Terms.
        </li>
      </ul>
      <p>
        4.2 Any capitalized terms or expressions not defined in this Section 4
        shall have the meanings otherwise ascribed to them in these Terms.
      </p>
      <p>
        4.3 Wherever a singular expression is used in these Terms, that
        expression is considered as including the plural or the body corporate
        where required by the context.
      </p>

      <h2>5. Registration of Account</h2>
      <p>
        5.1 <u>Registration of Account</u>. In order to enjoy full access to the
        Site, you may be asked to have or create an account or user profile (an
        “Account”). We may at our discretion refuse to allow anyone to establish
        an Account, or limit the number of the Accounts that a single User may
        establish and maintain at any time.
      </p>
      <p>
        5.2 <u>Account Security</u>.
      </p>
      <ul className="letters">
        <li>
          You are solely responsible for maintaining adequate security and
          control of any and all passwords, hints, personal identification
          numbers (PINs), application programming interface (API) keys,
          signatures or any other codes related to your Account or that you use
          to access the Site (collectively, your “
          <strong>Account Credentials</strong>”). Any loss or compromise of the
          Account Credentials and/or your personal information may result in
          unauthorized access to your Account by third parties and the loss or
          theft of any NFT(s) and/or funds held in your Account and any
          associated accounts, including your linked bank account(s) and credit
          card(s) (if applicable). By creating an Account, you agree that you
          will not disclose your Account Credentials to anyone and will notify
          us immediately of any unauthorized use of your Account. Neither shall
          Animoca Brands nor any of its affiliates be responsible for any
          unauthorized access to your Account, including any access that
          occurred as a result of fraud, phishing, or other criminal activity
          perpetrated by third parties.
        </li>
        <li>
          In the event that any log-in credentials or API authentication is
          generated by us, such log-in credentials and API authentication are
          for your use only and you agree that you will not sell, transfer or
          sublicense them to any third party, except that you may disclose your
          password or private key to your agents and subcontractors.{' '}
        </li>
      </ul>
      <p>
        5.3 <u>Account Activities</u>. You are responsible for all activities
        that occur under your Account, regardless of whether such activities
        have been authorized by you or undertaken by you.{' '}
      </p>
      <p>
        5.4 <u>Keeping your information up to date</u>. You are responsible for
        keeping your email address and contact number up to date in your Account
        profile in order to receive any notices or alerts that we may send you.
        We assume no responsibility for any loss that you may sustain due to
        compromise of your sensitive information or failure to follow or act on
        any notices or alerts that we may send to you.{' '}
      </p>
      <p>
        5.5 <u>Verification of Your Account</u>. Due to any applicable statutory
        and regulatory requirements, the creation and use of your Account can be
        subject to verification. You agree to provide us, and any third-party
        service provider designated by us (if applicable), with the information
        we may from time to time request for the purposes of identity
        verification, compliance with KYC rules, as well as detection of money
        laundering, terrorism financing, fraud or any financial or other crime.
        The requested information may include Personal Data (please refer to our
        Privacy Policy). By providing us or such third-party service providers
        with the information we request, you confirm that such information is
        true and accurate, and agree to inform us in case of change concerning
        such information.{' '}
      </p>
      <p>
        5.6 <u>Additional information</u>. You agree to provide such additional
        information and documents at the request of any competent authority or
        in case of application of any applicable law or regulation, including
        laws related to anti-laundering (legalization) of incomes obtained by
        criminal means, or for counteracting financing of terrorism. You agree
        and undertake to provide any such additional information and documents
        that we may from time to time require for the purpose of or otherwise to
        facilitate compliance with any applicable laws and regulation.
      </p>
      <p>
        5.7 <u>Suspension or Termination of Account</u>. We reserve the right to
        suspend or terminate your Account if any information provided by you
        under these Terms is inaccurate, untrue, or incomplete, or if you fail
        to comply with any Account registration requirements or these Terms. We
        may suspend or terminate your access to and use of the Site at any time,
        for any reason, in our sole and absolute discretion, without incurring
        liability of any kind to you as a result of such suspension or
        termination.{' '}
      </p>

      <h2>6. AML and KYC Compliance</h2>
      <p>
        6.1 <u>Compliance with KYC/AML Regulations</u>. Pursuant to the economic
        sanctions’ programs administered in the jurisdictions where we conduct
        business, we may be prohibited from providing access to the Site or
        entering into relationships with certain individuals and entities. We
        are therefore obliged to comply with applicable KYC requirements and AML
        laws and regulations (together, “<strong>KYC/AML Regulations</strong>’’)
        on any Users. You may not be able to make any transactions using our
        Site unless you comply with all our established KYC and AML procedures,
        requirements and Policies to our satisfaction.
      </p>
      <p>
        6.2 <u>Verification of documents</u>. Any documents submitted by you
        must be verified prior to opening of an Account. Verification of
        identity will require multi-factor authentication, layered security and
        other controls to ensure a meaningful User identity confirmation process
        based on accumulated reward size, among other factors.
      </p>

      <h2>7. Website Accuracy, Third Party Materials and Use of the Site</h2>
      <p>
        7.1 <u>Website Accuracy</u>. Although we intend to provide accurate and
        timely information on the Site, the Site may not always be entirely
        accurate, complete or current and may also include technical
        inaccuracies or typographical errors. In an effort to continue to
        provide you with as complete and accurate information as possible,
        information may be changed or updated from time to time without notice,
        including without limitation information regarding our Policies,
        products. Accordingly, you should verify all information before relying
        on it, and all decisions based on information contained on the Site are
        your sole responsibility and we shall have no liability for such
        decisions. You especially acknowledge, understand and agree that we do
        not assume any liability and shall not be liable for any loss or damage
        arising out of or in connection with any trading or transfer decision,
        made based on any information available on the Site.
      </p>
      <p>
        7.2 <u>Third-Party Materials</u>. From time to time, the Site may
        contain references or links to third-party materials (including, without
        limitation, websites) and third-party applications which are not
        controlled by us. Such information, links, and third-party applications
        are provided as a convenience to you. Such links should not be
        considered endorsements and such reference does not imply our
        recommendation, approval, affiliation, or sponsorship of that respective
        property, product, service, or process. You acknowledge and agree that
        we are not responsible for any aspect of the information, content, or
        services contained in any third-party materials or on any third-party
        sites accessible or linked to the Site, including without limitation
        content, property, goods or services available on the linked sites or
        services.
      </p>
      <p>
        7.3 <u>Prohibited Use</u>. In connection with your use of the Site, and
        your interactions with other Users, and third parties you agree and
        represent, you will not violate any law, contract, intellectual property
        or other third-party right, will not engage in any illegal,
        unauthorized, or improper activity. You agree that you will abide by
        these Terms and prohibition to not use the Site for including, but not
        limited to, production or dissemination of any pornographic or
        inappropriate material, racist content or financing of illicit
        activities, or any other use that would contradict or violate our
        Policies. We reserve the right at all times to monitor, review, retain
        and/or disclose any information as necessary to satisfy any applicable
        law, regulation, legal process or governmental request. We reserve the
        right to cancel and/or suspend your Account immediately and without
        notice if we determine, in our sole discretion after the consideration
        of the situation as a whole, that your Account is associated with
        prohibited use and/or a prohibited business.{' '}
      </p>
      <p>7.4 You shall not:</p>
      <ul className="letters">
        <li>
          use the Site to conduct any fraudulent, immoral or illegal activities
          or unauthorized purpose, or engage in, encourage or promote any
          activity that violates these Terms or applicable laws and regulations,
          including but not limited to money laundering, terrorist financing or
          deliberately engaging in activities designed to adversely affect the
          performance of the Site;
        </li>
        <li>
          reproduce, copy, transmit, distribute, display, or otherwise use any
          materials or contents on the Site without prior written consent from
          Animoca Brands or the relevant Intellectual Property Right owner;
        </li>
        <li>
          use the Site in any manner that could interfere with, disrupt,
          negatively affect or inhibit other users from fully enjoying the Site,
          or interfere, disrupt or reverse-engineer any aspects or features of
          the Site that could modify, damage, disable, overburden or impair the
          functioning of the Site in any manner;
        </li>
        <li>
          take any action to gain or attempt to gain unauthorized access to any
          Account or the Wallet of other Users;
        </li>
        <li>
          attempt to exploit vulnerability of any system or network of ours or
          breach any security or authentication measures implemented by us;
        </li>
        <li>
          attempt to decipher, decompile, disassemble or reverse engineer any of
          the software used to provide the Site;
        </li>
        <li>
          interfere with, or attempt to interfere with, the access of any user,
          host or network, including, without limitation, sending a virus,
          overloading, flooding, spamming, or mail-bombing the Site;
        </li>
        <li>
          use any robot, spider, crawler, scraper, script, browser extension,
          offline reader or other automated means, program or interface not
          authorized by us to access the Site, extract data or otherwise
          interfere with or modify the rendering of Site pages or functionality,
          or to incorporate the Site into any other program, website or
          application;
        </li>
        <li>
          use data collected from the Site to contact individuals, companies, or
          other persons or entities;
        </li>
        <li>
          use data collected from the Site for any direct marketing activity;
        </li>
        <li>
          use the Site to conduct electronic spamming or otherwise distribute
          ant unsolicited or unauthorized advertising, promotional or marketing
          material, junk or chain messages;
        </li>
        <li>
          bypass or ignore instructions that control all automated access to the
          Site; and
        </li>
        <li>
          use the Site to carry out any financial activities subject to
          registration or licensing, including but not limited to creating,
          listing, or buying securities, commodities, options, real estate, or
          debt instruments.{' '}
        </li>
      </ul>
      <p>
        7.5 <u>Suspension, Termination and Cancellation of Accounts</u>. We may,
        at our sole discretion and without any prior notification, (i) suspend,
        restrict, or terminate your access to any or all of the Site, and/or
        (ii) deactivate or cancel your Account if:
      </p>
      <ul className="letters">
        <li>
          We reasonably suspect you of using your Account in connection with
          illegal, unauthorized, or improper activity;
        </li>
        <li>
          We are so required by an enforceable subpoena, court order, or binding
          order of the court or government authority or
        </li>
        <li>
          Use of your Account is subject to any pending litigation,
          investigation, or government proceeding and/or we perceive a
          heightened risk of legal or regulatory non-compliance associated with
          your Account activity; or
        </li>
        <li>Our partners (if any) are unable to support your use; or</li>
        <li>
          We are required to do so in order to comply with any AML/KYC laws and
          regulations; or
        </li>
        <li>
          You violate these Terms (in particular, but not limited to, providing
          correct and accurate identification information for AML/KYC purposes,
          or violating any user warranties given hereunder); or
        </li>
        <li>
          You take any action that we deem as circumventing our controls,
          including, but not limited to, opening multiple Accounts or abusing
          promotions which we may offer from time to time.
        </li>
      </ul>
      <p>
        7.6 <u>Temporary Suspension</u>. In the event that a technical problem
        causes system outage or Account errors, we may temporarily suspend
        access to Your Account until the problem is resolved.
      </p>
      <p>
        7.7 <u>Termination by User</u>. The User may terminate this Agreement at
        any time without giving us any prior notice by discontinuing use of the
        Site for a period of at least two (2) weeks.{' '}
      </p>

      <h2>8. Intellectual Property Rights</h2>
      <p>
        8.1 <u>Ownership</u>. Unless otherwise indicated in writing by us, the
        Site, and all content, design element, and other materials contained
        therein, including, without limitation, our logo and all designs, text,
        graphics, pictures, information, data, software, sound files, other
        files and the selection and arrangement thereof (collectively, “
        <strong>Content</strong>”) are our proprietary property or that of our
        affiliates, licensors or users, as applicable. All underlying
        Intellectual Property of the Site, including, but not limited to
        copyrights, patents, service marks, any registered trademarks, domain
        names and other proprietary rights, are the proprietary property of
        Animoca Brands or its licensors (if any), and are protected by
        copyright, patent, trade secret and other applicable Intellectual
        Property laws. We retain any and all rights, title and interest in and
        to the Site, the Content (including, without limitation, all
        Intellectual Property Rights), including all copies, modifications,
        extensions and derivative works thereof. Your right to use the Site, the
        Content is limited to the rights expressly granted in these Terms. No
        licenses to use any of our trademarks or brands are to be inferred or
        assumed pursuant to the use of any of our Site. All rights not expressly
        granted to you are reserved and retained by us or our licensors, as the
        case may be.{' '}
      </p>
      <p>
        8.2 <u>Unauthorized use of Intellectual Property Rights</u>. You
        expressly agree not to duplicate, copy, transmit, distribute, license,
        reverse engineer, modify, publish, display, reuse or participate in the
        transfer or sale of, create derivative works from, or in any other way
        exploit any of our Intellectual Property Rights or knowingly or
        recklessly encourage or assist any third parties to infringe our
        Intellectual Property Rights without our express prior written consent.
      </p>
      <p>
        8.3 <u>Accuracy and reliability of Content</u>. We do not assume any
        responsibility for the accuracy, reliability, or completeness of the
        Content, nor do we represent or warrant that the Site or the Content are
        current, error-free or free of viruses or other harmful components.
      </p>
      <p>
        8.4 <u>Third party materials</u>.
      </p>
      <ul className="letters">
        <li>
          Some materials on the Site may be subject to copyright owned by third
          parties. You shall not copy, imitate or use any such materials, in
          whole or in part, without prior consent from their respective owner.
          You acknowledge that all the names and/or logos used on the Platform
          are for identification purposes only. Use or display of these names
          and/or logos does not constitute or imply endorsement.
        </li>
        <li>
          The Site may contain links and pointers to other websites, resources,
          and advertisers of the Site. Links to and from the Site to other
          materials, mobile applications or websites, maintained by third
          parties, do not constitute an endorsement by us or any affiliation
          with any third-party site or content. We are not responsible for the
          availability of these third-party resources, or their contents. We
          have not reviewed any or all of the materials, mobile applications or
          sites linked to the Site and are not responsible for the content of
          any third-party pages or any other materials, mobile applications or
          websites linked to the Site. The User’s linking to other third-party
          sites is at his own risk. By clicking on any such link, the User
          acknowledges that we have no control over and makes no representations
          of any kind with respect to, such other materials, mobile
          applications, websites, or any content contained within such other
          materials, mobile applications or websites, and the User hereby
          revokes any claim against us with respect to such other materials,
          mobile applications or websites. The User should direct any concerns
          regarding any external link to its mobile application or site
          administrator or webmaster.
        </li>
      </ul>

      <h2>9. Limitation of Liability</h2>
      <p>9.1</p>
      <p>
        9.2You assume all risks when using the Site. We, our directors,
        officers, employees, agents, licensors, attorneys, independent
        contractors, providers, subsidiaries, and affiliates shall in no event
        be liable, directly or indirectly, for any losses, claims, damages or
        liabilities, general, special, compensatory, consequential and/or
        incidental, incurred by you arising out of or relating to or in
        connection with any reliance of or acceptance of these Terms or with the
        use of or access to the Site, any performance or non-performance of the
        Site, or other service provided by us or on our behalf and that of our
        affiliates, including but not limited to loss of profits, loss of value,
        loss of goodwill, loss of data and any other damages.{' '}
      </p>
      <p>
        We shall have no responsibility for and shall not be liable for losses
        incurred by the User caused by or resulting from any of the following:
      </p>
      <ul className="letters">
        <li>fraud or wilful misconduct by any of the Users; or</li>
        <li>
          any damage or interruptions caused by any computer malware, spyware,
          or scareware that may affect your computer or other equipment, or any
          phishing, spoofing or other attack. We advise the regular use of a
          reputable and readily available virus screening and prevention
          software.
        </li>
      </ul>
      <p>
        9.3 We do not represent, warrant or undertake that: (a) the Site is fit
        for the User’s purposes; (b) the Site is compatible with the User’s
        mobile devices or computers; (c) the Site is free of bugs, errors,
        defects, malware and viruses; and any electronic files available on the
        Site will be free of any computer virus, trojan, worm or other computer
        code that, directly or indirectly, is harmful or disabling or which
        assists in or enables unauthorized access to or corruption of data.
      </p>
      <p>
        9.4 To the fullest extent permissible pursuant to applicable law, we
        disclaim all warranties, express or implied, including, but not limited
        to, implied warranties of merchantability and fitness for a particular
        purpose, non-infringement, copyright compliance, legality, title,
        custom, trade, quiet enjoyment, system integration and freedom from
        computer virus. We make no representations or warranties or endorsements
        of any kind whatsoever, express and implied, as to the Site or the
        Content. The User uses the Site at his own risk. We assume no liability
        for any action regarding transmissions, communications, publication, or
        content provided by any User or third party.
      </p>
      <p>
        9.5 If you have a dispute with one or more Users of our Site, to the
        extent permitted by law, you release us, our affiliates and service
        providers, and each of their respective officers, directors, agents,
        joint venturers, employees and representatives from any and all claims,
        demands and damages (actual and consequential) of every kind and nature
        arising out of or in any way connected with such disputes. In addition,
        to the extent permitted by law, in entering into this release you
        expressly waive any protections that would otherwise limit the coverage
        of this release to include only those claims which you may know or
        suspect to exist in your favor at the time of agreeing to this release.
      </p>
      <p>
        9.6 THE SITE AND THE CONTENT ARE PROVIDED “AS IS” AND “AS AVAILABLE”
        BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED,
        OR STATUTORY. WE SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE,
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO ANY PART
        OF THE SITE, OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE
        CONTINUOUS, UNINTERRUPTED, TIMELY, ERROR-FREE OR SECURE. OPERATION OF
        THE SITE MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF OUR
        CONTROL. WE ALSO SPECIFICALLY DISCLAIM ANY REPRESENTATION, WARRANTY OR
        UNDERTAKING IN ANY FORM WHATSOEVER TO ANY ENTITY OR PERSON, INCLUDING
        ANY REPRESENTATION, WARRANTY OR UNDERTAKING IN RELATION TO THE TRUTH,
        ACCURACY AND COMPLETENESS OF ANY OF THE INFORMATION SET OUT IN THESE
        TERMS.
      </p>
      <p>
        9.7 WE, OUR AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE
        OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, SHAREHOLDERS, PARENTS,
        SUBSIDIARIES, EMPLOYEES OR REPRESENTATIVES DO NOT VERIFY, CONFIRM OR IN
        ANY WAY WHATSOEVER TAKE RESPONSIBILITY FOR USERS’ DIGITAL ASSETS AND
        ESPECIALLY THEIR SUBSEQUENT VALUE.
      </p>
      <p>
        9.8 WITH THE EXCEPTION OF CLAIMS RELATING TO A BREACH OF OUR PROPRIETARY
        AND INTELLECTUAL PROPERTY RIGHTS AS GOVERNED BY SECTION 8, IN NO EVENT
        SHALL THE AGGREGATE LIABILITY OF EACH PARTY TOGETHER WITH ALL OF ITS
        AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE TOTAL
        AMOUNT PAID BY YOU HEREUNDER FOR ACCESS AND USE OF OUR SITE GIVING RISE
        TO THE LIABILITY IN THE TWELVE (12) MONTHS PRECEDING THE FIRST INCIDENT
        OUT OF WHICH THE LIABILITY AROSE. THE FOREGOING LIMITATION WILL APPLY
        WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF
        LIABILITY, BUT WILL NOT LIMIT YOUR PAYMENT OBLIGATIONS UNDER THESE
        TERMS.
      </p>

      <h2>10. Taxes</h2>
      <p>
        10.1 By accepting these Terms and accessing our Site you acknowledge and
        agree that you are solely responsible for your own filings, tax returns
        and similar reports on any transactions in connection with any use of
        the Site.{' '}
      </p>

      <h2>11. Force Majeure</h2>
      <p>
        We shall not be liable for delays, failure in performance or
        interruption of service which result directly or indirectly from any
        cause or condition beyond our reasonable control, including but not
        limited to, any delay or failure due to any act of God, act of civil or
        military authorities, act of terrorists, civil disturbance, war, strike
        or other labor dispute, fire, interruption in telecommunications or
        Internet services or network provider services, failure of equipment
        and/or software, hacking attacks, major market disturbances, other major
        event or catastrophe, pandemic, or any other occurrence which is beyond
        our reasonable control and shall not affect the validity and
        enforceability of any remaining provisions.
      </p>

      <h2>12. Indemnification</h2>
      <p>
        12.1 <u>Indemnification</u>. The User shall indemnify, defend, and hold
        harmless Animoca Brands and its affiliates, their respective directors,
        officers, employees, agents, licensors, attorneys, independent
        contractors, providers, subsidiaries, and affiliates (the “
        <strong>Indemnified Parties</strong>”) from and against any and all
        actual or alleged claims, actions, proceedings, investigations, demands,
        suits, losses, damages, demand of liability, costs, including
        attorneys’, investigators’, and experts’ (or similar) fees,
        disbursements and all expenses incurred, whether known or unknown,
        foreseen or unforeseen, matured or unmatured, or suspected or
        unsuspected, in law or equity, whether in tort, contract or otherwise
        (the “<strong>Claims</strong>”), arising out of or in connection with:
      </p>
      <ul className="letters">
        <li>
          any negligent acts, omissions, or willful misconduct by the User;
        </li>
        <li>User’s access to and uses or misuses of the Site;</li>
        <li>any dispute between the Users;</li>
        <li>the User’s violation of these Terms or any Policy;</li>
        <li>
          the User’s violation or infringement of any rights of any third party,
          including but not limited to the Intellectual Property Rights;
        </li>
        <li>
          the User’s violation or infringement of any laws or regulations;
          and/or
        </li>
        <li>
          any action taken or omitted to be taken by Animoca Brands hereunder or
          pursuant to any instructions from the User.{' '}
        </li>
      </ul>
      <p>
        12.2 <u>Notification and Control of Claims</u>. You agree to promptly
        notify us of any Claims and cooperate with us in defending such Claims.
        The User further agrees that the Indemnified Parties shall have control
        of the defense or settlement of any third-party Claims. This indemnity
        is in addition to, and not in lieu of, any other claims or indemnities
        that we may have against the User.
      </p>
      <h2>13. Miscellaneous</h2>
      <p>
        13.1 These Terms embody all the terms and conditions agreed upon between
        the Parties as to the subject matter of these Terms and supersedes and
        cancels in all respects all previous correspondence, understandings, and
        agreements between the Parties with respect to the subject matter
        hereof, whether such be written or oral.
      </p>
      <p>
        13.2 Animoca Brands may in its sole discretion amend these Terms without
        any prior notice to you. While Animoca Brands will carry out reasonable
        efforts to notify you of any changes, your continued access to and use
        of the Site constitutes your acceptance of any amendment to these Terms.
      </p>
      <p>
        13.3 The failure of any Party hereto at any time to require performance
        or observance by the other Party of any provision of these Terms shall
        in no way affect the right of such first Party to require performance of
        this provision and any waiver by any Party of any breach of any
        provision of these Terms shall not be construed as a waiver of any
        continuing or succeeding breach of such provision, a waiver of the
        provision itself or a waiver of any right under these Terms.
      </p>
      <p>
        13.4 If any of the provisions in these Terms are deemed invalid,
        illegal, prohibited, void, or for any reason is unenforceable, that
        provision will be ineffective and deemed severable and will not affect
        the validity and enforceability of the remaining provisions of these
        Terms.
      </p>
      <p>
        13.5 These Terms are governed by and shall be construed in all respects
        in accordance with the laws of Hong Kong. Any dispute, controversy, or
        claim arising out of or relating to these Terms, or the interpretation,
        breach, termination, validity, or invalidity thereof, shall be referred
        to and finally resolved by arbitration in Hong Kong by the Hong Kong
        International Arbitration Centre (“<strong>HKIAC</strong>”) in
        accordance with the HKIAC Administered Arbitration Rules in force at the
        time when the notice of arbitration is submitted. The law of this
        arbitration clause shall be construed and enforced in accordance with
        Hong Kong law. The seat of arbitration shall be in Hong Kong. The number
        of the arbitrator shall be one (1). The arbitral proceedings shall be
        conducted in English, and the arbitral award shall be final and binding
        on the parties to such proceedings.
      </p>
    </div>
  </ModalWrapper>
);

export default Terms;
