import React, { useState, useRef, useEffect } from 'react';
import Tabs from './Tabs';
import MocaLeaderboard from './mocaLeaderboard/MocaLeaderboard';
import TicketLeaderboard from './ticketLeaderboard/TicketLeaderboard';
import TribeLeaderboard from './tribeLeaderboard/TribeLeaderboard';
import arrow from '../../assets/images/arrow-bold.svg';
import moca from '../../assets/images/moca-icon-pink.svg';
import realm from '../../assets/images/realmicon.svg';
import tribe from '../../assets/images/tribe-icon.svg';

const tabs = [
  {
    key: 'Moca',
    text: (
      <>
        <span>
          <img src={moca} alt="moca" />
        </span>
        1. Moca Leaderboard
      </>
    ),
  },
  {
    key: 'Realm',
    text: (
      <>
        <span>
          <img src={realm} alt="real" />
        </span>
        2. Realm Ticket Leaderboard
      </>
    ),
  },
  {
    key: 'Tribe',
    text: (
      <>
        <span>
          <img src={tribe} alt="tribe" />
        </span>
        3. Tribe Leaderboard
      </>
    ),
  },
];
const LeaderBoard = () => {
  const tabsRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);

  const [toggle, setToggle] = useState(false);
  const handleSelectedTab = (value) => {
    setSelectedTab(value);
    setToggle(false);
  };
  const handleToggle = () => setToggle(!toggle);
  const handleClickOutside = (event) => {
    if (tabsRef.current && !tabsRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <div className="leader-board-wrapper">
      <p className="event-description">
        Start playing to rank up in <span className="text-color">1. Moca</span>,{' '}
        <span className="text-color">2. Realm Ticket</span>, and{' '}
        <span className="text-color">3. Tribe</span> Leaderboard.
      </p>
      <p className="event-description-mobile">
        Start playing to rank up in
        <br /> <span className="text-color">1. Moca</span>,{' '}
        <span className="text-color">2. Realm</span>, and{' '}
        <span className="text-color">3. Tribe</span> Leaderboard.
      </p>
      <div className="show-tabs" ref={tabsRef}>
        <button
          className={`btn-show ${toggle ? 'show' : ''}`}
          onClick={handleToggle}
        >
          <span className="text">Change Leaderboard</span>{' '}
          <span>
            {' '}
            <img src={arrow} alt="" />
          </span>
        </button>
        <Tabs
          className={`tabs-leaderboard ${toggle ? 'show' : ''}`}
          items={tabs}
          selectedTab={selectedTab}
          handleSelectedTab={handleSelectedTab}
        />
      </div>
      <div
        className={`tab-content ${
          selectedTab === tabs[0].key ? 'selected' : ''
        }`}
      >
        <MocaLeaderboard />
      </div>
      <div
        className={`tab-content ${
          selectedTab === tabs[1].key ? 'selected' : ''
        }`}
      >
        <TicketLeaderboard />
      </div>
      <div
        className={`tab-content ${
          selectedTab === tabs[2].key ? 'selected' : ''
        }`}
      >
        <TribeLeaderboard />
      </div>
    </div>
  );
};
export default LeaderBoard;
