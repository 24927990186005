import React from 'react';
import ModalWrapper from './common/ModalWrapper';
import { env } from '../utils/envHelpers';

const Licence = ({ toggleModalLicence }) => (
  <ModalWrapper
    className="licence-wrappar"
    onClose={() => toggleModalLicence()}
  >
    <h2 className="title">License Agreement</h2>
    <p>The NFT License Agreement is hosted on {env.licenseIpfsUrl}.</p>
    <p>
      You may read the contents at this{' '}
      <a href={env.licenseReadUrl} target="_blank" rel="noreferrer">
        Github Repository.
      </a>
    </p>
  </ModalWrapper>
);

export default Licence;
