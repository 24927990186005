import React from 'react';
import { formatWallet } from '../utils/realmHelper';

const RegisteringWallet = (props) => (
  <>
    {props?.isRegisted ? (
      <div className="registed-wallet-info">
        <div className="wallet-container">
          <span>Your Registered Wallet</span>
          <span className="bold">{formatWallet(props?.wallet)}</span>
        </div>
      </div>
    ) : (
      <div className="hot-wallet-info registering">
        <div className="wallet-container">
          <span>Registering Wallet:</span>
          <span className="bold">{formatWallet(props?.wallet)}</span>
        </div>
      </div>
    )}
  </>
);

export default RegisteringWallet;
