import React from 'react';

const EventHowToRegister = () => (
  <div className="event-section event-ht-register">
    <h2 className="event-section-title">How to Register</h2>
    <div className="event-section-content">
      <ol>
        <li>
          <h3>Register with a selected Moca or by holding a Realm Ticket</h3>
          <p>
            Moca: Select your Moca agent using the box on the right and click
            “Register Now”. The tribe of the selected Moca will also determine
            the tribe you will be playing for, so pick wisely!
          </p>
          <p>
            Realm Ticket: Make sure your connected wallet holds one or more
            Realm Tickets, then click “Register with Realm Ticket X1”. This
            option will only be available for those who do not hold any Moca.
          </p>
        </li>
        <li>
          <h3>Link registered wallet with Gamee account</h3>
          <p>
            Click on “Link Wallet” and head to the wallet page on Gamee website.
            Connect your registered or delegated wallet by clicking “Connect
            Wallet” and “Connect” on the wallet pop-up. If you’re not on Polygon
            Network, follow the pop-up to switch network.
          </p>
          <p>
            Click “Login to Arc8” and log in with your Acr8 Account. Simply log
            in with your Apple, Google, or Facebook account for new joiners.
            This account will now be your Arc8 account.
          </p>
          <p>
            Click “Pair to Arc8” under the connected wallet section and sign the
            wallet pop-up
          </p>
        </li>
        <li>
          <h3>Download the Arc8 app</h3>
          <p>
            Download the app on{' '}
            <a
              href="https://apps.apple.com/app/arc8-gmee-community-network/id1583002431"
              title="Apple Store"
              target="_blank"
              rel="noreferrer"
            >
              App Store
            </a>{' '}
            or{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.gamee.arc8.android.app&pli=1"
              title="Google Play"
              target="_blank"
              rel="noreferrer"
            >
              Google Play
            </a>
            , then log in using the same account in the previous step.
          </p>
        </li>
        <li>
          <h3>Start playing</h3>
          <p>
            Join three mini-games and prepare yourself for the tournament in-app
            between 20 April - 27 April. Have fun and climb the ranks!
          </p>
        </li>
      </ol>
      <p className="note">
        Note: MocaXP and RTM earned through the activation will be accrued to
        the selected Moca, with additional rewards distributed to the wallet
        address holding the said Moca during the reward distribution phase.
      </p>
    </div>
  </div>
);

export default EventHowToRegister;
