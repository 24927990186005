import React from 'react';
import { HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';
import whaleBoost from '../../assets/images/user-board/icon_moca_whale.svg';
import mocaIcon from '../../assets/images/user-board/icon_moca_normal.svg';
import mocaDanger from '../../assets/images/user-board/mocaDangerNew.svg';

const WhaleBoostTooltip = () => (
  <Tooltip
    className="moca-event-tooltip bottom-tooltip whale-tooltip"
    title={<HelpIcon />}
    content={
      <div className="moca-SoftStake-tooltip">
        <div className="SoftStake-tooltip-header">
          <h2 className="header-name">
            <img src={whaleBoost} alt="" />
            Whale Boost
          </h2>
          <span className="header-value">
            <h2>+0.8~</h2>
            <h2>4.0 XP</h2>
          </span>
        </div>
        <p className="description">Holding 3 or more Mocas</p>
        <div className="whale-boost-list">
          <ul className="list">
            <li className="list-item">
              <div className="list-item__moca-quantity">
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <span>x3</span>
              </div>
              <div className="list-item__moca-info">
                <span className="benefit">+0.8 XP</span>
                <span>
                  {' '}
                  to each
                  <img src={mocaIcon} alt="" />
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item__moca-quantity">
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <span>x6</span>
              </div>
              <div className="list-item__moca-info">
                <span className="benefit">+2.4 XP</span>
                <span>
                  {' '}
                  to each
                  <img src={mocaIcon} alt="" />
                </span>
              </div>
            </li>
            <li className="list-item">
              <div className="list-item__moca-quantity">
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <img src={mocaIcon} alt="" />
                <span>x8</span>
              </div>
              <div className="list-item__moca-info">
                <span className="benefit">+4.0 XP</span>
                <span>
                  to each
                  <img src={mocaIcon} alt="" />
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div className="message">
          <img src={mocaDanger} alt="" />
          <span>
            If there's a rare Moca in the 3+ Mocas,{' '}
            <strong>only the non-rare Mocas </strong>will receive the whale
            boost.
          </span>
        </div>
      </div>
    }
  />
);

export default WhaleBoostTooltip;
