import { useAddress, useDisconnect } from '@thirdweb-dev/react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EventHeader from './EventHeader';
import EventTitleCampaign from './EventTitleCampaign';
import EventTitlePage from './EventTitlePage';
import LeaderBoardCampaign from './LeaderBoardCampaign';
import EventSidebarCampaign from './EventSidebarCampaign';
import { useEventTimeCountDown } from '../../hooks/useCountDown';
import { env } from '../../utils/envHelpers';

const CampaignWrapper = () => {
  const [data, setData] = useState();
  const [height, setHeight] = useState();
  const address = useAddress();
  const disconnect = useDisconnect();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { statusEvent } = useEventTimeCountDown(
    data?.start_date,
    data?.end_date
  );

  useEffect(() => {
    fetch(`${env.apiUrl}/raffle-campaign-detail/${id}`)
      .then((response) => response.json())
      .then((values) => setData(values?.campaigns))
      .catch((error) => {
        console.error('Error fetching campaign data:', error);
      });
  }, [id]);

  useEffect(() => {
    const buttonConnect = document.querySelector('.connect.btn');
    const handlePostMessage = (e) => {
      if (e.data?.type === 'w3wCampaignJoinSuccess') {
        console.log(e.data);
      }

      if (e.data?.type === 'w3wDisconnectWallet') {
        console.log(4, e.data);
        disconnect()
          .catch(() => {
            console.log('handle disconnect errors');
          })
          .finally(() => {
            window.location.reload();
          });
      }
      // if (e.data?.type === 'w3wCampaignJoinFailed') {
      //   // Add failed logic here
      //   console.log(e.data);
      // }
      if (e.data?.type === 'w3wConnectWallet') {
        if (buttonConnect) {
          buttonConnect.click();
        }
        // Add connect wallet logic here
      }
      if (e.data?.type === 'w3wPageSizeChanged') {
        const iframeHeight = e.data.data.scrollHeight;
        setHeight(iframeHeight);
      }
    };

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [disconnect]);

  useEffect(() => {
    const iframe = document.getElementById('iframe');

    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: 'w3wWalletAddressUpdate',
          data: {
            walletAddress: address,
            walletType: 'meta_mask',
            href: 'http://localhost:3000',
          },
        },
        '*'
      );
    }
  }, [address]);
  // console.log(statusEvent, data, height);
  return (
    <div className="mocana-wrapper test">
      <EventHeader />
      {/* <EventTitlePage />” */}
      <div className="main-content">
        <EventTitleCampaign data={data} statusEvent={statusEvent} />
        <LeaderBoardCampaign
          data={data}
          address={address}
          heightIframe={height}
          statusEvent={statusEvent}
        />
      </div>
      <EventSidebarCampaign
        address={address}
        data={data}
        statusEvent={statusEvent}
        heightIframe={height}
      />
    </div>
  );
};

export default CampaignWrapper;
