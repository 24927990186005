import React from 'react';
import { formatPrice } from '../../utils/mathsHelper';
// import MocaDefaultImage from '../../assets/images/mocana/moca-default-img.png';
import { ItemCheckedIcon } from '../common/Icons';
import { env } from '../../utils/envHelpers';

/**
 *
 * @param string classNamePrefix
 * @param object item: {moca_id: string, img: string, url of the image, moca_name, tribe, moca_xp}
 * @param ... handleClick
 * @param object selectedItem : {moca_id: string, img: string, url of the image, moca_name, tribe, moca_xp}
 * @returns
 */
const EventMocaItem = ({
  classNamePrefix,
  item,
  handleClick,
  selectedItem,
}) => {
  const selected = selectedItem?.moca_id === item.moca_id;
  return (
    <li className={`${classNamePrefix}-item`}>
      <div
        className={`${classNamePrefix}-box`}
        onClick={() => {
          handleClick(item);
        }}
      >
        <div className={`${classNamePrefix}-img ${selected ? 'selected' : ''}`}>
          {selected && (
            <ItemCheckedIcon
              className={`${classNamePrefix}-icon`}
              aria-label="Moca Item Checked Icon"
            />
          )}
          <img
            title={item.moca_name}
            alt={item.moca_name}
            src={`${`${env.userThumbnailUrl}%23${item.moca_name}`}.jpg`}
            // src={item?.img || MocaDefaultImage}
          />
          <div className={`${classNamePrefix}-info`}>
            <h3 className={`${classNamePrefix}-name`}>
              #{item.moca_name || item.moca_id}
            </h3>
            <p className={`${classNamePrefix}-tribe`}>{item.tribe}</p>
          </div>
        </div>
        <p className={`${classNamePrefix}-xp`}>
          {formatPrice(item.moca_xp, 'XP')}
        </p>
      </div>
    </li>
  );
};

export default EventMocaItem;
