import React from 'react';
import Tribe from '../../assets/images/user-board/icon_moca_tribe.svg';
import mocaIcon from '../../assets/images/user-board/moca-pink.svg';
import angel from '../../assets/images/user-board/tribe-angel.svg';
import builder from '../../assets/images/user-board/tribe-builder.svg';
import connector from '../../assets/images/user-board/tribe-connector.svg';
import dreamer from '../../assets/images/user-board/tribe-dreamer.svg';
import neo from '../../assets/images/user-board/tribe-neo.svg';
import { HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';

const TribeBoostTooltip = () => (
  <Tooltip
    className="moca-event-tooltip bottom-tooltip tribe-tooltip"
    title={<HelpIcon />}
    content={
      <div className="moca-SoftStake-tooltip">
        <div className="SoftStake-tooltip-header">
          <h2 className="header-name">
            <img src={Tribe} alt="" />
            Tribe Boost
          </h2>
          <span className="header-value">
            <h2>+0.8XP</h2>
            <span>
              {' '}
              to each
              <img src={mocaIcon} alt="" />
            </span>
          </span>
        </div>
        <div className="tribe-content">
          <p className="tribe-title"> Hold All 5 Tribes</p>
          <div className="tribe-list">
            <div className="tribe-list-item">
              <img src={dreamer} alt="" />
              <span>Dreamer</span>
            </div>
            <div className="tribe-list-item">
              <img src={connector} alt="" />
              <span>Connector</span>
            </div>
            <div className="tribe-list-item">
              <img src={builder} alt="" />
              <span>Builder</span>
            </div>
            <div className="tribe-list-item">
              <img src={angel} alt="" />
              <span>Angel</span>
            </div>
            <div className="tribe-list-item">
              <img src={neo} alt="" />
              <span>Neo-capitalist</span>
            </div>
          </div>
        </div>
      </div>
    }
  />
);

export default TribeBoostTooltip;
