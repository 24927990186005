import React, { useState } from 'react';
import DustlandHeader from './DustlandHeader';
import DustlandConnectionWrapper from './DustlandConnectionWrapper';
import EventHowToRegister from './EventHowToRegister';
import EventRequirement from './EventRequirement';
import backToHome from '../../assets/images/back-to-home.svg';

import Tabs from './Tabs';
import Reward from './Reward';
import LeaderBoard from './LeaderBoard';

const tabs = [
  {
    key: 'Rewards',
    text: <>Rewards</>,
  },
  {
    key: 'How to Register',
    text: <>How to Register</>,
  },
  {
    key: 'Leader board',
    text: <>Leaderboard</>,
  },
];

const DustlandWrapper = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const handleSelectedTab = (value) => {
    // if (value.includes('Leader')) {
    //   return;
    // }
    setSelectedTab(value);
  };

  return (
    <div className="mocana-wrapper event-wrapper">
      <DustlandHeader />
      <DustlandConnectionWrapper>
        <Tabs
          items={tabs}
          selectedTab={selectedTab}
          handleSelectedTab={handleSelectedTab}
        />

        <div
          className={`tab-content ${
            selectedTab === tabs[0].key ? 'selected' : ''
          }`}
        >
          <Reward />
        </div>
        <div
          className={`tab-content ${
            selectedTab === tabs[1].key ? 'selected' : ''
          }`}
        >
          <EventRequirement />
          <EventHowToRegister />
        </div>
        <div
          className={`tab-content ${
            selectedTab === tabs[2].key ? 'selected' : ''
          }`}
        >
          <LeaderBoard />
        </div>
      </DustlandConnectionWrapper>
      <a className="back-to-home" href="/">
        <img src={backToHome} alt="" />
      </a>
    </div>
  );
};

export default DustlandWrapper;
