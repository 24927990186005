import React, { useEffect, useState } from 'react';

import TimeMyMoca from './TimeMyMoca';
import { env } from '../../utils/envHelpers';
import Tabs from './Tabs';
import subBgModal from '../../assets/images/user-board/sub-bg-modal.svg';

import arrowDown from '../../assets/images/arrow-down.svg';
import marketplace from '../../assets/images/mocana/moca_marketplace_white.svg';
import iconClose from '../../assets/images/mocana/close.svg';
import filter from '../../assets/images/symbol-filter.svg';
import { marketplaceLink, tabsModal, tabsSeason } from '../../utils/constants';
import FilterHistory from './FilterHistory';
import ToolTipModal from './TooltipModal';
// import PaginationMyMoca from './PaginationMyMoca';
import downWhite from '../../assets/images/arrow-down-white.svg';
import filteWhite from '../../assets/images/symbol-filter-white.svg';
import SortHistory from './SortHistory';
import { fetchMocaXp } from '../../api/fetchData';

import TableMyMocaXpSection from './TableMocaXpSection';

const TableMyMocaXp = ({ selectedMoca, setShowTooltip, xpSource }) => {
  const [selectedTabModal, setSelectedTabModal] = useState(tabsModal[0].key);
  const [selectedTabSeason, setSelectedTabSeason] = useState(tabsSeason[0].key);
  const [showSelect, setShowSelect] = useState(false);
  const [showFilterHistory, setShowFilterHistory] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [data, setData] = useState();
  const [dataInit, setDataInit] = useState([]);
  const [sortBy, setSortBy] = useState('date');
  const [isAsc, setIsAsc] = useState(false);

  const [xpFrom, setXpFrom] = useState([]);

  const convertTribe = (name) => {
    let parts = name.split('-');
    parts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1));
    return parts.join('-');
  };
  const toggleFilterHistory = () => setShowFilterHistory(!showFilterHistory);
  const handleSelectedTabModal = (value) => {
    setSelectedTabModal(value);
  };
  const toggleSort = () => setShowSort(!showSort);
  const handleSelectSection = () => setShowSelect(!showSelect);

  useEffect(() => {
    const fetchData = async () => {
      const results = await fetchMocaXp({
        id: selectedMoca.moca_id,
        season: selectedTabSeason,
      });

      if (results) {
        setData(results.results);
        setDataInit(results.results);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (xpFrom.length === 0 && dataInit.length === 0) return;
    if ((xpFrom.length === 0 || xpFrom.length === 3) && dataInit.length !== 0) {
      setData(dataInit);
      return;
    }
    const filteredData = dataInit.filter((item) =>
      xpFrom.includes(item.xp_sources)
    );
    setData(filteredData);
  }, [xpFrom]);

  return (
    <div className="modal-wrapper-parent">
      <div>
        <ToolTipModal>
          <div onClick={setShowTooltip} className="toggle-button">
            <img src={iconClose} alt={selectedMoca.moca_name} />
          </div>
          <div className="tooltip-content">
            <div className="left-block">
              <div className="moca-id">
                <a
                  href={`${marketplaceLink}:${selectedMoca.moca_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={marketplace} alt="" />
                </a>
                <span className="id">#{selectedMoca.moca_name}</span>
              </div>
              <div className="image-moca">
                <img
                  src={`${`${env.userBoardThumbnailUrl}%23${selectedMoca.moca_name}`}.jpg`}
                  alt="moca"
                />
              </div>
              <p className="title-xp">Total Moca XP gained </p>
              <p className="season">Season 1</p>
              <p className="xp-value">{selectedMoca.moca_xp} XP</p>
              <div className="moca-info-mobile">
                <div className="moca-info-mobile-id">
                  <span className="id">#{selectedMoca.moca_name}</span>
                  <a
                    href={`${marketplaceLink}:${selectedMoca.moca_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={marketplace} alt="" />
                  </a>
                </div>
                <p className="moca-info-mobile-title-xp">
                  Total Moca XP gained
                </p>
                <p className="moca-info-mobile-season">Season 1</p>
                <p className="moca-info-mobile-xp-value">
                  {selectedMoca.moca_xp} XP
                </p>
              </div>
            </div>
            <div className="info-moca">
              <Tabs
                items={tabsModal}
                selectedTab={selectedTabModal}
                handleSelectedTab={handleSelectedTabModal}
              />
              <div
                className={`${
                  selectedTabModal === tabsModal[0].key ? 'selected' : ''
                } tab-item moca-info-detail-wrapper`}
              >
                <div className="moca-info-detail">
                  <div className="row">
                    <span className="title">Tribe:</span>
                    <span className="description">
                      {convertTribe(selectedMoca.tribe)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Staking Status</span>
                    <span
                      className={`description  ${
                        selectedMoca.is_staked
                          ? 'text-color'
                          : ' text-color-red'
                      }`}
                    >
                      {selectedMoca.is_staked ? 'Staked' : 'Not Staked'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Current Staking Period</span>
                    <TimeMyMoca />
                  </div>
                  {/* <div className="row">
                <span className="title">Experiences Eligibility</span>
                <span className="description">
                  {selectedMoca.is_staked ? 'Eligible' : 'Ineligible'}
                  <a
                    href="https://mocaverse.xyz/event"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visit
                  </a>
                </span>
              </div> */}
                </div>
              </div>
              <div
                className={`${
                  selectedTabModal === tabsModal[1].key ? 'selected' : ''
                } tab-item`}
              >
                <div className="history-tab-wrapper">
                  <div className="history-header">
                    <div onClick={handleSelectSection} className="select-tab">
                      {tabsSeason.map((item, index) => {
                        if (item.key === selectedTabSeason)
                          return <span key={index}>{item.text}</span>;
                      })}
                      <span className="btn-arrow">
                        <img src={arrowDown} alt="" />
                      </span>
                    </div>
                    <div className="history-filter">
                      <button onClick={toggleFilterHistory}>
                        <img src={filter} alt="" />
                      </button>
                      <div
                        className={`mocana-filter-history ${
                          showFilterHistory && 'active'
                        }`}
                      >
                        <FilterHistory
                          setTribe={setXpFrom}
                          setShowFilter={toggleFilterHistory}
                        />
                        <div
                          onClick={() =>
                            setShowFilterHistory(!showFilterHistory)
                          }
                          className="toggle-button"
                        >
                          <img src={iconClose} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sort-filter-mobile">
                    <button className="moca-sort">
                      <span onClick={toggleSort}>
                        Sort by <img src={downWhite} alt="" />
                      </span>
                      <div
                        className={`mocana-list-sort ${showSort && 'active'}`}
                      >
                        <SortHistory
                          setShowSort={toggleSort}
                          setValueSort={setSortBy}
                          setIsAsc={setIsAsc}
                        />
                      </div>
                    </button>
                    <div className="moca-filter">
                      <button onClick={toggleFilterHistory}>
                        Filter <img src={filteWhite} alt="" />
                      </button>
                      <div
                        className={`mocana-list-filter ${
                          showFilterHistory && 'active'
                        }`}
                      >
                        <FilterHistory
                          setTribe={setXpFrom}
                          setShowFilter={toggleFilterHistory}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="history-content">
                    <div
                      className={`${
                        selectedTabSeason === tabsSeason[0].key
                          ? 'selected'
                          : ''
                      } tab-item `}
                    >
                      <TableMyMocaXpSection
                        data={data}
                        isAsc={isAsc}
                        iSortBy={sortBy}
                        xpSource={xpSource}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-bg-modal">
            <img src={subBgModal} alt="" />
          </div>
        </ToolTipModal>
      </div>
    </div>
  );
};

export default TableMyMocaXp;
