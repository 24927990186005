import React from 'react';
import Error from '../../assets/images/mocana/404.png';

const NoData = () => (
  <div className="moca-404">
    <img src={Error} alt="" />
    <h3>Invalid Token ID / Wallet Address</h3>
    <p>Please try again.</p>
  </div>
);

export default NoData;
