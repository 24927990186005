export const ERROR_MESSAGES = {
  UNAUTHORIZED: 'Please sign with your eligible wallet',
  ELIGIBLILITY_MISSING: 'Authenticated user is not in the allow list.',
  ELIGIBLILITY_EXCEED: 'Please select a quantity within your eligible quota',
  LICENSE_SIGNATURE_MISSING: 'Must provide a license agreement signature',
  LICENSE_SIGNATURE_ERROR: 'Please select the eligible wallet for signing',
  LICENSE_SIGNATURE_ALREADY_SUBMITTED: 'User has already submitted signature.',
  CLAIM_QUANTITY_CURRENCY_INCORRECT:
    'Please select the correct quantity and minting currency',
  CLAIM_CURRENCY_NOT_SUPPORTED:
    'Please select an eligible currency for payment',
  SURVEY_NOT_SUBMITTED: 'Please complete the survey before proceeding',
  SURVEY_ALREADY_SUBMITTED: 'User has already submitted survey.',
  TOKEN_NOT_EXISTS: 'Token does not exist',
  REALMS_USER_TOKEN_OWNERSHIP_MISSING:
    'Authenticated user does not own the specified token.',
  REALMS_USER_TOKEN_SENT_ALREADY: 'Token has already been sent to realm',
  REALMS_REALM_ID_TOKEN_ID_MISSING: 'Must provide realm_id and token_id',
  CLAIM_ONCE: 'User can only claim once.',
  MINT_FAIL: 'Mint Failed',
  UNCAUGHT_SERVER_ERROR: 'Uncaught Server Error',
  LICENSE_SIGNATURE_REJECTED: 'Please sign the license agreement to proceed',
  CONTRACT_REJECTED: 'Contract transaction failed.',
  CONTRACT_INVALID_REQ: 'Execution reverted: Invalid req',
  CONTRACT_INSUFFICIENT_FUNDS_FOR_GAS_PRICE_VALUE:
    'Please check your wallet to ensure there is sufficient balance',
  CONTRACT_INSUFFICIENT_FOR_GAS_FEE:
    'Please check your wallet to ensure there is sufficient ETH for gas',
  CONTRACT_CANNOT_ESTIMATE_GAS_FEE:
    'Unable to provide an estimate for gas fee required, please check your wallet',
  CONTRACT_NOT_SIGNING_TXN: 'Please sign the transaction to complete the mint',
  CONNECT_NOT_SIGNING: 'Please sign in with your wallet account to proceed',
};

export const formatContractErrorMessage = (msg) => {
  console.log({ msg });

  const reason = msg?.reason || 'Contract transaction failed';

  switch (reason) {
    case 'user rejected transaction':
      return ERROR_MESSAGES.CONTRACT_NOT_SIGNING_TXN;
    case 'execution reverted: Invalid req':
      return ERROR_MESSAGES.CONTRACT_INVALID_REQ;
    case 'missing revert data in call exception; Transaction reverted without a reason string':
      return ERROR_MESSAGES.CONTRACT_INSUFFICIENT_FUNDS_FOR_GAS_PRICE_VALUE;
    default:
      if (reason.includes('insufficient funds for gas * price + value')) {
        return ERROR_MESSAGES.CONTRACT_INSUFFICIENT_FUNDS_FOR_GAS_PRICE_VALUE;
      }
      break;
  }

  return reason;
};

export const formatErrorMessage = (msg) => {
  // 1. new formating error
  // {
  //     "key": "CLAIM_ONCE",
  //     "message": "User can only claim once."
  // }
  if (msg?.key) {
    return ERROR_MESSAGES[msg.key];
  }

  // 2. old formating error
  if (msg[0]?.message) {
    return msg[0].message;
  }

  return msg;
};

export const checkIsErrorWithAuth = (error) =>
  error === formatErrorMessage(ERROR_MESSAGES.UNAUTHORIZED);
