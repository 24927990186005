import React from 'react';
import { Header } from '../header/index';
import HomeIcon from '../../assets/images/mocana/mocana-logo.svg';

const links = [
  {
    key: 'home',
    icon: HomeIcon,
    href: '/event',
    text: '',
  },
  {
    key: 'mocana',
    url: '/mocana',
    text: 'Moca XP Rankings',
  },
  {
    key: 'experiences',
    url: '/event?type=partnership',
    text: 'Experiences',
    groups: [
      {
        key: 'partnership',
        label: 'Partnership',
        items: [
          {
            key: 'partnership_game',
            text: 'Into the Mocaverse with Gamee',
            url: '/event?type=partnership',
          },
        ],
      },
      // {
      //   key: 'odyssey',
      //   label: 'Odyssey',
      //   items: [
      //     {
      //       key: 'odyssey_ronin',
      //       text: 'RONIN Collaboration',
      //       url: '/event?type=odyssey',
      //     },
      //   ],
      // },
    ],
  },
];

const EventHeader = () => <Header links={links} clsNameNav="nav-dashboard" />;

export default EventHeader;
