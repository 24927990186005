import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletIsSigned, postWalletSign } from '../../api/fetchData';

import HomeMobileIcon from '../../assets/images/mocana/mocana-mobile-logo.svg';
import Modal from '../Modal';
import Socials from './HeaderSocials';
import ConnectWallet from './ConnectWallet';
import NavLinks from './NavLinks';
import { useLicense } from '../../hooks/useLicense';
import { setSignedLicense } from '../../redux/data/dataSlice';

const MobileNavWrapper = ({
  links,
  className,
  address,
  handleGetUpdate,
  handleDisconnect,
}) => {
  const { signature, signLicense } = useLicense({ license_signature: true });
  const isConnectedBefore = useSelector(
    (state) => state.data.isConnectedBefore
  );

  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const postVerifyingSign = async (sig) => {
    try {
      const results = await postWalletSign({
        wallet: address,
        signature: sig,
      });
      if (results && results.error) {
        dispatch(setSignedLicense(false));
        handleDisconnect();
      } else {
        dispatch(setSignedLicense(true));
        setIsSigned(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSignLicense = async () => {
    setError('');
    try {
      let sig = signature;
      if (!sig) {
        sig = await signLicense();
        if (sig?.error) {
          setError({
            msg: sig.info || 'Sign License failed. Please try again.',
          });
          handleDisconnect();
          return;
        }
        postVerifyingSign(sig);
      }
    } catch (err) {
      console.log('sign processing error', { err });
      setError({
        msg: 'sign processing error',
      });
      handleDisconnect();
    }
  };

  const fetchWalletIsSign = async () => {
    try {
      const results = await fetchWalletIsSigned({ wallet: address });

      if (results && results.errors) {
        dispatch(setSignedLicense(false));
        // popup the sign message and verify with the API
        handleSignLicense();
      } else {
        dispatch(setSignedLicense(true));
        setIsSigned(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setShowModal(false);
    /*
    If the user is already connected wallet when land to the site
    Check if the wallet is signed by API ( will pass the wallet and the API return “has_sign”=true/false)
    If not signed, popup the sign message and verify with the API ( will pass the wallet address and signature), connect the wallet if verify passed, or else cannot connect wallet
    If signed, nth happen
    */
    if (address && isConnectedBefore) {
      fetchWalletIsSign();
    } else if (address) {
      /*
     If the user is not connected wallet when land to the site
    User click the connect button
    Popup the sign message (no need checking)
    User sign the message and verify with the API ( will pass the wallet address and signature), connect the wallet if verify passed, or else cannot connect wallet */
      handleSignLicense();
    }
  }, [address]);

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <nav>
        <div className={!isActive ? 'mobile close' : ' mobile open'}>
          <div
            className={!isActive ? 'mobile-btn close' : ' mobile-btn open'}
            onClick={handleClick}
          >
            <img
              src={HomeMobileIcon}
              className={`mobile-moca-icon ${className}`}
              alt=""
            />
          </div>
          <ConnectWallet
            address={address}
            isSigned={isSigned}
            handleGetUpdate={handleGetUpdate}
            handleDisconnect={handleDisconnect}
            handleClick={() => setShowModal(true)}
          />
          <div className={`mobile-nav ${className}`}>
            <ul>
              <NavLinks
                type="mobile"
                links={links}
                handleMobileItemClick={handleClick}
              />
            </ul>
            <Socials />
          </div>
          <div className="navBg" />
          <div className="navoverlay" onClick={handleClick} />
        </div>
      </nav>
    </>
  );
};

export default MobileNavWrapper;
