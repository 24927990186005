import React, { useMemo } from 'react';
import EventBoard from './EventBoard';
import EventWhatIsTribeTooltip from './EventWhatIsTribeTooltip';
import RewardDetailXP23 from '../../assets/images/mocana/rw-detail-23xp.png';
import RewardDetailXP20 from '../../assets/images/mocana/rw-detail-20xp.png';
import RewardDetailXP10 from '../../assets/images/mocana/rw-detail-10xp.png';
import RewardDetailXP17 from '../../assets/images/mocana/rw-detail-17xp.png';
import RewardDetailXP3 from '../../assets/images/mocana/rw-detail-3xp.png';
import RewardDetailMatic from '../../assets/images/mocana/rw-detail-matic.png';
import RewardDetailMatic400 from '../../assets/images/mocana/rw-detail-400-matic.png';
import RewardDetailMatic150 from '../../assets/images/mocana/rw-detail-150-matic.png';
import RewardDetailGbot from '../../assets/images/mocana/rw-detail-gbot.png';
import RewardDetailRTM10 from '../../assets/images/mocana/rw-detail-10rtm.png';
import RewardDetailRTM15 from '../../assets/images/mocana/rw-detail-15rtm.png';
import RewardDetailRTM20 from '../../assets/images/mocana/rw-detail-20rtm.png';
import RewardDetailRTM25 from '../../assets/images/mocana/rw-detail-25rtm.png';

const boardItems = [
  {
    key: 'board1',
    className: 'event-highscore-board',
    title: 'High Score Leaderboard',
    subtitle: (
      <>
        The best score ever achieved in <strong>a single game</strong>.
      </>
    ),
    items: [
      {
        key: 'board1-reward1',
        title: (
          <>
            <span>1</span> st <span className="event-board-item-hr">-</span>{' '}
            <span>5</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        rewardItems: [
          {
            key: 'board1-reward1-1',
            img: RewardDetailXP23,
            name: 'Moca XP',
          },
          {
            key: 'board1-reward1-2',
            img: RewardDetailMatic400,
            name: 'MATIC',
          },
          {
            key: 'board1-reward1-3',
            img: RewardDetailGbot,
            name: 'G-Bot',
          },
        ],
      },
      {
        key: 'board1-reward2',
        title: (
          <>
            <span>6</span> th <span className="event-board-item-hr">-</span>{' '}
            <span>25</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#8CFFF8',
        rewardItems: [
          {
            key: 'board1-reward2-1',
            img: RewardDetailXP20,
            name: 'Moca XP',
          },
          {
            key: 'board1-reward2-2',
            img: RewardDetailMatic150,
            name: 'MATIC',
          },
          {
            key: 'board1-reward2-3',
            img: RewardDetailGbot,
            name: 'G-Bot',
          },
        ],
      },
      {
        key: 'board1-reward3',
        title: (
          <>
            From <br />
            <span>26</span>th<span className="event-board-badge">*</span>
          </>
        ),
        subtitle: <>*excluding Top 15% on Total Score Leaderboard</>,
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#FFA178',
        rewardItems: [
          {
            key: 'board1-reward3-1',
            img: RewardDetailXP10,
            name: 'Moca XP',
          },
        ],
      },
    ],
  },
  {
    key: 'board2',
    className: 'event-totalscore-board',
    title: 'Total Score Leaderboard',
    subtitle: (
      <>
        The total score accumulated in <strong>all previous games</strong>
      </>
    ),
    items: [
      {
        key: 'board2-reward1',
        title: (
          <>
            Top <br />
            <span>15</span>%<span className="event-board-badge">**</span>
          </>
        ),
        subtitle: (
          <>
            *excluding 1st - 25th on <br />
            High Score leaderboard
          </>
        ),
        rewardItems: [
          {
            key: 'board2-reward1-1',
            img: RewardDetailXP17,
            name: 'Moca XP',
          },
          {
            key: 'board2-reward1-2',
            img: RewardDetailMatic,
            name: '3K MATIC split equally',
            subtitle: '(Max. 100 per wallet)',
          },
        ],
      },
    ],
  },
  {
    key: 'board3',
    className: 'event-tribe-board',
    title: (
      <>
        Tribe Leaderboard <EventWhatIsTribeTooltip />
      </>
    ),
    subtitle: 'Contributed by top 5 players from each tribe',
    items: [
      {
        key: 'board3-reward1',
        title: (
          <>
            <span className="sm">All Players of the</span> Winning Tribe
          </>
        ),
        rewardItems: [
          {
            key: 'board3-reward1-1',
            img: RewardDetailXP3,
            name: 'Moca XP',
          },
          {
            key: 'board2-reward1-2',
            img: RewardDetailMatic,
            name: '2K MATIC split equally',
          },
        ],
      },
    ],
  },
];

const rtmBoardItems = [
  {
    key: 'board1',
    className: 'event-highscore-board',
    title: 'RTM - High Score Leaderboard',
    subtitle: (
      <>
        The best score ever achieved in <strong>a single game</strong>.
      </>
    ),
    items: [
      {
        key: 'board1-reward1',
        title: (
          <>
            <span>1</span> st <span className="event-board-item-hr">-</span>{' '}
            <span>5</span> th
          </>
        ),
        bgColor: 'rgba(0, 31, 67, 0.8)',
        rewardItems: [
          {
            key: 'board1-reward1-1',
            img: RewardDetailRTM25,
            name: 'Realm Ticket Miles',
          },
        ],
      },
      {
        key: 'board1-reward2',
        title: (
          <>
            <span>6</span> th <span className="event-board-item-hr">-</span>{' '}
            <span>25</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#8CFFF8',
        rewardItems: [
          {
            key: 'board1-reward2-1',
            img: RewardDetailRTM20,
            name: 'Realm Ticket Miles',
          },
        ],
      },
      {
        key: 'board1-reward3',
        title: (
          <>
            From <br />
            <span>26</span>th<span className="event-board-badge">*</span>
          </>
        ),
        subtitle: <>*excluding Top 15% on Total Score Leaderboard</>,
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#FFA178',
        rewardItems: [
          {
            key: 'board1-reward3-1',
            img: RewardDetailRTM10,
            name: 'Realm Ticket Miles',
          },
        ],
      },
    ],
  },
  {
    key: 'board2',
    className: 'event-totalscore-board',
    title: 'RTM - Total Score Leaderboard',
    subtitle: (
      <>
        The total score accumulated in <strong>all previous games</strong>
      </>
    ),
    items: [
      {
        key: 'board2-reward1',
        title: (
          <>
            Top <br />
            <span>15</span>%<span className="event-board-badge">**</span>
          </>
        ),
        subtitle: (
          <>
            *excluding 1st - 25th on <br />
            High Score leaderboard
          </>
        ),
        rewardItems: [
          {
            key: 'board2-reward1-1',
            img: RewardDetailRTM15,
            name: 'Realm Ticket Miles',
          },
        ],
      },
    ],
  },
];

const EventRewardDetail = () => {
  const highScoreLeaderBoard = useMemo(() => {
    const list = [];
    boardItems.forEach((board) => {
      list.push(
        <EventBoard
          key={board.key}
          className={board?.className || ''}
          title={board.title}
          subtitle={board?.subtitle || ''}
          txtColor={board?.txtColor || ''}
          bgColor={board?.bgColor || ''}
          items={board.items}
        />
      );
    });
    return list;
  }, []);

  const rtmHighScoreLeaderBoard = useMemo(() => {
    const list = [];
    rtmBoardItems.forEach((board) => {
      list.push(
        <EventBoard
          key={board.key}
          className={board?.className || ''}
          title={board.title}
          subtitle={board?.subtitle || ''}
          txtColor={board?.txtColor || ''}
          bgColor={board?.bgColor || ''}
          items={board.items}
          center
        />
      );
    });
    return list;
  }, []);

  return (
    <>
      <div className="event-section event-reward-detail">
        <h2 className="event-section-title">Reward Detail</h2>
        <div className="event-section-content">{highScoreLeaderBoard}</div>
        <p className="event-reward-detail-note">
          Note: All winners must score 100 or higher in High Score Leaderboard
          to receive rewards.
        </p>
        <p className="event-reward-detail-note-mobile">
          Note: All winners must score 100 or higher in <br /> High Score
          Leaderboard to receive rewards.
        </p>
      </div>
      <div
        className="event-section event-reward-detail"
        style={{ marginTop: 32 }}
      >
        <div className="event-section-content">{rtmHighScoreLeaderBoard}</div>
        <p className="event-reward-detail-note-mobile">
          *excluding Top 15% on Total Score Leaderboard
          <br />
          **excluding 1st - 25th on High Score Leaderboard Note: All winners
          must score 100 or higher in High Score Leaderboard to receive rewards.
        </p>
      </div>
    </>
  );
};

export default EventRewardDetail;
