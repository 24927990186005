import React from 'react';
// import { ArrowRightIcon } from '../common/Icons';

const NavGroupLinks = ({ groups }) => {
  const content = [];

  groups.forEach((group) => {
    const countItems = group?.items.length || 0;
    const item = (
      <div className="nav-group-item" key={group.key}>
        <div className="nav-group-item-label">
          {group.label} {countItems > 1 ? `(${countItems})` : ''}
        </div>
        {group?.items && (
          <ul className="nav-group-sub-items">
            {group.items.map((groupItem) => (
              <li key={groupItem.key}>
                <a
                  href={groupItem.url}
                  title={groupItem.text}
                  target={groupItem?.target}
                >
                  {groupItem.text}
                  {/* <span className="nav-group-sub-item-icon">
                    <ArrowRightIcon aria-label="Arrow Right" />
                  </span> */}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
    content.push(item);
  });

  return (
    <div className="nav-group-items">
      {content}
      <div className="nav-group-item-experience">
        <a href="/campaign">All Experiences</a>
      </div>
    </div>
  );
};

export default NavGroupLinks;
