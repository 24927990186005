/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import {
  EVENT_REGISTRATION_STATUS,
  EVENT_RESULT_TYPE,
} from '../../utils/eventHelpers';
import TicketTinyImg from '../../assets/images/mocana/ticket-tiny.png';
import { MocaOopsIcon } from '../common/Icons';
import EventEligibleTooltip from './EventEligibleTooltip';
import EventMsg from './EventMsg';
import EventDelegate from './EventDelegate';
import { marketplaceHeroBannerLink } from '../../utils/constants';

const EventNoEligible = ({
  status,
  realms,
  handleRegister,
  error,
  delegateItem,
  delegatedList,
  handleSelectDelegate,
}) => {
  const actions = useMemo(() => {
    if (status !== EVENT_REGISTRATION_STATUS.IN_PROCESS) {
      return (
        <div className="event-sb-actions">
          <button className="btn btn-md-second btn-register-now" disabled>
            Register Now
          </button>
        </div>
      );
    }

    if (realms) {
      return (
        <div className="event-sb-or-actions">
          <p className="event-sb-or-txt">
            <span>or</span>
          </p>
          <button
            className="btn btn-md-second btn-register-with-realms"
            aria-label="Register with Realms"
            onClick={handleRegister}
          >
            Register with{' '}
            <img className="event-sb-or-img" src={TicketTinyImg} alt="Ticket" />
            x1
          </button>
          <div className="event-sb-or-note">
            <p>
              You have{' '}
              <img
                className="event-sb-or-note-img"
                src={TicketTinyImg}
                alt="Ticket"
              />{' '}
              x{realms}
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="event-sb-or-actions">
        <p className="event-sb-or-txt">
          <span>or</span>
        </p>
        <button
          className="btn btn-md-second btn-register-with-realms"
          aria-label="Register with Realms"
          disabled
        >
          Register with{' '}
          <img className="event-sb-or-img" src={TicketTinyImg} alt="Ticket" />
          x1
        </button>
        <div className="event-sb-or-note">
          <p className="event-sb-or-no-ticket">
            You have{' '}
            <img
              className="event-sb-or-note-img"
              src={TicketTinyImg}
              alt="Ticket"
            />{' '}
            x0
          </p>
          <a
            href={marketplaceHeroBannerLink}
            title="Get Realm Ticket"
            target="_blank"
            rel="noreferrer"
          >
            Get Realm Ticket
          </a>
        </div>
      </div>
    );
  }, [status, realms, handleRegister]);

  return (
    <>
      {error && (
        <EventMsg
          type={EVENT_RESULT_TYPE.ERROR}
          message={error?.msg}
          // txnHash={error?.txnHash}
        />
      )}
      <div className="event-sb-header">
        <h2 className="event-sb-title">Select a Moca to be your Agent</h2>
      </div>
      {delegatedList.length !== 0 && (
        <EventDelegate
          selectedItem={delegateItem}
          delegatedList={delegatedList}
          handleSelectDelegate={handleSelectDelegate}
        />
      )}
      <div className="event-sb-main event-no-moca">
        <MocaOopsIcon
          className="event-no-moca-icon"
          aria-label="Moca Oops Icon"
        />
        <h3>No Eligible Moca</h3>
        <div className="event-no-moca-note">
          Your Moca must complete staking
          <br /> requirement to become eligible <EventEligibleTooltip />
        </div>
      </div>
      {actions}
    </>
  );
};

export default EventNoEligible;
