import React, { useState, useEffect } from 'react';
import { useCountdown } from '../../hooks/useCountDown';
import { EVENT_TIME_STATUS } from '../../utils/eventHelpers';
import { formatDotDot } from '../../utils/textHelper';
import { env } from '../../utils/envHelpers';

import eth_icon from '../../assets/images/eth-icon.png';
import discord_icon from '../../assets/images/discord-icon.svg';
import tel_icon from '../../assets/images/telegram-icon.svg';
import twitter_icon from '../../assets/images/twitter-icon.svg';

import metamask_icon from '../../assets/images/metamask-icon.svg';

import bnb_icon from '../../assets/images/bnb-icon.png';

import solana_icon from '../../assets/images/solana-icon.png';

import poly_icon from '../../assets/images/polygon-icon.png';
import trust_icon from '../../assets/images/trust-icon.svg';

import unstop_icon from '../../assets/images/unstop-icon.svg';

import coinbase_icon from '../../assets/images/coinbase-icon.svg';
import particle_icon from '../../assets/images/particle-icon.png';
import hourglass from '../../assets/images/user-board/hourglass.svg';
import walletconnect_icon from '../../assets/images/walletconnect-icon.png';

const classTopIframe = 'right-wrapper-registration-open';

const LeaderBoardCampaign = ({
  data,
  address,
  heightIframe: height,
  statusEvent,
}) => {
  const { days, hours, minutes } = useCountdown(data?.start_date);
  const [customObject, setCustomObject] = useState(null);

  const handleSocials = (type) => {
    if (type.includes('discord')) {
      return discord_icon;
    }
    if (type.includes('twitter')) {
      return twitter_icon;
    }

    return tel_icon;
  };
  const handleChain = (chain) => {
    if (chain.includes('eth')) {
      return eth_icon;
    }
    if (chain.includes('bnb')) {
      return bnb_icon;
    }
    if (chain.includes('poly')) {
      return poly_icon;
    }
    return solana_icon;
  };
  const handleWallet = (wallet) => {
    if (wallet.includes('wallet_connect')) {
      return walletconnect_icon;
    }
    if (wallet.includes('meta_mask')) {
      return metamask_icon;
    }
    if (wallet.includes('coinbase')) {
      return coinbase_icon;
    }
    if (wallet.includes('unstoppable_domains')) {
      return unstop_icon;
    }
    if (wallet.includes('trust_wallet')) {
      return trust_icon;
    }

    return particle_icon;
  };
  const handleClickConnect = () => {
    if (address) {
      const buttonConnect = document.querySelector(
        'header .connect-select-title'
      );
      buttonConnect.click();
    } else {
      const buttonConnect = document.querySelector('header .connect.btn');
      buttonConnect.click();
    }
  };
  const formatText = (text) =>
    text ? text.replace('Week Number', '').replace('To Join', 'at') : '';

  useEffect(() => {
    if (data && data.campaign_requirements.length !== 0) {
      const customObj = data.campaign_requirements.find(
        (item) => item.type === 'custom'
      );
      setCustomObject(customObj);
    }
  }, [data]);
  if (!data) {
    return <></>;
  }
  return (
    <div className="leader-board-wrapper test1">
      <div className="left">
        <h3 className="campaign-title">Campaign Details</h3>
        <div className="line" />
        <div className="campaign-item">
          <h4 className="label">Eligible Participants</h4>
          <p>
            {data.is_public
              ? 'Available to All'
              : 'Moca Holders Fulfill Staking Requirements'}
          </p>
        </div>
        {!data.is_public && data.campaign_requirements && customObject && (
          <div className="campaign-item">
            <h4 className="label">
              {formatText(customObject?.custom_field_config.message)}:
            </h4>
            <p>{customObject?.custom_field_config.value}</p>
          </div>
        )}
        <div className="campaign-item">
          <h4 className="label">Chain</h4>
          <p className="chain">
            <img src={handleChain(data?.chain)} alt="" />
            <span>{data?.chain} Chain</span>
          </p>
        </div>
        <div className="campaign-item">
          <h4 className="label">Wallet Requirements</h4>
          <ul className="campaign-wallet">
            <li>
              <img src={handleWallet('wallet_connect')} alt="wallet" />
            </li>
            <li>
              <img src={handleWallet('meta_mask')} alt="wallet" />
            </li>
            <li>
              <img src={handleWallet('coinbase')} alt="wallet" />
            </li>
          </ul>
        </div>
        <div className="campaign-item">
          <h4 className="label">Description</h4>
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
        <div className="campaign-item">
          <h4 className="label">Steps to Register</h4>
          <ul>
            <li>Connect a Wallet</li>
            <li>
              <span>Connect Social Accounts and Complete Tasks</span>
              <ul className="campaign-item-socials">
                {data.required_social_media.map((item, index) => (
                  <li key={index}>
                    <img src={handleSocials(item.type)} alt={item.type} />
                    <span>{item.type}</span>
                  </li>
                ))}
              </ul>
            </li>
            <li>Enter Email to Get Campaign Update</li>
          </ul>
        </div>
      </div>

      <div className="right">
        <div className={classTopIframe}>
          {statusEvent === EVENT_TIME_STATUS.NOT_STARTED && height && (
            <div className={`${classTopIframe}_time`}>
              <span>Registration Opens On: </span>
              <h4>
                <img src={hourglass} alt="time" />
                {`${days}D ${hours}H ${minutes}M`}
              </h4>
            </div>
          )}

          {statusEvent === EVENT_TIME_STATUS.LIVE && !address && (
            <div className={`${classTopIframe}_wrapper-parent`}>
              <h2 className="title-connect">Complete Steps to Register</h2>
              <div className={`${classTopIframe}_wrapper`}>
                <div
                  className={`${classTopIframe}_btn-connect connect-wrapper`}
                >
                  <span className="title">Connect Wallet</span>
                  <button
                    className="btn-connect connect btn"
                    onClick={handleClickConnect}
                  >
                    {formatDotDot(address) || 'Connect Wallet'}
                  </button>
                </div>
                <p className="content">
                  Connect with hot wallet. Use{' '}
                  <a href="https://delegate.cash/">delegate.cash </a>to delegate
                  your cold wallet to hot wallet in order to register with your
                  cold wallet Mocas
                </p>
              </div>
            </div>
          )}
        </div>
        {window.screen.width > 768 ||
        statusEvent === EVENT_TIME_STATUS.ENDED ? (
          <iframe
            id="iframe"
            src={`${env.w3wIframeLink}${data.id}`}
            frameBorder="0"
            style={{
              width: '100%',
              height: `${height || 500}px`,
              border: 'none',
            }}
            title="Challenge"
            className={
              statusEvent !== EVENT_TIME_STATUS.NOT_STARTED ? 'active' : ''
            }
          />
        ) : null}
      </div>
    </div>
  );
};
export default LeaderBoardCampaign;
