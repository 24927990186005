import React from 'react';
import hourglass from '../../assets/images/mocana-content-header/hourglass.svg';
import { countDownMocana } from '../../hooks/useCountDownMocana';
import { showNextPeriod3Apr } from '../../utils/mocanaHelper';
import moca from '../../assets/images/mocana/moca-icon-pink.svg';

const MocanaContentHeader = () => {
  const { days, hours, minutes, seasonNumber, isZeroTime, endDate } =
    countDownMocana();
  const isHideCountdown = showNextPeriod3Apr();

  return (
    <section className="mocana-countdown-wrapper">
      <div className="content-header-left">
        <p className="content-header-left-title">
          <img src={moca} alt="" /> <span>Moca XP Rankings</span>
        </p>
        <p className="content-header-left-description">
          <span>
            {' '}
            Hold Moca during{' '}
            <span className="big-text">Weekly Staking Period, </span>{' '}
          </span>
          <span>
            {' '}
            <br /> your Moca will gain{' '}
            <span className="big-text"> 8 Moca XP</span>{' '}
          </span>
          {seasonNumber <= 2 && !isHideCountdown && !isZeroTime && (
            <span className="double-text">Double XP this week!</span>
          )}
        </p>
      </div>
      <div className="content-header-right">
        <p className="content-header-right-title">SEASON 1</p>
        <div
          className={`content-header-right-content ${
            isHideCountdown || isZeroTime ? 'hide-countdown' : 'show-countdown'
          }`}
        >
          {!isHideCountdown && !isZeroTime && (
            <div className="content-header-right-content-countdown">
              <p className="content-header-right-content-countdown-title">
                Week {seasonNumber} Ends in:
              </p>
              <p className="content-header-right-content-countdown-time">
                <img src={hourglass} alt="hourglass" />
                {days}D {hours}H {minutes}M
              </p>
            </div>
          )}
          <div className="content-header-right-content-time">
            <p className="content-header-right-content-time-title">
              Next period starts on:
            </p>
            <p className="content-header-right-content-time-time">
              {endDate} (EST)
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MocanaContentHeader;
