import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Socials } from '../components';
import Wrapper from '../components/mocana/Wrapper';
import CampaignSection from '../components/campaign/CampaignSection';
import EventHeader from '../components/event/EventHeader';
import CampaignDetail from '../components/campaign/CampaignWrapper';

const Campaign = () => {
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('id');

  useEffect(() => {
    document.body.classList = 'campaign-page';
  });

  return campaignId ? (
    <CampaignDetail />
  ) : (
    <Wrapper>
      <EventHeader />
      <Socials />
      <CampaignSection />
    </Wrapper>
  );
};
export default Campaign;
