/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  user: null,
  wallet_address: null,
  error: null,
};
const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAddress: (state, action) => {
      state.wallet_address = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
    reset: (state) => {
      state.user = null;
    },
  },
});

export const { setLoading, setUser, setAddress, setError, reset } =
  UserSlice.actions;
export default UserSlice.reducer;
