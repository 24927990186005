import React from 'react';
import DoGoodRealmItem from './DoGoodRealmItem';
import LearnRealmItem from './LearnRealmItem';
import InvestRealmItem from './InvestRealmItem';
import PlayRealmItem from './PlayRealmItem';

const RealmsContent = () => (
  <div className="realm__row">
    <LearnRealmItem />
    <InvestRealmItem />
    <PlayRealmItem />
    <DoGoodRealmItem />
  </div>
);

export default RealmsContent;
