import React from 'react';
import { HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';

const EventWhatIsTribeTooltip = () => (
  <Tooltip
    className="moca-what-tribe-tooltip"
    title={<HelpIcon />}
    content={
      <>
        <h2>What is Moca’s Tribe?</h2>
        <p>
          There are 5 Moca tribes:{' '}
          <strong>Dreamers, Builders, Angels, Connectors,</strong> and{' '}
          <strong>Neo-Capitalists</strong>.
        </p>
        <p>
          In the registration panel, your Moca tribe is displayed under its
          name.
        </p>
      </>
    }
  />
);

export default EventWhatIsTribeTooltip;
