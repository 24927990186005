import React from 'react';
import { Header } from '../header/index';
import HomeIcon from '../../assets/images/mocana/mocana-logo.svg';

const links = [
  {
    key: 'home',
    icon: HomeIcon,
    href: '/event',
    text: '',
  },
  {
    key: 'mocana',
    url: '/mocana',
    text: 'Moca XP Rankings',
  },
  {
    key: 'experiences',
    text: 'Experiences',
    groups: [
      {
        key: 'partnership',
        label: 'Partnership',
        items: [
          {
            key: 'partnership_game',
            text: 'Into the Mocaverse with Gamee',
            url: '/event?type=partnership',
          },
          {
            key: 'dus_game',
            text: 'Mocaverse x Dustland',
            url: '/dustland',
          },
        ],
      },
    ],
  },
];

const DustlandHeader = () => (
  <Header links={links} clsNameNav="nav-dashboard" />
);

export default DustlandHeader;
