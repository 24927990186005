import React from 'react';
import EventRewardList from './EventRewardList';
import RewardImg1 from '../../assets/images/mocana/reward-pool-1.svg';
import RewardImg2 from '../../assets/images/mocana/reward-pool-2.svg';
import RewardImg3 from '../../assets/images/mocana/reward-pool-3.svg';
import RewardImg4 from '../../assets/images/mocana/reward-pool-4.png';

const rewardList = [
  { key: 'reward-1', img: RewardImg1, name: 'Matic x 10,000' },
  {
    key: 'reward-2',
    img: RewardImg2,
    name: 'Mocaverse G-Bot',
    subtitle: '(Limited Edition)',
  },
  { key: 'reward-3', img: RewardImg3, name: 'Moca XP' },
  { key: 'reward-4', img: RewardImg4, name: 'Realm Ticket Mile' },
];

const EventRewardPool = () => (
  <div className="event-section event-reward-pool">
    <h2 className="event-section-title">Reward Pool</h2>
    <div className="event-section-content">
      <EventRewardList classNamePrefix="event-reward" items={rewardList} />
    </div>
  </div>
);

export default EventRewardPool;
