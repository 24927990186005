import React from 'react';
import RealmTitle from '../assets/images/realms.svg';
import RealmsSlider from './realms/RealmsSlider';
import RealmsContent from './realms/RealmsContent';

const Realms = () => (
  <section id="realms" className="realms-section orbit-rings-no-outer-rings">
    <img src={RealmTitle} alt="" />
    <div className="realm__block-info">
      Hover over the realms to learn more!
    </div>
    <RealmsSlider />
    <RealmsContent />
  </section>
);

export default Realms;
