import React from 'react';
import MyMoca from './MyMoca';

const UserBoardContent = ({ address }) => (
  <div className="landing-content-wrapper">
    <div className="tab-item">
      <MyMoca address={address} />
    </div>
  </div>
);
export default UserBoardContent;
