import React, { useState, useEffect } from 'react';

import arrowDown from '../../assets/images/arrow-down.svg';
import SoftStakeTooltip from './SoftStakeTooltip';
import XPTooltip from './XPTooltip';
// import PaginationMyMoca from './PaginationMyMoca';
import BoosterTooltip from './BoosterTooltip';
import TribeBoostTooltip from './TribeBoostTooltip';
import WhaleBoostTooltip from './WhaleBoostTooltip';
import { fetchMocaXpDetail } from '../../api/fetchData';
import { XP_SOURCE_NAME } from '../../utils/userBoardHelpers';

const TableMyMocaXpSection = ({
  data,
  iSortBy = 'date',
  isAsc = false,
  xpSource,
}) => {
  const [activeItemId, setActiveItemId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const [activeItemBottom, setActiveItemBottom] = useState(null);

  const [sortedData, setSortedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [sortBy, setSortBy] = useState(iSortBy);
  const [defaultSort, setDefaultSort] = useState(isAsc);
  const handeSort = (type) => {
    setSortBy(type);
    setDefaultSort(!defaultSort);
    setActiveItemId(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  };
  const arrayToObj = (array) => {
    const items = array.reduce((acc, cur) => {
      const { xp_sources, ...rest } = cur;
      acc[xp_sources] = rest;
      return acc;
    }, {});
    return items;
  };

  const getXpSourceName = (xp_sources) => {
    if (xp_sources === 1) {
      return XP_SOURCE_NAME.STAKING;
    }
    if (xp_sources === 9) {
      return XP_SOURCE_NAME.SOCIAL_XP;
    }
    return XP_SOURCE_NAME.INTO_GAME;
  };
  // fucntion call api details
  const handeDetails = async (item) => {
    setLoading(true);
    if (item?.moca_id) {
      const resultDetail = await fetchMocaXpDetail({
        id: item.moca_id,
        week: item.week,
        season: item.season,
        xp_sources: item.xp_sources,
      });

      if (resultDetail) {
        setActiveItemBottom(arrayToObj(resultDetail.results));
      }
      setLoading(false);
    }
  };
  const toggleHistory = (itemId, item) => {
    setActiveItemId(itemId === activeItemId ? null : itemId);
    setActiveItem(itemId === activeItemId ? null : item);

    if (itemId !== activeItemId) {
      handeDetails(item);
    }
  };
  const handleMouseEnter = (event) => {
    const parent = event.target.closest('.detail-parent-tooltip');
    if (parent) {
      parent.classList.add('active');
    }
  };

  const handleMouseLeave = (event) => {
    const parent = event.target.closest('.detail-parent-tooltip');
    if (parent) {
      parent.classList.remove('active');
    }
  };

  useEffect(() => {
    setDefaultSort(isAsc);
    setSortBy(iSortBy);
  }, [iSortBy, isAsc]);

  useEffect(() => {
    if (data && data.length !== 0) {
      const sorted = [...data].sort((a, b) => {
        if (sortBy === 'date') {
          return defaultSort
            ? new Date(a.created_at) - new Date(b.created_at)
            : new Date(b.created_at) - new Date(a.created_at);
        }
        if (sortBy === 'xp_from') {
          return defaultSort
            ? a.xp_sources_name.localeCompare(b.xp_sources_name)
            : b.xp_sources_name.localeCompare(a.xp_sources_name);
        }
        if (sortBy === 'xp_gained') {
          return defaultSort ? a.moca_xp - b.moca_xp : b.moca_xp - a.moca_xp;
        }
        return 0;
      });
      setSortedData(sorted);
    } else {
      setSortedData([]);
    }
    setActiveItemId(null);
  }, [data, defaultSort, sortBy, iSortBy, isAsc]);

  return (
    <div className="table-container">
      <div
        className={`table-container__header ${sortBy} ${
          defaultSort ? 'asc' : 'desc'
        }`}
      >
        <div className="table-container__header--col-1">
          <span onClick={() => handeSort('date')}>Date</span>
        </div>
        <div className="table-container__header--col-2">
          <span onClick={() => handeSort('xp_from')}>
            <XPTooltip /> XP From
          </span>
        </div>
        <div className="table-container__header--col-3">
          <span onClick={() => handeSort('xp_gained')}>XP Gained</span>
        </div>
      </div>
      <div className="moca-history-list-wrapper">
        <div className="moca-history-list">
          {sortedData && sortedData.length !== 0
            ? sortedData.map((item, index) => (
                <div key={index} className="detail-parent-tooltip">
                  <div className="table-container__item">
                    <div className="table-container__item--header">
                      <div className="table-container__item--header-col-1">
                        {formatDate(item.created_at)}
                      </div>
                      <div className="table-container__item--header-col-2">
                        {getXpSourceName(item.xp_sources)}
                      </div>
                      <div className="table-container__item--header-col-3">
                        {item.moca_xp}
                      </div>
                      {item.xp_sources !== 9 && (
                        <button
                          onClick={() => toggleHistory(index, item)}
                          className={`btn-showMore  ${
                            index === activeItemId ? 'toggle' : ''
                          }`}
                        >
                          <img src={arrowDown} alt="" />
                        </button>
                      )}
                    </div>
                    <div className="table-container__item--header--mobile">
                      <div className="table-container__item--header--mobile-col-1">
                        <span className="status">
                          {getXpSourceName(item.xp_sources)}
                        </span>
                        <span className="date">
                          {formatDate(item.created_at)}
                        </span>
                      </div>
                      <div className="table-container__item--header--mobile-col-3">
                        {item.moca_xp}
                      </div>
                      {item.xp_sources !== 9 && (
                        <button
                          onClick={() => toggleHistory(index, item)}
                          className={`btn-showMore  ${
                            index === activeItemId ? 'toggle' : ''
                          }`}
                        >
                          <img src={arrowDown} alt="" />
                        </button>
                      )}
                    </div>
                    <div
                      className={`table-container__item--showMore  ${
                        index === activeItemId ? 'toggle' : ''
                      }`}
                    >
                      <p className="table-container__item--showMore-title">
                        {activeItem?.xp_sources === 1
                          ? 'XP Breakdown:'
                          : 'XP From Leaderboard'}
                      </p>
                      <ul className="table-container__item--showMore-list">
                        {loading ? (
                          <li className="table-container__item--showMore-list-item">
                            <p className="title">Loading...</p>
                            <p className="value">--</p>
                          </li>
                        ) : activeItem?.xp_sources !== 5 ? (
                          <>
                            <li className="table-container__item--showMore-list-item">
                              <p
                                className="title"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              >
                                <SoftStakeTooltip /> Soft Stake
                              </p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['1']
                                  ? `+${activeItemBottom['1'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>

                            <li className="table-container__item--showMore-list-item">
                              <p className="title">
                                <BoosterTooltip /> Rare Boost
                              </p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['2']
                                  ? activeItemBottom['2'].moca_xp >= 0
                                    ? `+${activeItemBottom['2'].moca_xp}`
                                    : `${activeItemBottom['2'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>

                            <li className="table-container__item--showMore-list-item">
                              <p className="title">
                                <TribeBoostTooltip /> Tribe Boost
                              </p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['3']
                                  ? activeItemBottom['3'].moca_xp >= 0
                                    ? `+${activeItemBottom['3'].moca_xp}`
                                    : `${activeItemBottom['3'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>
                            <li className="table-container__item--showMore-list-item">
                              <p className="title">
                                <WhaleBoostTooltip /> Whale Boost
                              </p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['4']
                                  ? activeItemBottom['4'].moca_xp >= 0
                                    ? `+${activeItemBottom['4'].moca_xp}`
                                    : `${activeItemBottom['4'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="table-container__item--showMore-list-item bold not-stake">
                              <p className="title">High Score</p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['5']
                                  ? activeItemBottom['5'].moca_xp >= 0
                                    ? `+${activeItemBottom['5'].moca_xp}`
                                    : `${activeItemBottom['5'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>

                            <li className="table-container__item--showMore-list-item bold not-stake">
                              <p className="title">Total Score</p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['6']
                                  ? activeItemBottom['6'].moca_xp >= 0
                                    ? `+${activeItemBottom['6'].moca_xp}`
                                    : `${activeItemBottom['6'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>

                            <li className="table-container__item--showMore-list-item bold not-stake">
                              <p className="title">Tribe</p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['7']
                                  ? activeItemBottom['7'].moca_xp >= 0
                                    ? `+${activeItemBottom['7'].moca_xp}`
                                    : `${activeItemBottom['7'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>
                          </>
                        )}
                      </ul>

                      {activeItem && activeItem.xp_sources === 5 && (
                        <>
                          <p className="table-container__item--showMore-title">
                            Others
                          </p>
                          <ul className="table-container__item--showMore-list">
                            <li className="table-container__item--showMore-list-item  bold">
                              <p className="title">Special Event</p>
                              <p className="value">
                                {activeItemBottom && activeItemBottom['8']
                                  ? activeItemBottom['8'].moca_xp >= 0
                                    ? `+${activeItemBottom['8'].moca_xp}`
                                    : `${activeItemBottom['8'].moca_xp}`
                                  : '--'}
                              </p>
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default TableMyMocaXpSection;
