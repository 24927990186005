/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { env } from '../utils/envHelpers';

const convertDatetoTimeZone = (date = '') => {
  if (date !== '') {
    return new Date(date).toLocaleString('en-US', {
      timeZone: 'Asia/Hong_Kong',
    });
  }
  return new Date().toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' });
};

const COUNTDOWN_START = new Date(
  convertDatetoTimeZone(env.mocanaStartDate || 'Apr 3, 2023 21:00:00 GMT+0800')
);

const SEASON_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
const useCountdown = () => {
  const [endDate, setEndDate] = useState(null);
  const [seasonNumber, setSeasonNumber] = useState(2);

  useEffect(() => {
    const calculateEndDate = () => {
      const currentTime = new Date(convertDatetoTimeZone());
      const numSeasons =
        Math.floor((currentTime - COUNTDOWN_START) / SEASON_DURATION) +
        seasonNumber +
        1;
      const nEndDate = new Date(
        COUNTDOWN_START.getTime() +
          (numSeasons - seasonNumber) * SEASON_DURATION
      );
      setEndDate(nEndDate);
      setSeasonNumber(numSeasons);
    };

    calculateEndDate();
    const timer = setInterval(calculateEndDate, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return { endDate, seasonNumber };
};

const formatTime = (time) => time.toString().padStart(2, '0');

export const countDownMocana = () => {
  const { endDate, seasonNumber } = useCountdown();

  if (!endDate) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      secondsRemaining: 0,
      seasonNumber,
      endDate,
    };
  }

  const now = new Date(convertDatetoTimeZone());
  const timeRemaining = endDate - now;
  const daysRemaining = Math.floor(timeRemaining / (24 * 60 * 60 * 1000));
  const hoursRemaining = Math.floor((timeRemaining / (60 * 60 * 1000)) % 24);
  let minutesRemaining = Math.floor((timeRemaining / (60 * 1000)) % 60);
  const secondsRemaining = Math.floor((timeRemaining / 1000) % 60);
  let isZeroTime =
    daysRemaining === 0 && hoursRemaining === 0 && minutesRemaining === 0;
  if (isZeroTime && secondsRemaining > 0) {
    minutesRemaining = 1;
    isZeroTime = false;
  }
  return {
    days: formatTime(daysRemaining),
    hours: formatTime(hoursRemaining),
    minutes: formatTime(minutesRemaining),
    secondsRemaining: formatTime(secondsRemaining),
    seasonNumber,
    endDate: `${format(endDate, 'dd MMM, h:mm')} AM`,
    isZeroTime,
  };
};
