import { ChainId } from '@thirdweb-dev/react';

const domain = process.env.REACT_APP_THIRDWEB_AUTHDOMAIN;
const desiredChainId =
  process.env.REACT_APP_THIRDWEB_NETWORK ||
  (domain === 'www.mocaverse.xyz' ? ChainId.Mainnet : ChainId.Goerli);
const envTag = domain === 'www.mocaverse.xyz' ? 'prod' : 'staging';

const contractId = process.env.REACT_APP_THIRDWEB_CONTRACT_ID || '';
// use a separate variable instead of re-using contractId
// as we might want to interact with testing contract or use different explorer
const contractUrl = process.env.REACT_APP_PUBLIC_CONTRACT_URL || '';

export const env = {
  authUrl: process.env.REACT_APP_THIRDWEB_API_URL,
  domain,
  siteUrl: process.env.REACT_APP_SITE_URL || 'https://www.mocaverse.xyz',
  desiredChainId,
  gtmId: process.env.REACT_APP_GTM_ID,
  gtmAuth: process.env.REACT_APP_GTM_AUTH,
  gtmPreview: process.env.REACT_APP_GTM_ENV,
  apiUrl: process.env.REACT_APP_THIRDWEB_API_URL,
  envTag,
  licenseIpfsUrl: process.env.REACT_APP_LICENSE_IPNS_URL || '',
  licenseReadUrl: process.env.REACT_APP_LICENSE_READ_URL || '',
  signMessage: process.env.REACT_APP_SIGN_MESSAGE || '',
  licenseMessage:
    process.env.REACT_APP_LICENSE_MESSAGE || 'I agree to the license terms',
  soldOutCondition: +process.env.REACT_APP_UNCLAIMED_SOLD_OUT || 0,
  contractId,
  contractUrl,
  targetDate: +process.env.REACT_APP_COUNTDOWN_DATE,
  mocanaStartDate: process.env.REACT_APP_MOCANA_START_DATE,
  userThumbnailUrl: process.env.REACT_APP_LEADERBOARD_USER_URL || '',
  userBoardThumbnailUrl:
    process.env.REACT_APP_BOARD_USER_URL ||
    'https://mocaverse-prod.s3.us-west-2.amazonaws.com/thumbnails/300x300/',


  w3wIframeLink: process.env.REACT_APP_NFT_MPC_LINK || '',

  eventRegistrationStartedDate:
    process.env.REACT_APP_EVENT_REGISTRATION_STARTED_DATE,
  eventRegistrationEndedDate:
    process.env.REACT_APP_EVENT_REGISTRATION_ENDED_DATE,
  eventTimeStartedDate: process.env.REACT_APP_EVENT_TIME_STARTED_DATE,
  eventTimeEndedDate: process.env.REACT_APP_EVENT_TIME_ENDED_DATE,
  campaignUrl: process.env.REACT_APP_W3W_CAMPAIGN,
  campaignKey: process.env.REACT_APP_W3W_CAMPAIGN_KEY,

  dustlandRegistrationStartedDate:
    process.env.REACT_APP_DUSTLAND_REGISTRATION_STARTED_DATE,
  dustlandRegistrationEndedDate:
    process.env.REACT_APP_DUSTLAND_REGISTRATION_ENDED_DATE,
  dustlandTimeStartedDate: process.env.REACT_APP_DUSTLAND_TIME_STARTED_DATE,
  dustlandTimeEndedDate: process.env.REACT_APP_DUSTLAND_TIME_ENDED_DATE,

  dustlandMocaJson: process.env.REACT_APP_DUSTLAND_MOCA_JSON,
  dustlandRealmJson: process.env.REACT_APP_DUSTLAND_REALM_JSON,
  dustlandTribeJson: process.env.REACT_APP_DUSTLAND_TRIBE_JSON,
};
