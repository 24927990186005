import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEventRegistrationCountDown } from '../../hooks/useCountDown';
import {
  EVENT_REGISTRATION_STATUS,
  EVENT_RESULT_TYPE,
} from '../../utils/eventHelpers';
import EventSidebarWrapper from './EventSidebarWrapper';
import EventJoinDiscord from './EventJoinDiscord';
import EventSelectMoca from './EventSelectMoca';
import EventNoMoca from './EventNoMoca';
import EventNoEligible from './EventNoEligible';
import EventResult from './EventResult';
import EventRegisterMobile from './EventRegisterMobile';
import EventJoinDiscordMobile from './EventJoinDiscordMobile';

import EventBarWrapper from './EventBarWrapper';
import Modal from '../Modal';
import {
  fetchMocaEvent,
  fetchEventRegistration,
  submitEventRegistration,
  fetchRealmBalance,
} from '../../api/fetchData';

const EVENT_ID = 1;
const STATUSAFTER17 = 'not-started';

const EventSideBar = ({ address }) => {
  const { statusRegistration: _statusRegistration } =
    useEventRegistrationCountDown();

  const statusRegistration = STATUSAFTER17 || _statusRegistration;
  const [searchParams] = useSearchParams();
  const resultStatus = searchParams.get('result') || EVENT_RESULT_TYPE.SUCCESS;
  const [showModal, setShowModal] = useState(false);
  const [selectedMocaItem, setSelectedMocaItem] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [
    result,
    // setResult
  ] = useState('');
  const [resultWithRealm, setResultWithRealm] = useState('');
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [mocaList, setMocaList] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [infoRegistration, setInfoRegistration] = useState();
  const [realmsBalance, setRealmsBalance] = useState(0);

  const handleShowConnectWalletModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleRegisterMobile = useCallback(() => {
    setShowPopup(true);
  }, []);

  const handleClickMoca = useCallback((selectedItem) => {
    setSelectedMocaItem(selectedItem);
  }, []);

  /* register moca_holder */
  const handleRegister = useCallback(async () => {
    const results = await submitEventRegistration({
      wallet: address,
      event_id: EVENT_ID,
      moca_id: selectedMocaItem.moca_id,
      registered_by: 'moca_holder',
    });

    if (results && !results.error) {
      results.is_owner = true;
      setInfoRegistration(results);
    } else {
      setError({
        msg:
          'Transaction failed. Please try again.' || results.error[0].message,
      });
    }
    return false;
  }, [selectedMocaItem]);
  /* end register moca_holder */

  /* onFetchEventRegistration realm */
  const handleRegisterWithRealms = useCallback(async () => {
    const results = await submitEventRegistration({
      wallet: address,
      event_id: EVENT_ID,
      registered_by: 'realm',
    });

    if (results && !results.error) {
      setResultWithRealm('success');
      setInfoRegistration(results);
    } else {
      setError({
        msg:
          'Transaction failed. Please try again.' || results.error[0].message,
      });
    }
    return false;
  }, [address]);
  /* end onFetchEventRegistration realm */

  /* get moca list */
  const onFetchMoca = async () => {
    const results = await fetchMocaEvent({
      event_id: EVENT_ID,
      wallet_address: address,
    });
    if (results) {
      setMocaList(results?.results);
    }
  };
  /* end get moca list */

  /* onFetchEventRegistration */
  const onFetchEventRegistration = async () => {
    const results = await fetchEventRegistration({
      event_id: EVENT_ID,
      wallet_address: address,
    });
    if (results) {
      setInfoRegistration(results);
    }
  };
  /* end onFetchEventRegistration */

  /* fetch RealmsBalanc */
  const onFetchRealmsBalance = async () => {
    const results = await fetchRealmBalance({
      event_id: EVENT_ID,
      wallet_address: address,
    });
    const balance = Number(results?.balance);
    setRealmsBalance(balance);
    setIsFetching(false);
  };
  /* end fetch RealmsBalanc */

  const onFilterMocaEligible = (data) => {
    if (data.length === 0) return data;
    const listMocaEligible = data.filter((item) => item.is_qualified);
    return listMocaEligible;
  };
  const onFilterMocaById = (data) => {
    if (mocaList && mocaList.length === 0) return data;
    const mocaId = mocaList.filter((item) => item.moca_id === data.moca_id);
    return mocaId[0];
  };

  useEffect(() => {
    if (address) {
      setShowModal(false);
      setIsFetching(true);
      onFetchMoca();
      onFetchEventRegistration();
      onFetchRealmsBalance();
    } else {
      setTimeout(() => {
        setIsFetching(false);
      }, 2000);
    }
  }, [address]);

  const renderContent = useMemo(() => {
    const cls = showPopup ? 'show-popup' : '';
    if (
      address &&
      !!infoRegistration?.registration_status &&
      infoRegistration?.registered_by !== 'moca_holder'
    ) {
      return (
        <EventSidebarWrapper
          className={`event-sb-mobile event-sb-mobile-collapse event-sb-result ${cls} ${
            isCollapsed ? 'hide' : 'show'
          }`}
        >
          <EventBarWrapper
            result=""
            resultWithRealm={
              infoRegistration?.registered_by !== 'moca_holder'
                ? 'resultWithRealm'
                : ''
            }
            resultStatus={resultStatus}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
          <EventResult
            resultStatus={resultStatus}
            selectedMocaItem={realmsBalance ? null : selectedMocaItem}
          />
        </EventSidebarWrapper>
      );
    }

    if (
      address &&
      !!infoRegistration?.registration_status &&
      infoRegistration?.registered_by === 'moca_holder' &&
      infoRegistration?.moca_id
    ) {
      return (
        <EventSidebarWrapper
          className={`event-sb-mobile event-sb-mobile-collapse event-sb-result ${cls} ${
            isCollapsed ? 'hide' : 'show'
          }`}
        >
          <EventBarWrapper
            result="success"
            resultWithRealm=""
            resultStatus="success"
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
          <EventResult
            resultStatus="success"
            selectedMocaItem={onFilterMocaById(infoRegistration)}
          />
        </EventSidebarWrapper>
      );
    }
    if (address && infoRegistration?.is_registered && mocaList.length === 0) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventNoMoca
              status={statusRegistration}
              realms={realmsBalance}
              handleRegister={handleRegisterWithRealms}
              error={error}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    if (
      address &&
      infoRegistration?.is_registered &&
      onFilterMocaEligible(mocaList).length === 0
    ) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventNoEligible
              status={statusRegistration}
              realms={realmsBalance}
              handleRegister={handleRegisterWithRealms}
              error={error}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }

    if (
      address &&
      !infoRegistration?.moca_id &&
      onFilterMocaEligible(mocaList).length !== 0
    ) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventSelectMoca
              status={statusRegistration}
              handleRegister={handleRegister}
              handleClickMoca={handleClickMoca}
              selectedMocaItem={selectedMocaItem}
              error={error}
              mocaList={onFilterMocaEligible(mocaList)}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    if (address && infoRegistration?.moca_id && mocaList.length !== 0) {
      return (
        <>
          <EventSidebarWrapper
            className={`event-sb-modal ${cls}`}
            handleCloseBtn={() => setShowPopup(false)}
          >
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
            />
            <EventSelectMoca
              status={statusRegistration}
              handleRegister={handleRegister}
              handleClickMoca={handleClickMoca}
              selectedMocaItem={selectedMocaItem}
              error={error}
              mocaList={mocaList}
            />
          </EventSidebarWrapper>
          <EventSidebarWrapper className="event-sb-mobile">
            <EventBarWrapper
              result={result}
              resultWithRealm={resultWithRealm}
              resultStatus={resultStatus}
              countDownType="fixed"
            />
            <EventRegisterMobile
              status={statusRegistration}
              handleRegister={handleRegisterMobile}
              address={address}
              handleConnect={handleShowConnectWalletModal}
            />
          </EventSidebarWrapper>
        </>
      );
    }
    return (
      <>
        <EventSidebarWrapper className="event-sb-modal event-sb-connect">
          <EventBarWrapper
            result={result}
            resultWithRealm={resultWithRealm}
            resultStatus={resultStatus}
          />
          <EventJoinDiscord />
        </EventSidebarWrapper>
        <EventSidebarWrapper className="event-sb-mobile">
          <EventBarWrapper
            result={result}
            resultWithRealm={resultWithRealm}
            resultStatus={resultStatus}
            countDownType="fixed"
          />
          <EventJoinDiscordMobile />
        </EventSidebarWrapper>
      </>
    );
  }, [
    address,
    result,
    resultWithRealm,
    selectedMocaItem,
    isFetching,
    statusRegistration,
    realmsBalance,
    showPopup,
    infoRegistration,
    mocaList,
    error,
    isCollapsed,
  ]);

  if (statusRegistration === EVENT_REGISTRATION_STATUS.ENDED) {
    return null;
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isFetching ? (
        <EventSidebarWrapper className="event-sb-modal event-sb-connect">
          <EventBarWrapper
            result={result}
            resultWithRealm={resultWithRealm}
            resultStatus={resultStatus}
          />
          <div className="loading-state" />
          <div className="loading-state h-100" />
        </EventSidebarWrapper>
      ) : (
        <>
          {showModal && <Modal toggleModal={() => setShowModal(false)} />}
          {renderContent}
        </>
      )}
    </>
  );
};

export default EventSideBar;
