import React from 'react';
import { env } from '../../utils/envHelpers';

import backHome from '../../assets/images/mocana-header/home-back.svg';
import stepArrow from '../../assets/images/mocana-header/stepArrow.svg';
import discord from '../../assets/images/mocana-header/discord-yellow.svg';
import twitter from '../../assets/images/mocana-header/twitter-yellow.svg';
import marketplace from '../../assets/images/mocana/moca_marketplace_yellow.svg';
import etherscan from '../../assets/images/mocana-header/etherscan-yellow.svg';
import mocanaLogo from '../../assets/images/mocana-header/logo-mocana.svg';
import { marketplaceHeroBannerLink } from '../../utils/constants';

const MocanaHeader = () => (
  <div className="mocana-header-wrapper">
    <a className="back-to-home" href="/">
      <img src={backHome} alt="back-to-home" />
    </a>
    <div className="mocana-tag">
      <img src={mocanaLogo} alt="mocana logo" />
    </div>
    <p className="mocana-sub-title">Earn Moca XP to Win a Chance for</p>
    <p className="mocana-title">
      Boxes & <br /> Moca Benefits
    </p>
    <p className="mocana-time">Started On: 17 Mar 09:00 EST</p>
    <div className="mocana-step">
      <div className="mocana-step-item">
        <p className="mocana-step-item-number">1</p>
        <p className="mocana-step-item-title">Hold Moca</p>
        <p className="mocana-step-item-description">
          Hold any Moca to earn Moca XP.
        </p>
        <img className="arrow-step" src={stepArrow} alt="step arrow" />
      </div>
      <div className="mocana-step-item">
        <p className="mocana-step-item-number">2</p>
        <p className="mocana-step-item-title">Earn Moca XP</p>
        <p className="mocana-step-item-description">Moca XP = Points</p>
        <img className="arrow-step" src={stepArrow} alt="step arrow" />
      </div>
      <div className="mocana-step-item">
        <p className="mocana-step-item-number">3</p>
        <p className="mocana-step-item-title">Higher Chances</p>
        <p className="mocana-step-item-description">to win prizes</p>
      </div>
    </div>
    <div className="mocana-button-check">
      <a href="#moca" className="button-check-ranking">
        Check My Ranking
      </a>
      <a
        target="_blank"
        href="https://medium.com/mocaverse/introducing-moca-xp-the-ultimate-points-system-within-mocaverse-f1ef5caddd0b"
        className="button-learn-more"
        rel="noreferrer"
      >
        Learn More
      </a>
    </div>
    <p className="mocana-description">
      All Mocas but treasury are included in the leaderboard.
    </p>
    <div className="blur-wrapper" />
    <div className="list-social-mobile">
      <ul>
        <li>
          <a href={marketplaceHeroBannerLink} target="_blank" rel="noreferrer">
            <img src={marketplace} alt="" />
          </a>
        </li>
        <li>
          <a
            href="http://discord.gg/MocaverseNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discord} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/MocaverseNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="" />
          </a>
        </li>
        <li>
          <a href={env.contractUrl} target="_blank" rel="noreferrer">
            <img src={etherscan} alt="" />
          </a>
        </li>
      </ul>
    </div>
  </div>
);
export default MocanaHeader;
