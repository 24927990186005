import React, { useEffect, useState } from 'react';
import HomeIcon from '../../assets/images/moca-home.png';
import LinkOutIcon from '../../assets/images/icon-link-out.svg';
import ArrowDownIcon from '../../assets/images/arrow-down.svg';
import Modal from '../Modal';
// import ConnectWallet from './ConnectWallet';

const DesktopNav = ({
  showDesktopNav,
  address,
  // handleGetUpdate,
  // handleDisconnect,
}) => {
  const [showModal, setShowModal] = useState(false);

  const top = showDesktopNav.display ? 0 : -1000;

  useEffect(() => {
    if (address) {
      setShowModal(false);
    }
  }, [address]);

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <nav
        style={{
          top,
        }}
        className="nav-desktop"
      >
        <div className="desktop">
          <li>
            <a
              href="/#home"
              className="home"
              onClick={() => showDesktopNav({ display: true })}
            >
              <img src={HomeIcon} alt="Mocaverse" />
            </a>
          </li>

          <li
            className="home-menu"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <a href="/#story-of-mocas">
              Mocaverse
              <img src={ArrowDownIcon} alt="Moca Dao link" />
            </a>

            <div className="menu-items">
              <a href="/#story-of-mocas">Story of The Mocas</a>
              <a href="/#realms">Realms</a>
              <a href="/#what-is-mocaverse">What is Mocaverse?</a>
              {/* <a href="/#utilities">Utilities</a> */}
              <a href="/#faq">FAQ</a>
            </div>
          </li>

          <li>
            <a
              href="https://snapshot.org/#/mocana.eth"
              target="_blank"
              rel="noreferrer"
            >
              Moca DAO
              <img src={LinkOutIcon} alt="Moca Dao link" />
            </a>
          </li>
          <li>
            <a
              href="https://marketplace.mocaverse.xyz/?utm_source=website&utm_medium=header&utm_campaign=launch"
              target="_blank"
              rel="noreferrer"
            >
              Marketplace
              <img src={LinkOutIcon} alt="Marketplace link" />
            </a>
          </li>

          {/* <li>
            <a href="/#story-of-mocas">
              Marketplace
              <img src={LinkOutIcon} alt="Marketplace link" />
            </a>
          </li> */}

          <li>
            <div className="connect-wrapper">
              <div className="connect btn">
                <a href="/mocana">Visit Mocana</a>
              </div>
            </div>
          </li>
        </div>
      </nav>
    </>
  );
};

export default React.memo(DesktopNav);
