import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import FaqTextAnimation from './FaqTitle';
import { env } from '../../utils/envHelpers';

// const FaqTextAnimation = React.lazy(() => import('./FaqTitle'));

// const githubUrl = process.env.REACT_APP_LICENSE_READ_URL || '';

const FaqContent = () => (
  <div className="faq">
    <div className="logo-block">
      <FaqTextAnimation />
    </div>
    <div className="faq-block">
      <div className="faq-scroll">
        <Accordion>
          <Accordion.Item eventKey="0" className="questions">
            <Accordion.Header className="btn-wrap">
              What is Mocaverse?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Mocaverse is the membership NFT collection for Animoca Brands’
                  extraordinary family of companies, projects, investments,
                  shareholders, and partners.
                </p>
                <p>
                  This collection will represent our values surrounding Web3 and
                  digital property rights, as well as our one-of-a-kind
                  ecosystem.
                </p>
                <p>
                  We wish to express our deepest appreciation for the immense
                  support of our friends and family by inviting them to this
                  exclusive membership experience. We simply would not be where
                  we are without them.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="questions">
            <Accordion.Header className="btn-wrap">
              Who are the Mocas?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  The Mocas are a lively community of beings that live in the
                  Mocaverse. There are 5 Moca tribes: Dreamers, Builders,
                  Angels, Connectors, and Neo-Capitalists.
                </p>
                <p>
                  While the Mocas may have their own special abilities, they are
                  most empowered when they work together. The real magic happens
                  when everyone unites for a greater purpose.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="questions">
            <Accordion.Header className="btn-wrap">
              What are the utilities of Mocaverse?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Holders will be able to Learn, Play, Do Good, and Build
                  together. Utilities will be co-created by our communities,
                  enjoyed by our communities, and powered by Animoca Brands.
                </p>
                <p>
                  Our utility offerings will be diverse, with just a few
                  examples below:
                </p>
                <p>
                  Learn: Masterclasses and AMAs with industry leaders,
                  educational research insights
                </p>
                <p>
                  Play: Exclusive game passes, access to unique in-game assets
                  and airdrops
                </p>
                <p>
                  Do Good: Opportunities to make meaningful contributions
                  towards social causes
                </p>
                <p>
                  Build: Access to resources and tools for builders in the Web3
                  space
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="questions">
            <Accordion.Header className="btn-wrap">
              Who is eligible to mint?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Mocaverse NFTs are reserved exclusively for friends and family
                  of Animoca Brands, including the following eligible parties:
                </p>
                <ol>
                  <li>Animoca Brands’ institutional and retail investors</li>
                  <li>Employees of Animoca Brands and its subsidiaries</li>
                  <li>
                    Management teams of companies in Animoca Brands’ Web3
                    investment portfolio
                  </li>
                  <li>Partners of Animoca Brands</li>
                  <li>Mocalist members</li>
                </ol>
                <p>
                  All eligible parties will receive a free mint quota and a paid
                  mint quota. Parties in categories 1-4 should already have
                  received an email from{' '}
                  <a
                    href="mailto:mocaverse@animocabrands.com"
                    title="mocaverse@animocabrands.com"
                  >
                    mocaverse@animocabrands.com
                  </a>{' '}
                  to submit their wallet address for the minting event. Learn
                  more about category 5 eligibility in our{' '}
                  <a
                    href="https://medium.com/mocaverse/mocaverse-announces-full-eligibility-criteria-and-mocalist-registration-event-27fcbcdb77"
                    title="Medium article"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Medium article
                  </a>
                  .
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className="questions">
            <Accordion.Header className="btn-wrap">
              When is the mint date, and what is the collection size and mint
              price?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  The mint date for Mocaverse is 6 March (5pm EST / 10pm UTC / 7
                  March 6am HKT)! Mocaverse comprises 8,888 NFTs of characters
                  called Mocas, each belonging to a tribe. Eligible parties will
                  receive a free mint quota and a paid mint quota.
                </p>
                <p>
                  The mint price will be 0.138 ETH (or equivalent) per paid NFT
                  mint.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className="questions">
            <Accordion.Header className="btn-wrap">
              What tokens will be accepted for payment?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Eligible parties may mint their NFTs using the following
                  tokens on Ethereum Mainnet (ERC-20):
                </p>
                <ul>
                  <li>$ETH</li>
                  <li>$USDC</li>
                  <li>$APE</li>
                  <li>$SAND</li>
                  <li>$ASTRAFER</li>
                  <li>$BONDLY</li>
                  <li>$GMEE</li>
                  <li>$REVV</li>
                  <li>$TOWER</li>
                  <li>$PRIMATE</li>
                  <li>$DOSE</li>
                  <li>$PROS</li>
                  <li>$QUIDD</li>
                </ul>
                <p>
                  Kindly ensure there will also be sufficient ETH in the wallet
                  for gas fees.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className="questions">
            <Accordion.Header className="btn-wrap">
              May I mint with a delegated wallet instead of my hardware wallet?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Only users who have previously registered their delegated
                  wallet request through the Mocaverse Discord will be able to
                  mint through their Warm.xyz or Delegate.cash delegated wallet.
                  Please connect and mint using your delegated wallet.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="7" className="questions">
            <Accordion.Header className="btn-wrap">
              What is the allocation of Mocaverse NFTs?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  The approximate allocation of Mocaverse NFTs will be as
                  follows:
                </p>
                <p>
                  Company mint (company reserve and DAO): 888 NFTs
                  <br />
                  Public mint (Investors, Employees, Portfolio Companies,
                  Partners, Mocalist Community): 8,000 NFTs
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="8" className="questions">
            <Accordion.Header className="btn-wrap">
              Will minting be done in phases? How does the minting mechanism
              work?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  The Mocaverse mint starts on 6 March (5pm EST / 10pm UTC / 7
                  March 6am HKT), and will last for 24 hours. The MocaFam is
                  encouraged to mint in the first 4 hours - Read more to find
                  out why!
                </p>
                <p>
                  For the first 4 hours, allowlisted parties will be able to
                  freely mint their Mocas with 0% overallocation. 888 Mocas will
                  be minted to the Treasury before the minting window is opened.
                  8000 Mocas will be allocated to the minting pool.
                </p>
                <p>
                  At the 5th hour, a Moca wormhole will be opened: Mocas will be
                  progressively minted to the Treasury on an hourly basis. 100
                  Mocas will be minted hourly and teleported at first, and
                  increase at increments of 100 Mocas every 3 hours. This is
                  teleportation schedule:
                </p>
                <ul>
                  <li>1st 3 Hours: 100 Mocas / Hour</li>
                  <li>2nd 3 Hours: 200 Mocas / Hour</li>
                  <li>3rd 3 Hours: 300 Mocas / Hour</li>
                  <li>4th 3 Hours: 400 Mocas / Hour</li>
                </ul>
                <p>
                  This minting mechanism will continue until the full 8,888
                  Mocas have been minted. So, there will be a minimum reserve of
                  888 NFTs, and a maximum reserve of 3,888 NFTs. The Treasury
                  will help us onboard future investors, portfolio companies,
                  partners, and employees into the Animoca Brands ecosystem as
                  we continue to scale!
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9" className="questions">
            <Accordion.Header className="btn-wrap">
              What can I do with a Realm Ticket NFT? How can I check if I won a
              Mocalist spot?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Eligible parties received their Realm Ticket NFT(s) via an
                  airdrop based on their holdings of Animoca Brands ecosystem
                  tokens and NFTs. The Realm Ticket NFT(s) was airdropped
                  directly to eligible parties’ cryptocurrency wallets. Parties
                  who were not eligible for the Realm Ticket NFT airdrop may
                  obtain the Realm Ticket NFTs from secondary markets.
                </p>
                <p>
                  Each Realm Ticket NFT counted as an individual entry for an
                  opportunity to win a spot on the Mocalist for minting
                  eligibility. On 2 February, there was a snapshot of Realm
                  Ticket NFT holders. Mocalist Members were randomly selected
                  from this pool of Realm Ticket NFT holders. Results were
                  posted on{' '}
                  <a
                    href="https://github.com/MocaverseNFT/mocaverse-realm-ticket-winner-selection/blob/round2/winners_round2.md"
                    target="_blank"
                    title="Github"
                    rel="noreferrer"
                  >
                    Github
                  </a>
                  .
                </p>
                <p>
                  Mocalist Members will be added to the minting allowlist, with
                  eligibility for 1 free mint and 1 paid mint.
                </p>
                <p>
                  For Realm Ticket NFT holders who were not selected for the
                  Mocalist, do not worry! Realm Ticket NFT holders are still a
                  part of the Mocaverse family, and selected experiences will be
                  made available for these holders in the future.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10" className="questions">
            <Accordion.Header className="btn-wrap">
              By minting this NFT, what license terms do I agree to?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  By minting a Mocaverse NFT, users will enter a License
                  Agreement that grants them a license for limited commercial
                  use of their NFT! As outlined in the License Agreement,
                  holders would be entitled to a “non-exclusive,
                  non-transferrable, sublicensable, universe-wide, royalty-free
                  license to use the Licensed NFT Artwork in any and all formats
                  and media.” This includes the ability for commercialisation of
                  the NFT.
                </p>
                <p>
                  To learn more about the license terms, we invite our Mocas to
                  view the License Agreement contents at our{' '}
                  <a
                    href={env.licenseReadUrl}
                    target="_blank"
                    title="GitHub repository"
                    rel="noreferrer"
                  >
                    GitHub repository
                  </a>
                  ! We strive for creator rights and transparency.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  </div>
);

export default FaqContent;
