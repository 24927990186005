import React from 'react';
// import { formatDotDot } from '../../utils/textHelper';
import { EVENT_RESULT_TYPE } from '../../utils/eventHelpers';
import { ErrorMsgIcon, SuccessMsgIcon } from '../common/Icons';

// const EventMsg = ({ type, message, txnHash = '' }) => (
const EventMsg = ({ type, message }) => (
  <div className={`event-main-msg-wrapper ${type}`}>
    {type === EVENT_RESULT_TYPE.ERROR && (
      <ErrorMsgIcon className="event-main-msg-icon" aria-label="warning-icon" />
    )}
    {type === EVENT_RESULT_TYPE.SUCCESS && (
      <SuccessMsgIcon
        className="event-main-msg-icon"
        aria-label="success-icon"
      />
    )}
    <div className="event-main-msg">
      <div>{message}</div>
      {/* {txnHash && (
        <a
          className="txn-hash-link"
          href="/event"
          title={txnHash}
          target="_blank"
        >
          Txn Hash: {formatDotDot(txnHash, '...', 6, 4)}
        </a>
      )} */}
    </div>
  </div>
);

export default EventMsg;
