import React from 'react';

const Tooltip = ({ className, title, content }) => (
  <div className={`moca-tooltip ${className}`}>
    <span className="moca-tooltip-label">{title}</span>
    <div className="moca-tooltip-content"> {content}</div>
  </div>
);

export default Tooltip;
