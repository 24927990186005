export const sanitizeInput = (input) => {
  // Remove any potential script tags and other potentially harmful code
  const sanitizedInput = input.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>|#/gi,
    ''
  );
  // Replace any potentially harmful characters with their HTML entities
  const safeInput = sanitizedInput.replace(/[&<>"'/]/g, (char) => {
    switch (char) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '"':
        return '&quot;';
      case "'":
        return '&#x27;';
      case '/':
        return '&#x2F;';
      default:
        return char;
    }
  });
  return safeInput;
};

const convertDatetoTimeZone = (date = '') => {
  if (date !== '') {
    return new Date(date).toLocaleString('en-US', {
      timeZone: 'Asia/Hong_Kong',
    });
  }
  return new Date().toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' });
};

// takes the current time and checks to see if it's after March 31, 2023, 09:00 EST. If that's the case, the function will return True and return False after April 3, 2023, 09:00 EST
export const showNextPeriod3Apr = () => {
  const currentDate = new Date(convertDatetoTimeZone());
  const startDate = new Date(
    convertDatetoTimeZone('March 31, 2023 21:00:00 GMT+0800')
  );
  const endDate = new Date(
    convertDatetoTimeZone('April 3, 2023 21:00:00 GMT+0800')
  );
  if (currentDate >= startDate && currentDate < endDate) {
    return true;
  }

  return false;
};
