import { configureStore } from '@reduxjs/toolkit';
import dataSlice from './data/dataSlice';
import myMocaSlice from './my-moca/myMocaSlice';
import userSlice from './user/userSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    data: dataSlice,
    myMoca: myMocaSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
