export const formatDotDot = (text, dot = '..', first = 5, last = 4) => {
  const result =
    text && text.length > 11
      ? `${text.substring(0, first)}${dot}${text.substring(
          text.length - last,
          text.length
        )}`
      : text;
  return result;
};

export const formatNumber = (number) => {
  if (number < 10) return `0${number}`;
  return number;
};

export const isPastDay = (date) => {
  const today = new Date();
  const inputDate = new Date(date);

  return inputDate < today;
};
