import React from 'react';
import Marketplace from '../../assets/images/mocana/moca_marketplace.svg';
import { marketplaceHeroBannerLink } from '../../utils/constants';

const IntroSoldout = () => (
  <>
    <p className="home-title">Step into the new Mocaverse marketplace</p>
    <div className="sold-out-info">
      <p>Strap in, it's a wild shopping playground adventure!</p>
      <p className="visit-marketplace">
        <a
          href={marketplaceHeroBannerLink}
          target="_blank"
          rel="noreferrer"
          title="opensea collection"
        >
          <img
            src={Marketplace}
            alt="icon-opensea"
            className="icon-opensea-btn"
          />
          Visit Marketplace
        </a>
      </p>
    </div>
  </>
);

export default IntroSoldout;
