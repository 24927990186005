// shd work for both /quota & /eligibility
export const checkIsAlreadyMinted = (eligibility) => {
  if (!eligibility) {
    return false;
  }
  const remainingFree = +(eligibility?.quotas?.remaining?.free || 0);
  const remainingPaid = +(eligibility?.quotas?.remaining?.paid || 0);
  const totalFree = +(eligibility?.quotas?.total?.free || 0);
  const totalPaid = +(eligibility?.quotas?.total?.paid || 0);
  return remainingFree + remainingPaid !== totalFree + totalPaid;
};

export const checkIsMintBtnDisabled = ({
  address,
  quotas,
  isInEligibleToMint,
  networknNotMatched,
}) =>
  address &&
  (!quotas || quotas?.errors || isInEligibleToMint || networknNotMatched);

export const checkIsInEligibleToMint = ({ quotas, address }) =>
  !!quotas?.errors &&
  quotas.errors[0]?.key === 'ELIGIBLILITY_MISSING' &&
  !!address;

export const checkIsMintBtnDisplayed = ({
  address,
  user,
  quotas,
  eligibility,
}) => {
  if (user.isLoading) {
    return false;
  }
  if (!address) {
    return true;
  }

  // eligible from quotas but /eligibility not yet loaded
  if (!eligibility) {
    return false;
  }

  const isInEligibleToMint = checkIsInEligibleToMint({
    quotas,
    address: user?.user?.address,
  });

  return !!(user.user && quotas && (!quotas?.errors || !isInEligibleToMint));
};
