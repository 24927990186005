import React, { useEffect, useState } from 'react';
import moment from 'moment';

import CampaignOdysseyItem from './CampaignOdysseyItem';
import CampaignGiveawayItem from './CampaignGiveawayItem';

const CampaignList = ({ campaignData, isLive }) => {
  const itemsPerPage = 15;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const currentPage = page;
  const [isOpen, setIsOpen] = useState(false);
  const calculatePage = (number) => Math.ceil(number / itemsPerPage);
  const options = Array.from({ length: calculatePage(count) }, (_, i) => i + 1);

  const [selectedOption, setSelectedOption] = useState(1);
  const toggling = () => setIsOpen(!isOpen);

  const lastItem = options[options.length - 1];
  options.splice(options.length - 1, 1);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setPage(Number(value));
    setOffset((Number(value) - 1) * itemsPerPage);
    setIsOpen(false);
  };
  const onPrevClicked = () => {
    if (selectedOption === 1) return;
    setSelectedOption(selectedOption - 1);
    setPage(selectedOption - 1);
    setOffset((selectedOption - 2) * itemsPerPage);
  };
  const onNextClicked = () => {
    if (selectedOption >= calculatePage(count)) return;
    setSelectedOption(selectedOption + 1);
    setPage(selectedOption + 1);
    setOffset(selectedOption * itemsPerPage);
  };
  useEffect(() => {
    if (selectedOption !== currentPage) {
      setSelectedOption(currentPage);
    }
  }, [currentPage]);

  const CampaignGiveawayList = ({ isLive }) =>
    campaignData.map((item, idx) => {
      return <CampaignGiveawayItem key={item.id} item={item} isLive={isLive} />;
    });
  return (
    <section className="campaign-list-wrapper">
      <ul className="campaign-list">
        {/* <CampaignOdysseyItem registered={false} /> */}
        <CampaignGiveawayList isLive={isLive} />
        {/* <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-live-countdown">
              <img src={hourglass} alt="" />
              <span>6D 20H 34M</span>
            </p>
            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward2} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward3} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward4} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item last-item">
                  <span>+3</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-registered">
              <div className="campaign-list__item--footer-registered--moca">
                <div className="campaign-list__item--footer-registered--moca-thumbnail">
                  <img src={moca} alt="" />
                </div>
                <span className="campaign-list__item--footer-registered--moca-id">
                  Moca: #1213
                </span>
              </div>
              <div className="campaign-list__item--footer-registered--status">
                <div className="campaign-list__item--footer-registered--status-joined">
                  <img src={joinedIcon} alt="" />
                  <span>Joined</span>
                </div>
              </div>
            </div>
          </div>
          <div className="campaign-list__item--live">
            <div className="circle" />
            <span>Live</span>
          </div>
        </li>
        <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-live-countdown">
              <img src={hourglass} alt="" />
              <span>6D 20H 34M</span>
            </p>

            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward2} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward3} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward4} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item last-item">
                  <span>+3</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-registered">
              <div className="campaign-list__item--footer-registered--moca">
                <div className="campaign-list__item--footer-registered--moca-thumbnail">
                  <img src={moca} alt="" />
                </div>
                <span className="campaign-list__item--footer-registered--moca-id">
                  Moca: #1213
                </span>
              </div>
              <div className="campaign-list__item--footer-registered--status">
                <div className="campaign-list__item--footer-registered--status-joined">
                  <img src={joinedIcon} alt="" />
                  <span>Joined</span>
                </div>
              </div>
            </div>
          </div>
          <div className="campaign-list__item--live">
            <div className="circle" />
            <span>Live</span>
          </div>
        </li>
        <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-ended">
              12 Mar 00:00 - 19 Mar 00:00 UTC
            </p>
            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward3} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward4} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-registered">
              <div className="campaign-list__item--footer-registered--moca">
                <div className="campaign-list__item--footer-registered--moca-thumbnail">
                  <img src={moca} alt="" />
                </div>
                <span className="campaign-list__item--footer-registered--moca-id">
                  Moca: #1213
                </span>
              </div>
              <div className="campaign-list__item--footer-registered--status">
                <div className="campaign-list__item--footer-registered--status-won">
                  <img src={wonIcon} alt="" />
                  <span>You won</span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-ended">
              12 Mar 00:00 - 19 Mar 00:00 UTC
            </p>
            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward3} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward4} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-registered">
              <div className="campaign-list__item--footer-registered--moca">
                <div className="campaign-list__item--footer-registered--moca-thumbnail">
                  <img src={moca} alt="" />
                </div>
                <span className="campaign-list__item--footer-registered--moca-id">
                  Moca: #1213
                </span>
              </div>
            </div>
          </div>
        </li>
        <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-live-countdown">
              <img src={hourglass} alt="" />
              <span>6D 20H 34M</span>
            </p>

            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>

                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward3} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward4} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-registered">
              <div className="campaign-list__item--footer-registered--moca">
                <div className="campaign-list__item--footer-registered--moca-thumbnail">
                  <img src={moca} alt="" />
                </div>
                <span className="campaign-list__item--footer-registered--moca-id">
                  Moca: #1213
                </span>
              </div>
            </div>
          </div>
        </li>
        <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-ended">
              12 Mar 00:00 - 19 Mar 00:00 UTC
            </p>
            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward3} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward4} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-registered">
              <div className="campaign-list__item--footer-registered--moca">
                <div className="campaign-list__item--footer-registered--moca-thumbnail">
                  <img src={moca} alt="" />
                </div>
                <span className="campaign-list__item--footer-registered--moca-id">
                  Moca: #1213
                </span>
              </div>
              <div className="campaign-list__item--footer-registered--status">
                <div className="campaign-list__item--footer-registered--status-won">
                  <img src={wonIcon} alt="" />
                  <span>You won</span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-ended">
              12 Mar 00:00 - 19 Mar 00:00 UTC
            </p>
            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward2} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-registered">
              <div className="campaign-list__item--footer-registered--moca">
                <div className="campaign-list__item--footer-registered--moca-thumbnail">
                  <img src={moca} alt="" />
                </div>
                <span className="campaign-list__item--footer-registered--moca-id">
                  Moca: #1213
                </span>
              </div>
            </div>
          </div>
        </li>
        <li className="campaign-list__item">
          <div className="campaign-list__item--header">
            <div className="line-bottom">
              <img src={line} alt="" />
            </div>
            <div className="arc-thumbnail">
              <img src={ArcLogo} alt="" />
            </div>
          </div>
          <div className="campaign-list__item--content">
            <p className="campaign-list__item--content-title">
              Into the Mocaverse with GAMEE
            </p>
            <p className="campaign-list__item--content-ended">
              12 Mar 00:00 - 19 Mar 00:00 UTC
            </p>
            <div className="campaign-list__item--content-reward">
              <p className="campaign-list__item--content-reward--title">
                Rewards:
              </p>
              <ul className="campaign-list__item--content-reward--list">
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward1} alt="" />
                </li>
                <li className="campaign-list__item--content-reward--list-item">
                  <img src={reward3} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="campaign-list__item--footer">
            <div className="campaign-list__item--footer-didnt-register">
              <img src={didntRegister} alt="" />
              <span>You didn’t register</span>
            </div>
          </div>
        </li> */}
      </ul>
      {/* <div className="campaign_pagination">
        <span
          className="campaign_pagination--prev"
          onClick={() => onPrevClicked()}
        >
          <img src={iconNext} alt="" />
        </span>

        <span className="campaign_pagination--label">Page</span>
        <div className="campaign_pagination--select">
          <div onClick={toggling}>{selectedOption || 1}</div>
          {isOpen && (
            <div className="campaign_pagination--select--option">
              <ul>
                {options.map((option) => (
                  <li onClick={onOptionClicked(option)} key={option}>
                    {option}
                  </li>
                ))}
              </ul>
              <div className="last-item" onClick={onOptionClicked(lastItem)}>
                {lastItem}
              </div>
            </div>
          )}
        </div>
        <span className="campaign_pagination--label">
          of {calculatePage(count)}
        </span>
        <span
          className="campaign_pagination--next"
          onClick={() => onNextClicked()}
        >
          <img src={iconNext} alt="" />
        </span>
      </div> */}
    </section>
  );
};
export default CampaignList;
