/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useSDK } from '@thirdweb-dev/react';
import { submitLicense } from '../api/fetchData';
import { ERROR_MESSAGES } from '../utils/errorsHelper';
import { env } from '../utils/envHelpers';

export const useLicense = (eligibility) => {
  const sdk = useSDK();
  const [signature, setSignature] = useState(null);

  const signLicense = async () => {
    let sig = await sdk?.wallet.sign(env.signMessage).catch((e) => ({
      error: true,
      info: ERROR_MESSAGES.LICENSE_SIGNATURE_REJECTED,
    }));
    if (!sig?.error) {
      setSignature(sig);
      if (!eligibility.license_signature) {
        const result = await submitLicense(sig);
        if (result?.errors) {
          setSignature(null);
          sig = { error: true, info: result.errors };
        }
      }
    }
    return sig;
  };

  return { signature, signLicense };
};
