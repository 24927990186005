/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { formatDotDot } from '../../utils/textHelper';

const EventDelegate = ({
  selectedItem,
  delegatedList,
  handleSelectDelegate,
}) => {
  const [showList, setShowList] = useState(false);

  const handleSelect = (i) => {
    setShowList(false);
    handleSelectDelegate(i);
  };
  return (
    <>
      {delegatedList.length !== 0 && (
        <div className="event-sb-delegate">
          <span>
            <b>{delegatedList.length}</b> total wallets detected
          </span>
          <div
            className="event-sb-delegate-select"
            onClick={() => setShowList(!showList)}
          >
            <div className="selected">
              {formatDotDot(selectedItem?.address)}
              <span>{selectedItem?.type} Wallet</span>
            </div>
            {showList && (
              <div className="event-sb-delegate-menu">
                {delegatedList.map((i, index) => (
                  <div
                    className={
                      i.address === selectedItem.address
                        ? 'active selected'
                        : 'selected'
                    }
                    key={index}
                    onClick={() => handleSelect(i)}
                  >
                    {formatDotDot(i.address)} <span>{i.type} Wallet</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EventDelegate;
