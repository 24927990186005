import React, { useEffect, useState } from 'react';
import close from '../../assets/images/close.svg';

const stopPropagation = (e) => {
  e.stopPropagation();
};

const ModalWrapper = ({
  className,
  outside = true,
  closeWrap = true,
  onClose,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);

  const cls = isOpen ? 'open' : '';

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    const timer = setTimeout(() => setOpen(true), 300);
    return () => clearTimeout(timer);
  }, []);

  if (outside && closeWrap) {
    return (
      <div
        className={`modal-wrapper ${className} ${cls}`}
        onClick={handleClose}
      >
        <div className="outer" onClick={stopPropagation}>
          <div className="inner">
            <div className="content">
              <button className="close" onClick={handleClose}>
                <img src={close} alt="close" className="close-btn-modal" />
              </button>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (!outside && closeWrap) {
    return (
      <div className={`modal-wrapper ${className} ${cls}`}>
        <div className="outer" onClick={stopPropagation}>
          <div className="inner">
            <div className="content">
              <button className="close" onClick={onClose}>
                <img src={close} alt="close" className="close-btn-modal" />
              </button>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={`modal-wrapper ${className} ${cls}`}>
      <div className="outer">
        <div className="inner">
          <div className="content">
            <button className="close" onClick={onClose}>
              <img src={close} alt="close" className="close-btn-modal" />
            </button>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
