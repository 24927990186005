import React, { useEffect, useState } from 'react';
import Wrapper from '../components/mocana/Wrapper';
import MocaXp from '../components/mocana/MocaXp';
import RealmMiles from '../components/mocana/RealmMiles';
import MocanaHeader from '../components/mocana/MocanaHeader';
import { Socials } from '../components';
import MocanaContentHeader from '../components/mocana/MocanaContentHeader';
import RealmMilesHeader from '../components/mocana/RealmMilesHeader';
// import MocanaEvent from '../components/event/MocanaEvent';
import EventHeader from '../components/event/EventHeader';
import Tabs from '../components/mocana/Tabs';
import moca from '../assets/images/mocana/moca-icon-pink.svg';
import realm from '../assets/images/realmicon.svg';

const tabs = [
  {
    key: 'Moca',
    text: (
      <>
        <span>
          <img src={moca} alt="moca" />
        </span>
        Moca XP
      </>
    ),
  },
  {
    key: 'Realm',
    text: (
      <>
        <span>
          <img src={realm} alt="real" />
        </span>
        Realm Ticket Miles
      </>
    ),
  },
];

const Mocana = () => {
  useEffect(() => {
    document.body.classList = 'mocana-page';
  });
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const handleSelectedTab = (value) => {
    setSelectedTab(value);
  };
  return (
    <Wrapper>
      <EventHeader />
      <MocanaHeader />
      <Socials />
      {/* <MocanaEvent /> */}
      <Tabs
        className="main-tabs-mocana"
        items={tabs}
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
      />
      <div
        className={`tab-content ${
          selectedTab === tabs[0].key ? 'selected' : ''
        }`}
      >
        <MocanaContentHeader />
        <MocaXp />
      </div>
      <div
        className={`tab-content ${
          selectedTab === tabs[1].key ? 'selected' : ''
        }`}
      >
        <RealmMilesHeader />
        <RealmMiles />
      </div>
    </Wrapper>
  );
};
export default Mocana;
