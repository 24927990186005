import React from 'react';
import { CloseIcon } from '../common/Icons';

const stopPropagation = (e) => {
  e.stopPropagation();
};

const EventSidebarWrapper = ({ className, children, handleCloseBtn }) => (
  <div className={`event-sb ${className ?? ''}`} onClick={handleCloseBtn}>
    <div className="event-sb-inner" onClick={stopPropagation}>
      {handleCloseBtn && (
        <button className="event-sb-close-btn" onClick={handleCloseBtn}>
          <CloseIcon aria-label="Close Icon" />
        </button>
      )}
      {/* <div className="event-sb-bg top-bg" /> */}
      {/* <div className="event-sb-cover-bg" /> */}
      <div className="event-sb-panel">{children}</div>
      {/* <div className="event-sb-bg bottom-bg" /> */}
    </div>
  </div>
);

export default EventSidebarWrapper;
