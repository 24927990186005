import React from 'react';

const ToolTip = ({ title, children, className }) => (
  <div className={`moca-tooltip ${className}`}>
    {title && <span className="moca-tooltip-label">{title}</span>}

    <div className="moca-table-tooltip">{children}</div>
  </div>
);

export default ToolTip;
