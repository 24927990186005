/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  isLoginInProgress: false,
  quotas: null,
  surveyItems: null,
  surveyResults: null,
  surveySelectedItems: null,
  eligibility: null,
  realms: null,
  error: null,
  mint: null,
  transaction: null,
  supplies: null,
  signedLicense: false,
  isConnectedBefore: true,
};

const DataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsLoginInProgress: (state, action) => {
      state.isLoginInProgress = action.payload;
    },
    setQuotas: (state, action) => {
      state.quotas = action.payload;
    },
    setEligibility: (state, action) => {
      state.eligibility = action.payload;
    },
    setSurveyResults: (state, action) => {
      state.surveyResults = action.payload;
    },
    setSurveyItems: (state, action) => {
      state.surveyItems = action.payload;
    },
    setSurveySelectedItems: (state, action) => {
      state.surveySelectedItems = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setMint: (state, action) => {
      state.mint = action.payload;
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    setSupplies: (state, action) => {
      state.supplies = action.payload;
    },
    setRealms: (state, action) => {
      state.realms = action.payload;
    },
    reset: (state) => {
      state.quotas = null;
    },
    setSignedLicense: (state, action) => {
      state.signedLicense = action.payload;
    },
    setIsConnectedBefore: (state, action) => {
      state.isConnectedBefore = action.payload;
    },
  },
});

export const {
  setIsLoginInProgress,
  setSurveyItems,
  setTransaction,
  setLoading,
  setQuotas,
  setError,
  reset,
  setSurveyResults,
  setMint,
  setEligibility,
  setSupplies,
  setRealms,
  setSurveySelectedItems,
  setSignedLicense,
  setIsConnectedBefore,
} = DataSlice.actions;
export default DataSlice.reducer;
