/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  total: null,
};

const MyMocaSlice = createSlice({
  name: 'myMoca',
  initialState,
  reducers: {
    setTotal: (state, action) => {
      state.total = action.payload;
    },
  },
});
export const { setTotal } = MyMocaSlice.actions;
export default MyMocaSlice.reducer;
