/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import validator from 'validator';
import OkIcon from '../assets/images/ok.svg';
import HomeIcon from '../assets/images/moca-home.png';
import OkTopLeftIcon from '../assets/images/ok_1.svg';
import OkBottomRightIcon from '../assets/images/ok_2.svg';
import { submitGettingUpdates } from '../api/fetchData';

const ContactForm = () => {
  const address = useAddress();
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('emailAddress', email);
    if (validator.isEmail(email)) {
      setEmailError('');
      const res = await submitGettingUpdates(address || '', email).catch(
        (er) => {
          console.log('submit error', { er });
          setMessage(
            'There was an issue submitting your email address, please try again.'
          );
        }
      );
      if (!res) {
        setMessage('Something went wrong, try again later');
      }
      if (res.status === 'ok') {
        setEmail('');
        setIsSuccess(true);
      } else {
        setMessage(
          'There was an issue submitting your email address, please try again.'
        );
      }
    } else {
      setEmailError('Please enter a valid email address');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="signupform">
      {!isSuccess ? (
        <>
          <h2>Sign Up for Updates</h2>
          <div className="form-fields">
            <label htmlFor="emailAddress">Email Address</label>
            <input
              type="text"
              name="emailAddress"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="emailError">
              {emailError && <span>{emailError}</span>}
            </div>
          </div>
          <div className="form-fields">
            <input
              type="submit"
              value="Submit"
              className="btn btn-get-updates"
            />
          </div>

          <div className="message">{message && <p>{message}</p>}</div>
          <div className="legal">
            By clicking Submit, I agree to receive updates and news from
            Mocaverse and Animoca Brands via Notifi, and I accept the{' '}
            <a
              href="https://notifi.network/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              href="https://notifi.network/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of use
            </a>
            .
          </div>
        </>
      ) : (
        <div>
          <div className="message success">
            <img className="success-checkbox" src={OkIcon} alt="Ok" />

            <p>THANK YOU FOR SIGNING UP</p>

            <img src={HomeIcon} alt="Moca" />
          </div>

          <div className="close-btn-modal" />

          <img
            src={OkTopLeftIcon}
            alt="close"
            className="success-top-left-modal"
          />

          <img
            src={OkBottomRightIcon}
            alt="close"
            className="success-bottom-right-modal"
          />
        </div>
      )}
    </form>
  );
};

export default ContactForm;
