/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import EventRewardList from './EventRewardList';

/**
 *
 * @param string className = ''
 * @param string|component title
 * @param string|component subtitle = ''
 * @param string txtColor = ''
 * @param string bgColor = ''
 * @param object[] items: [{ title: string|component, subtitle: string|component, rewardItems: [{img: string(url of image), name: string, subtitle: string}] }]
 * @param boolean center = false
 * @returns
 */
const EventBoard = ({
  className = '',
  title,
  subtitle = '',
  txtColor = '',
  bgColor = '',
  items,
  center = false,
}) => {
  let styles = {};

  if (bgColor !== '') {
    styles = { ...styles, backgroundColor: bgColor };
  }

  if (txtColor !== '') {
    styles = { ...styles, color: txtColor };
  }

  const renderBoardItems = useMemo(() => {
    if (!items) return <></>;
    const list = [];
    items.forEach((item) => {
      let hbStyles = {};
      if (item?.txtColor !== '') {
        hbStyles = { ...hbStyles, color: item.txtColor };
      }

      let bStyles = {};
      if (item?.txtColor !== '') {
        bStyles = { ...bStyles, backgroundColor: item.bgColor };
      }

      list.push(
        <div key={item.key} className="event-board-item" style={bStyles}>
          <div className="event-board-item-header" style={hbStyles}>
            <div className="event-board-item-title">{item.title}</div>
            {item?.subtitle && (
              <p className="event-board-item-subtitle">{item.subtitle}</p>
            )}
          </div>
          <div className="event-board-item-main">
            <EventRewardList
              classNamePrefix="event-board-item-rw"
              items={item.rewardItems}
              center={center}
            />
          </div>
        </div>
      );
    });
    return list;
  }, [items]);

  return (
    <div className={`event-board-wrapper ${className}`}>
      <div className="event-board-header" style={styles}>
        <h3 className="event-board-title">{title}</h3>
        {subtitle && <p className="event-board-subtitle">{subtitle}</p>}
      </div>
      <div className="event-board-main">{renderBoardItems}</div>
    </div>
  );
};

export default EventBoard;
