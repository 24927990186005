import React from 'react';
import { HelpIcon } from '../common/Icons';
import Tooltip from '../common/Tooltip';
import mocaYellowIcon from '../../assets/images/user-board/icon_moca_rare.svg';

const BoosterTooltip = () => (
  <Tooltip
    className="moca-event-tooltip bottom-tooltip boost-tooltip"
    title={<HelpIcon />}
    content={
      <div className="moca-SoftStake-tooltip">
        <div className="SoftStake-tooltip-header">
          <h2 className="header-name">
            <img src={mocaYellowIcon} alt="" />
            Rare Boost
          </h2>
          <span className="header-value">
            <h2>+1.5 XP</h2>
            <span>
              {' '}
              to each
              <img src={mocaYellowIcon} alt="" />
            </span>
          </span>
        </div>
        <p className="description">Hold a 1:1 Moca</p>
      </div>
    }
  />
);

export default BoosterTooltip;
