/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
// import { EVENTRESULTTYPE } from '../../utils/eventHelpers';
import { ShowIcon, HideIcon } from '../common/Icons';
import GooglePlayImg from '../../assets/images/mocana/google-play.png';
import AppleStoreImg from '../../assets/images/mocana/app-store.png';
import EventMocaListItem from './EventMocaListItem';

const EventResult = ({ resultStatus, selectedMocaItem }) => {
  const [showRegMoca, setShowRegMoca] = useState(false);

  const cls = showRegMoca ? 'show' : '';

  // if (resultStatus === EVENTRESULTTYPE.ERROR && selectedMocaItem) {
  //   return (
  //     <div className={`event-result ${resultStatus}`}>
  //       <ul className="event-result-moca-list">
  //         <EventMocaListItem
  //           classNamePrefix="event-result-moca"
  //           item={selectedMocaItem}
  //           status={resultStatus}
  //         />
  //       </ul>
  //       <p>
  //         *Additional rewards will be distributed to the wallet <br />
  //         address holding this Moca during the distribution phase.
  //       </p>
  //     </div>
  //   );
  // }

  return (
    <>
      {selectedMocaItem && (
        <>
          <div className={`event-result ${cls}`}>
            <ul className="event-result-moca-list">
              <EventMocaListItem
                classNamePrefix="event-result-moca"
                item={selectedMocaItem}
                status={resultStatus}
              />
            </ul>
            <p>
              *Additional rewards will be distributed to the wallet address
              holding this Moca during the distribution phase.
            </p>
          </div>
          <div className="event-result-moca-actions">
            <button
              className="btn-show-hide-registered-moca"
              onClick={() => setShowRegMoca(!showRegMoca)}
            >
              {showRegMoca ? (
                <>
                  <HideIcon /> Hide
                </>
              ) : (
                <>
                  <ShowIcon /> Show
                </>
              )}{' '}
              Registered Moca
            </button>
          </div>
        </>
      )}
      <div className="event-sb-header event-result-header">
        <h2 className="event-sb-title">
          Link your Gamee account and start playing on the Gamee App
        </h2>
      </div>
      <div className="event-sb-actions event-result-actions">
        <a
          href="https://app.gamee.com/"
          className="btn btn-md-second"
          title="Link Gamee Account"
          target="_blank"
          rel="noreferrer"
        >
          Link Gamee Account
        </a>
        <p className="event-result-note">Don’t have Gamee App? Download Now:</p>
        <ul className="event-result-download-links">
          <li>
            <a
              href="https://apps.apple.com/app/arc8-gmee-community-network/id1583002431"
              title="Apple Store"
              target="_blank"
              rel="noreferrer"
            >
              <img src={AppleStoreImg} alt="Apple Store" />
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.gamee.arc8.android.app&pli=1"
              title="Google Play"
              target="_blank"
              rel="noreferrer"
            >
              <img src={GooglePlayImg} alt="Google Play" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default EventResult;
