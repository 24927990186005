import React, { useState, useEffect } from 'react';
import { EVENT_TIME_STATUS } from '../../utils/eventHelpers';
import { TimeRemindIcon } from '../common/Icons';
import shareIcon from '../../assets/images/share-fill.svg';

const EventTitleCampaign = ({ className, data, statusEvent }) => {
  const currentLink = window.location.href;
  const [copied, setCopied] = useState(false);

  const converTime = (isoDate) => {
    const dateObj = new Date(isoDate);
    const month = dateObj.toLocaleString('default', {
      timeZone: 'UTC',
      month: 'short',
    });
    const day = dateObj.getUTCDate();
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();

    return `${month} ${day}, ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(currentLink);
    setCopied(true);
  };

  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [copied]);
  if (!data?.start_date) {
    return null;
  }
  return (
    <div className={`event-title-wrapper ${className ?? ''}`}>
      <h1 className="event-title">{data.name}</h1>
      <div className="event-title-notify">
        <p className="event-title-date">
          <TimeRemindIcon className="event-title-icon" />
          {converTime(data?.start_date)} - {converTime(data?.end_date)} (UTC)
          {/* {startedDate}- {endedDate} (UTC) */}
        </p>
        <p className="event-title-status">
          {statusEvent === EVENT_TIME_STATUS.NOT_STARTED && (
            <span>Not Started</span>
          )}
          {statusEvent === EVENT_TIME_STATUS.LIVE && (
            <span className="live">Live</span>
          )}
          {statusEvent === EVENT_TIME_STATUS.ENDED && (
            <span className="ended">Ended</span>
          )}
        </p>
        <p className="event-title-type">
          <span className="partnership">Partnership</span>
        </p>
        <button
          className="btn btn-md-second btn-connect btn-share"
          aria-label="Share"
          onClick={handleCopy}
        >
          <img className="icon-share" src={shareIcon} alt="share mocaverse" />
          <span>Share</span>
        </button>
        {copied && <span className="copied">Copied</span>}
      </div>
    </div>
  );
};

export default EventTitleCampaign;
