import React from 'react';
import RewardDefaultImage from '../../assets/images/mocana/reward-default.png';

/**
 *
 * @param string classNamePrefix
 * @param string img: url of image
 * @param string name
 * @param string subtitle
 * @returns
 */
const EventRewardItem = ({ classNamePrefix, img, name, subtitle }) => (
  <li className={`${classNamePrefix}-item`}>
    <div className={`${classNamePrefix}-img`}>
      <img title="" alt="" src={img || RewardDefaultImage} />
    </div>
    <h3 className={`${classNamePrefix}-name`}>{name}</h3>
    {subtitle && <p className={`${classNamePrefix}-subtitle`}>{subtitle}</p>}
  </li>
);

export default EventRewardItem;
