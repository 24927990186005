import React from 'react';

const EventRequirement = () => (
  <div className="event-section event-requirement">
    <h2 className="event-section-title">Requirement</h2>
    <div className="event-section-content">
      <p>
        To participate you must meet <strong>at least 1</strong> of the follow
        requirement:
      </p>
      <ul>
        <li>Own a Moca that is staked during 3 Apr - 10 Apr.</li>
        <li>Own a Realm Ticket</li>
      </ul>
    </div>
    <p className="note">
      Note: For Moca in cold wallets, in order to participate, you must open a
      ticket on discord, for delegation
    </p>
  </div>
);

export default EventRequirement;
