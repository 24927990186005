import React, { useMemo } from 'react';
import EventBoard from './EventBoard';
import EventWhatIsTribeTooltip from './EventWhatIsTribeTooltip';
import RewardDetailXP3 from '../../assets/images/dustland/rw-detail-3xp.png';
import RewardDetailXP10 from '../../assets/images/dustland/rw-detail-10xp.png';
import RewardDetailXP15 from '../../assets/images/dustland/rw-detail-15xp.png';
import RewardDetailXP20 from '../../assets/images/dustland/rw-detail-20xp.png';
import RewardDetailXP25 from '../../assets/images/dustland/rw-detail-25xp.png';
import RewardDetailXP30 from '../../assets/images/dustland/rw-detail-30xp.png';
import RewardDetailDoes100 from '../../assets/images/dustland/rw-detail-100-does.svg';
import RewardDetailDoes150 from '../../assets/images/dustland/rw-detail-150-does.svg';
import RewardDetailDoes300 from '../../assets/images/dustland/rw-detail-300-does.svg';
import RewardDetailDoes400 from '../../assets/images/dustland/rw-detail-400-does.svg';
import RewardDetailDoes413 from '../../assets/images/dustland/rw-detail-413-does.svg';

import RewardDetailFloppy from '../../assets/images/dustland/rw-detail-floppy.svg';
import RewardDetailRTM10 from '../../assets/images/dustland/rw-detail-10rtm.png';
import RewardDetailRTM15 from '../../assets/images/dustland/rw-detail-15rtm.png';
import RewardDetailRTM20 from '../../assets/images/dustland/rw-detail-20rtm.png';
import RewardDetailRTM25 from '../../assets/images/dustland/rw-detail-25rtm.png';
import RewardDetailRTM30 from '../../assets/images/dustland/rw-detail-30rtm.png';

const boardItems = [
  {
    key: 'board1',
    className: 'event-highscore-board',
    title: 'Moca XP - Avg. Pace Leaderboard',
    subtitle: (
      <>Average pace achieved by the runner within the activation period</>
    ),
    items: [
      {
        key: 'board1-reward1',
        title: (
          <>
            <span>1</span> st <span className="event-board-item-hr">-</span>{' '}
            <span>5</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        rewardItems: [
          {
            key: 'board1-reward1-1',
            img: RewardDetailXP30,
            name: 'Moca XP',
          },
          {
            key: 'board1-reward1-2',
            img: RewardDetailDoes400,
            name: 'DOSE',
          },
          {
            key: 'board1-reward1-3',
            img: RewardDetailFloppy,
            name: 'Floppy Disk',
          },
        ],
      },
      {
        key: 'board1-reward2',
        title: (
          <>
            <span>6</span> th <span className="event-board-item-hr">-</span>{' '}
            <span>25</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#8CFFF8',
        rewardItems: [
          {
            key: 'board1-reward2-1',
            img: RewardDetailXP20,
            name: 'Moca XP',
          },
          {
            key: 'board1-reward2-2',
            img: RewardDetailDoes150,
            name: 'DOSE',
          },
          {
            key: 'board1-reward2-3',
            img: RewardDetailFloppy,
            name: 'Floppy Disk',
          },
        ],
      },
      {
        key: 'board1-reward3',
        title: (
          <>
            From <br />
            <span>26</span>th<span className="event-board-badge">*</span>
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#FFA178',
        rewardItems: [
          {
            key: 'board1-reward3-1',
            img: RewardDetailXP10,
            name: 'Moca XP',
          },
        ],
      },
    ],
  },
  {
    key: 'board2',
    className: 'event-totalscore-board',
    title: 'Moca XP - Total Distance Leaderboard',
    subtitle: (
      <>Total distance covered by the runner within the activation period</>
    ),
    items: [
      {
        key: 'board2-reward1',
        title: (
          <>
            <span>1</span> st <span className="event-board-item-hr">-</span>{' '}
            <span>5</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        rewardItems: [
          {
            key: 'board2-reward1-1',
            img: RewardDetailXP25,
            name: 'Moca XP',
          },
          {
            key: 'board2-reward1-2',
            img: RewardDetailDoes300,
            name: 'DOSE',
          },
          {
            key: 'board2-reward1-3',
            img: RewardDetailFloppy,
            name: 'Floppy Disk',
          },
        ],
      },
      {
        key: 'board2-reward2',
        title: (
          <>
            <span>6</span> th <span className="event-board-item-hr">-</span>{' '}
            <span>25</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#8CFFF8',
        rewardItems: [
          {
            key: 'board2-reward2-1',
            img: RewardDetailXP15,
            name: 'Moca XP',
          },
          {
            key: 'board2-reward2-2',
            img: RewardDetailDoes100,
            name: 'DOSE',
          },
          {
            key: 'board2-reward2-3',
            img: RewardDetailFloppy,
            name: 'Floppy Disk',
          },
        ],
      },
    ],
  },
  {
    key: 'board3',
    className: 'event-tribe-board',
    title: (
      <>
        Moca XP - Tribe Distance Leaderboard <EventWhatIsTribeTooltip />
      </>
    ),
    subtitle: 'Contributed by top 5 player’s total distance from each tribe',
    items: [
      {
        key: 'board3-reward1',
        title: (
          <>
            <span className="sm">All Players of the</span> Winning Tribe
          </>
        ),
        rewardItems: [
          {
            key: 'board3-reward1-1',
            img: RewardDetailXP3,
            name: 'Moca XP',
          },
          {
            key: 'board2-reward1-2',
            img: RewardDetailDoes413,
            name: '413,337 DOSE split equally',
          },
        ],
      },
    ],
  },
];

const rtmBoardItems = [
  {
    key: 'board1',
    className: 'event-highscore-board',
    title: 'RTM - Avg. Pace Leaderboard',
    subtitle: (
      <>Average Pace achieved by the runner within the activation period</>
    ),
    items: [
      {
        key: 'board1-reward1',
        title: (
          <>
            <span>1</span> st <span className="event-board-item-hr">-</span>{' '}
            <span>5</span> th
          </>
        ),
        bgColor: 'rgba(0, 31, 67, 0.8)',
        rewardItems: [
          {
            key: 'board1-reward1-1',
            img: RewardDetailRTM30,
            name: 'Realm Ticket Miles',
          },
          {
            key: 'board1-reward1-3',
            img: RewardDetailFloppy,
            name: 'Floppy Disk',
          },
        ],
      },
      {
        key: 'board1-reward2',
        title: (
          <>
            <span>6</span> th <span className="event-board-item-hr">-</span>{' '}
            <span>25</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#8CFFF8',
        rewardItems: [
          {
            key: 'board1-reward2-1',
            img: RewardDetailRTM20,
            name: 'Realm Ticket Miles',
          },
        ],
      },
      {
        key: 'board1-reward3',
        title: (
          <>
            From <br />
            <span>26</span>th<span className="event-board-badge">*</span>
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#FFA178',
        rewardItems: [
          {
            key: 'board1-reward3-1',
            img: RewardDetailRTM10,
            name: 'Realm Ticket Miles',
          },
        ],
      },
    ],
  },
  {
    key: 'board2',
    className: 'event-totalscore-board',
    title: 'RTM - Total Distance Leaderboard',
    subtitle: (
      <>Total Distance covered by the runner within the activation period</>
    ),
    items: [
      {
        key: 'board2-reward1',
        title: (
          <>
            <span>1</span> st <span className="event-board-item-hr">-</span>{' '}
            <span>5</span> th
          </>
        ),
        bgColor: 'rgba(0, 31, 67, 0.8)',
        rewardItems: [
          {
            key: 'board2-reward1-1',
            img: RewardDetailRTM25,
            name: 'Realm Ticket Miles',
          },
          {
            key: 'board1-reward1-3',
            img: RewardDetailFloppy,
            name: 'Floppy Disk',
          },
        ],
      },
      {
        key: 'board2-reward2',
        title: (
          <>
            <span>6</span> th <span className="event-board-item-hr">-</span>{' '}
            <span>25</span> th
          </>
        ),
        bgColor: 'rgba(0,31,67,0.8)',
        txtColor: '#8CFFF8',
        rewardItems: [
          {
            key: 'board1-reward2-1',
            img: RewardDetailRTM15,
            name: 'Realm Ticket Miles',
          },
        ],
      },
    ],
  },
];

const EventRewardDetail = () => {
  const highScoreLeaderBoard = useMemo(() => {
    const list = [];
    boardItems.forEach((board) => {
      list.push(
        <EventBoard
          key={board.key}
          className={board?.className || ''}
          title={board.title}
          subtitle={board?.subtitle || ''}
          txtColor={board?.txtColor || ''}
          bgColor={board?.bgColor || ''}
          items={board.items}
        />
      );
    });
    return list;
  }, []);

  const rtmHighScoreLeaderBoard = useMemo(() => {
    const list = [];
    rtmBoardItems.forEach((board) => {
      list.push(
        <EventBoard
          key={board.key}
          className={board?.className || ''}
          title={board.title}
          subtitle={board?.subtitle || ''}
          txtColor={board?.txtColor || ''}
          bgColor={board?.bgColor || ''}
          items={board.items}
          // center
        />
      );
    });
    return list;
  }, []);

  return (
    <>
      <div className="event-section event-reward-detail">
        <h2 className="event-section-title">Reward Detail</h2>
        <div className="event-section-content">{highScoreLeaderBoard}</div>
      </div>
      <div
        className="event-section event-reward-detail"
        style={{ marginTop: 32 }}
      >
        <div className="event-section-content">{rtmHighScoreLeaderBoard}</div>
        <p className="event-reward-detail-note">
          Note:
          <br />
          1. Top runner rewards on both Total Distance and Avg. Pace Leaderboard
          (for both Moca XP and RTM) are mutually exclusive
          <br />
          2. All runners must spent at least 60 energies within the activation
          period to be eligible for any rewards
        </p>
        <p className="event-reward-detail-note-mobile">
          Note:
          <br />
          1. Top runner rewards on both Total Distance and Avg. Pace Leaderboard
          (for both Moca XP and RTM) are mutually exclusive
          <br />
          2. All runners must spent at least 60 energies within the activation
          period to be eligible for any rewards
        </p>
      </div>
    </>
  );
};

export default EventRewardDetail;
