import React, { useEffect } from 'react';
import PageWrapper from '../components/PageWrapper';
import {
  Header,
  Footer,
  Socials,
  Intro,
  Story,
  Realms,
  WhatIsMocaverse,
  Faq,
} from '../components';

const Home = () => {
  useEffect(() => {
    document.body.classList.add('home');
  });

  return (
    <>
      <div className="bg" />
      <div className="stars" />
      <PageWrapper>
        <Header />
        <Intro isMint />
        <Story />
        <Realms />
        <WhatIsMocaverse />
        <Faq />
        <Footer />
        <Socials />
      </PageWrapper>
    </>
  );
};

export default Home;
