import React from 'react';
import { PlayAnimation, PlayTextAnimation } from './index';

// const PlayAnimation = React.lazy(() => import('./PlayRealm'));
// const PlayTextAnimation = React.lazy(() => import('./PlayRealmText'));

const text =
  'Games, entertainment and fun in a joyful environment where unique engagement surrounds us all';

const PlayRealmItem = ({ isSlider = false }) => {
  if (isSlider) {
    return (
      <div className="realms-slider-item">
        <PlayAnimation />
        <div className="realm-info play">
          <PlayTextAnimation />
          {text}
        </div>
      </div>
    );
  }

  return (
    <div className="realm__play">
      <div className="realm__content">
        <PlayTextAnimation />
        <PlayAnimation />
        <div className="realm__info">{text}</div>
      </div>
    </div>
  );
};

export default PlayRealmItem;
