import React from 'react';
import ModalWrapper from './common/ModalWrapper';
import Form from './Form';

const ModalGetUpdates = ({ toggleModal }) => (
  <ModalWrapper className="signup-wrappar" onClose={toggleModal}>
    <Form />
  </ModalWrapper>
);

export default ModalGetUpdates;
