import React from 'react';
import homeLogo from '../assets/images/mocaverse.svg';
import PlayerRealms from './PlayerRealms';

const Error = () => (
  <section id="home" className="intro orbit-rings">
    <div className="intro__wrapper">
      <div className="home-logo-counter-block">
        <div className="home-wrapper">
          <img src={homeLogo} alt="OFF TO THE REALMS" className="home-logo" />
          <div className="signup-info">
            The Page you are looking for was not found <br />
            please go back to the <a href="/">homepage</a>
          </div>
        </div>
      </div>
      <PlayerRealms />
    </div>
  </section>
);

export default React.memo(Error);
