import React, { useMemo } from 'react';
import {
  EVENT_REGISTRATION_STATUS,
  EVENT_RESULT_TYPE,
} from '../../utils/eventHelpers';
import EventMocaGrid from './EventMocaGrid';
import EventMsg from './EventMsg';
import EventDelegate from './EventDelegate';
import RegisteringWallet from '../RegisteringWallet';

const EventSelectMoca = ({
  status,
  handleRegister,
  handleClickMoca,
  selectedMocaItem,
  error,
  mocaList,
  delegateItem,
  delegatedList,
  handleSelectDelegate,
  address,
}) => {
  const rgBtn = useMemo(() => {
    if (status === EVENT_REGISTRATION_STATUS.IN_PROCESS) {
      return (
        <button
          className="btn btn-md-second btn-register-now"
          onClick={handleRegister}
          aria-label="Register Now"
          disabled={!selectedMocaItem}
        >
          Register Now
        </button>
      );
    }
    return (
      <>
        <button
          className="btn btn-md-second btn-register-now"
          disabled
          aria-label="Register Now"
        >
          Register Now
        </button>
        <p className="event-sb-action-note">
          Registration period has not started yet.
        </p>
      </>
    );
  }, [status, handleRegister]);

  return (
    <>
      {error && (
        <EventMsg
          type={EVENT_RESULT_TYPE.ERROR}
          message={error?.msg}
          // txnHash={error?.txnHash}
        />
      )}
      <div className="event-sb-header">
        <h2 className="event-sb-title">Select a Moca to be your Agent</h2>
        <div className="event-sb-note">
          MocaXP will be gained to the selected Moca. <br />
          You cannot change your selection after registered.
        </div>
      </div>
      {delegatedList.length !== 0 && (
        <EventDelegate
          selectedItem={delegateItem}
          delegatedList={delegatedList}
          handleSelectDelegate={handleSelectDelegate}
        />
      )}
      <div className="event-sb-main event-select-moca">
        <EventMocaGrid
          classNamePrefix="event-moca"
          items={mocaList}
          selectedItem={selectedMocaItem}
          handleClick={handleClickMoca}
        />
      </div>
      <RegisteringWallet wallet={address} />
      <div className="event-sb-actions">{rgBtn}</div>
    </>
  );
};

export default EventSelectMoca;
