import React from 'react';

const HotWalletInfo = (props) => (
  <div className="hot-wallet-info">
    <p>
      We now support native{' '}
      <a href="https://delegate.cash/" target="_blank" rel="noreferrer">
        delegate.cash
      </a>
      !
    </p>
    {!props?.isMobile && (
      <span>
        You can now connect with a hot wallet while your moca is sitting in your
        cold wallet.
      </span>
    )}
  </div>
);

export default HotWalletInfo;
