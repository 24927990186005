import React from 'react';
import { useSelector } from 'react-redux';
import mocaIcon from '../../assets/images/user-board/moca-pink.svg';

const UserBoardHeader = () => {
  const { total } = useSelector((state) => ({
    total: state.myMoca.total,
  }));

  return (
    <div className="landing-header-wrapper">
      <p className="landing-title">My Account</p>
      <div className="landing-content">
        <div className="landing-content-item">
          <div className="item">
            <img className="item-icon" src={mocaIcon} alt="" />
            <div className="item-content">
              <p className="item-content-title">MOCA</p>
              <p className="item-content-quantity">{total}</p>
            </div>
          </div>
          {/* <div className="item">
            <div className="item-content">
              <p className="item-content-title">XP Last Week</p>
              <p className="item-content-quantity-more">+128</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default UserBoardHeader;
