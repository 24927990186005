import React from 'react';
import Error from '../components/Error';

const ErrorPage = () => (
  <>
    <div className="bg" />
    <div className="stars" />
    <main>
      <Error />
    </main>
  </>
);

export default ErrorPage;
