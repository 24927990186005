import React, { useState, useEffect } from 'react';
import Sort from './Sort';
import TableRealmMiles from './TableRealmMiles';
import NoData from './NoData';

import iconSearch from '../../assets/images/mocana/icon-search.svg';
import iconSort from '../../assets/images/mocana/icon-down.svg';
import { fetchRtmLeaderboard } from '../../api/fetchData';
import { useDebounce } from '../../hooks/useDebounce';
import { sanitizeInput } from '../../utils/mocanaHelper';

import iconClose from '../../assets/images/mocana/close.svg';
import iconRemoveS from '../../assets/images/mocana/icon-remove-s.svg';

const RealmMiles = () => {
  const [showSort, setShowSort] = useState(false);
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(0);

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchTerm = useDebounce(searchInput, 400);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [ordering, setOrdering] = useState('rank');
  const [data, setData] = useState();

  const handleChangeParam = () => {
    if (page !== 1 || offset !== 0) {
      setPage(1);
      setOffset(0);
    }
  };
  const handleInputChange = (event) => {
    handleChangeParam();
    setSearchInput(event.target.value);
  };

  const handleRemoveSearch = () => {
    setSearchInput('');
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const results = await fetchRtmLeaderboard({
        search: sanitizeInput(debouncedSearchTerm),
        page,
        offset,
        ordering,
      });
      if (results) {
        setCount(results.total);
        setData(results.results);
      }
      setLoading(false);
    };
    fetchData();
  }, [debouncedSearchTerm, page, offset, ordering]);

  return (
    <section className="moca-table moca-table-realm" id="moca">
      <div className="moca-search-filter">
        <div className="moca-search">
          <img src={iconSearch} alt="icon-search" />
          <input
            type="text"
            name="search"
            value={searchInput}
            placeholder="Search by wallet address"
            onChange={handleInputChange}
            required
          />
          {searchInput.length !== 0 && (
            <button onClick={handleRemoveSearch}>
              <img src={iconRemoveS} alt="icon-r-search" />
            </button>
          )}
        </div>
        <p className="content-header-note txt-sm">
          *The Realm Ticket Miles will be updated within 7 days after the end of
          activation.
        </p>
        <div className="mocana-filter">
          <div className="mocana-button-sort">
            <button onClick={() => setShowSort(!showSort)}>
              Sort By
              <img src={iconSort} alt="" />
            </button>
          </div>

          <div className={`mocana-list-sort ${showSort && 'active'}`}>
            <Sort
              setShowSort={setShowSort}
              setValueSort={setOrdering}
              isRealm
            />
            <div
              onClick={() => setShowSort(!showSort)}
              className="toggle-button"
            >
              <img src={iconClose} alt="" />
            </div>
          </div>
        </div>
      </div>
      <p className="content-header-note txt-md realm">
        *The Realm Ticket Miles will be updated within 7 days after the end of
        activation.
      </p>
      <div className="moca-results">
        {data && data.length === 0 ? (
          <NoData />
        ) : (
          <TableRealmMiles
            data={data}
            count={count}
            setPage={setPage}
            loading={loading}
            setOffset={setOffset}
            setValueSort={setOrdering}
            currentPage={page}
          />
        )}
      </div>
    </section>
  );
};
export default RealmMiles;
