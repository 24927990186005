import React from 'react';
import { EVENT_RESULT_TYPE } from '../../utils/eventHelpers';
import { formatPrice } from '../../utils/mathsHelper';
// import MocaDefaultImage from '../../assets/images/mocana/moca-default-img.png';
import { env } from '../../utils/envHelpers';
import jsonName from '../../assets/moca_name_mapping.json';

/**
 *
 * @param string classNamePrefix
 * @param object item: {moca_id: string, img: string, url of the image, moca_name, moca_xp}
 * @param ... handleClick
 * @param object selectedItem : {moca_id: string, img: string, url of the image, moca_name, tribe, moca_xp}
 * @returns
 */
const EventMocaListItem = ({ classNamePrefix, item, status }) => {
  const keyToFindName = item.moca_id; // The key whose value you want to access
  const mapName = jsonName[keyToFindName];

  return (
    <li className={`${classNamePrefix}-item`}>
      {status !== EVENT_RESULT_TYPE.ERROR && (
        <div className={`${classNamePrefix}-img ${status}`}>
          <img
            title={item.moca_name}
            alt=""
            src={`${`${env.userThumbnailUrl}%23${
              item.moca_name || mapName.replace('#', '')
            }`}.jpg`}
            // src={item?.img || MocaDefaultImage}
          />
        </div>
      )}
      <div className={`${classNamePrefix}-box ${status}`}>
        <h3 className={`${classNamePrefix}-title`}>Your Registered Moca</h3>
        {status === EVENT_RESULT_TYPE.ERROR && (
          <p className={`${classNamePrefix}-msg`}>
            This Moca no longer belongs to you
          </p>
        )}
        <div className={`${classNamePrefix}-info`}>
          <h4 className={`${classNamePrefix}-name`}>
            {item.moca_name ? `#${item.moca_name}` : mapName}
          </h4>
          <p className={`${classNamePrefix}-xp`}>
            {formatPrice(item.moca_xp, 'XP')}
          </p>
          <p className={`${classNamePrefix}-tribe`}>{item.tribe}</p>
        </div>
      </div>
    </li>
  );
};

export default EventMocaListItem;
