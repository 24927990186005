import React from 'react';
import { TimerIcon, TimerFixedPanelIcon } from '../common/Icons';

const EventCountDown = ({ type = '', hours, minutes, days }) => (
  <div className="event-countdown">
    {type === 'fixed' ? (
      <TimerFixedPanelIcon className="timer-icon" aria-label="timer-icon" />
    ) : (
      <TimerIcon className="timer-icon" aria-label="timer-icon" />
    )}

    <span className="event-countdown-item">{days}D</span>
    <span className="event-countdown-item">{hours}H</span>
    <span className="event-countdown-item">{minutes}M</span>
  </div>
);

export default EventCountDown;
