import React from 'react';
import { useAddress } from '@thirdweb-dev/react';
import EventTitle from './EventTitle';
import EventSideBar from './EventSidebar';
import EventTitlePage from './EventTitlePage';

const EventConnectionWrapper = ({ children }) => {
  const address = useAddress();
  return (
    <div className="mocana-main">
      <main className="event-main">
        <EventTitle />
        {children}
      </main>
      <EventSideBar address={address} />
      <EventTitlePage />
    </div>
  );
};

export default EventConnectionWrapper;
