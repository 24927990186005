import React, { useMemo } from 'react';

const Tabs = (props) => {
  const { selectedTab, handleSelectedTab, items, className } = props;

  const itemsEl = useMemo(() => {
    if (!items) return <></>;
    const data = [];
    items.map((item) => {
      const cls = selectedTab === item.key ? 'active' : '';
      data.push(
        <li
          key={`my-account-tab-item-${item.key}`}
          onClick={() => handleSelectedTab(item.key)}
        >
          <button className={cls} disabled={item.disabled ?? false}>
            {item.text}
          </button>
        </li>
      );
    });
    return data;
  }, [selectedTab]);

  return (
    <div className={`list-tabs ${className}`}>
      <ul>{itemsEl}</ul>
    </div>
  );
};

export default Tabs;
