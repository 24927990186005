import React from 'react';
import EventMocaItem from './EventMocaItem';

/**
 *
 * @param string classNamePrefix
 * @param object[] items: [{moca_id: string, img: string, url of the image, moca_name, tribe, total_xp}]
 * @returns
 */
const EventMocaGrid = ({
  classNamePrefix,
  items,
  selectedItem,
  handleClick,
}) => (
  <div className={`${classNamePrefix}-scroller`}>
    <ul className={`${classNamePrefix}-grid`}>
      {items.length !== 0 &&
        items.map((item) => (
          <EventMocaItem
            classNamePrefix={classNamePrefix}
            key={item.moca_id}
            item={item}
            selectedItem={selectedItem}
            handleClick={handleClick}
          />
        ))}
    </ul>
  </div>
);

export default EventMocaGrid;
