/* eslint-disable radix */
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import TV from '../assets/images/tv.png';
import homeLogo from '../assets/images/mocaverse.svg';

function getTvInitialScale() {
  const windowWidth = parseInt(window.innerWidth);
  if (windowWidth >= 3072) {
    return 2.25;
  }
  if (windowWidth >= 2560) {
    return 1.75;
  }
  if (windowWidth >= 1920) {
    return 1.3;
  }
  return 1;
}

function getTvFinalScale() {
  const windowWidth = parseInt(window.innerWidth);
  if (windowWidth >= 3072) {
    return 7.5;
  }
  if (windowWidth >= 2560) {
    return 5.5;
  }
  if (windowWidth >= 1920) {
    return 4.0;
  }
  return 3.5;
}

const loadingTV = ({ show, onEnter }) => (
  <AnimatePresence>
    {show && (
      <motion.div
        className="tv__wrapper desktop"
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
      >
        <motion.div
          className="tv__inner"
          initial={{
            scale: getTvInitialScale(),
            opacity: 1,
            translateX: 0,
            translateY: 0,
          }}
          animate={{
            scale: getTvInitialScale(),
            opacity: 1,
            translateX: 0,
            translateY: 0,
          }}
          exit={{
            scale: getTvFinalScale(),
            translateX: 300,
            translateY: -150,
            opacity: 1,
          }}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
        >
          <motion.div
            className="tv__overlay"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
          />

          <img src={TV} alt="TV" className="tvclick" />
          <motion.img
            src={homeLogo}
            alt="Off To The Realm Logo"
            className="small-logo tv__small-logo"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0 }}
          />
          <motion.div
            className="tv__button"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0 }}
          >
            <button
              type="button"
              id="tvclick-btn"
              className="btn"
              onClick={onEnter}
            >
              Enter
            </button>
          </motion.div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

export default loadingTV;
