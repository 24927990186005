import React from 'react';

const EventHowToRegister = () => (
  <div className="event-section event-ht-register">
    <h2 className="event-section-title">How to Register</h2>
    <div className="event-section-content">
      <ol>
        <li>
          <h3>
            Connect wallet and Register with a selected Moca or by holding a
            Realm Ticket
          </h3>
          <p className="guide-step-1">
            Connect Wallet: The connected wallet is for registration. You can
            now connect with a hot wallet while your moca is sitting in your
            cold wallet, complete delegation through delegate.cash{' '}
            <a href="https://delegate.cash/" target="_blank" rel="noreferrer">
              here
            </a>{' '}
            to connect with your hot wallet.
          </p>
          <p>
            Moca: Select your Moca agent using the box on the right and click
            “Register Now.” The tribe of the selected Moca will also determine
            the tribe you will be playing for, so pick wisely!
          </p>
          <p>
            Realm Ticket: Make sure your connected wallet holds one or more
            Realm Tickets, then click “Register with Realm Ticket X1”. This
            option will only be available for those who do not hold any Moca.
          </p>
          <p>
            Delegate.cash: For holders who would like to register with their hot
            wallet, please select delegate after completing the delegation on
            delegate.cash. Visit Mocaverse Discord for additional instructions!
          </p>
        </li>
        <li>
          <h3>Link registered wallet with Dustland account</h3>
          <p>
            Click on the "Link Wallet" button and head to the Dustland official
            site. Connect your registered or delegated wallet by clicking
            "Connect Wallet" on the top right.
          </p>
          <p>
            <strong>Existing Dustland Users</strong> <br />
            Select "Link existing Dustland account" and log in.
          </p>
          <p>
            <strong>New Dustland Users</strong> <br />
            Select "Create New Dustland Account" and follow the pop-up
            instruction to create an account. Then select "Link existing
            Dustland account" and log in.
          </p>
        </li>
        <li>
          <h3>Download the Dustland Runner App</h3>
          <p>
            Download the app on{' '}
            <a
              href="https://apps.apple.com/hk/app/dustland-runner/id1616478668?l=en"
              title="Apple Store"
              target="_blank"
              rel="noreferrer"
            >
              App Store
            </a>{' '}
            or{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.olivex.dustlandrunnerclient&hl=en&gl=US"
              title="Google Play"
              target="_blank"
              rel="noreferrer"
            >
              Google Play
            </a>
            , then log in using the same account in the previous step.
          </p>
        </li>
        <li>
          <h3>Start running!</h3>
          <p>
            Select a mission and start running! Remember to turn on GPS and put
            on your headphones for the immersive audio experience as well!
          </p>
        </li>
      </ol>
      <p className="note">
        Note: MocaXP earned through the activation will be accrued to the
        selected Moca, with additional rewards distributed to the wallet address
        holding the said Moca during the reward distribution phase.
      </p>
    </div>
  </div>
);

export default EventHowToRegister;
