import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import line from '../../assets/images/campaign/giveaway-item-line.svg';
import DefaultLogo from '../../assets/images/campaign/moca-sharing.svg';

const CampaignGiveawayItem = ({ item, isLive }) => {
  const { id, name, start_date, end_date, images } = item;
  const startDate = moment(start_date).format('DD MMM hh:mm');
  const endDate = moment(end_date).format('DD MMM hh:mm');

  return (
    <li className="campaign-list__item campaign-list__item--giveaway">
      <Link to={`/campaign?id=${id}`}>
        <div className="campaign-list__item--header campaign-list__item--header--giveaway">
          <div className="line-bottom">
            <img src={line} alt="" />
          </div>
          <div className="arc-thumbnail">
            <img src={images[0] ? images[0].src : DefaultLogo} alt="" />
          </div>
        </div>
        <div className="campaign-list__item--content campaign-list__item--giveaway--content">
          <p className="campaign-list__item--content-title">{name}</p>
          <p className="campaign-list__item--content-ended">
            {`${startDate} - ${endDate}`} UTC
          </p>
        </div>
        {/* <div className="campaign-list__item--footer">
        <div className="campaign-list__item--footer-registered">
          <div className="campaign-list__item--footer-registered--moca">
            <div className="campaign-list__item--footer-registered--moca-thumbnail">
              <img src={moca} alt="" />
            </div>
            <span className="campaign-list__item--footer-registered--moca-id">
              Moca: #1213
            </span>
          </div>
          <div className="campaign-list__item--footer-registered--status">
            <div className="campaign-list__item--footer-registered--status-won">
              <img src={wonIcon} alt="" />
              <span>You won</span>
            </div>
          </div>
        </div>
      </div> */}
        {isLive && (
          <div className="campaign-list__item--live">
            <div className="circle" />
            <span>Live</span>
          </div>
        )}
        <div className="campaign-list__item--category-giveaway">
          <span>Giveaway</span>
        </div>
      </Link>
    </li>
  );
};
export default CampaignGiveawayItem;
