import React from 'react';

import Tabs from './Tabs';

const CampaignHeader = (props) => {
  const {
    categoryTabs,
    selectedCategoryTab,
    handleSelectedCategoryTab,
    tabs,
    selectedTab,
    handleSelectedTab,
  } = props;

  return (
    <section className="campaign-header-wrapper">
      <h2 className="campaign-title">Experiences</h2>
      <Tabs
        className="category-tabs-campaign"
        items={categoryTabs}
        selectedTab={selectedCategoryTab}
        handleSelectedTab={handleSelectedCategoryTab}
      />
      <Tabs
        className="main-tabs-campaign main-tabs-campaign-desktop"
        items={tabs}
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
      />
    </section>
  );
};

export default CampaignHeader;
